import * as ENV             from '../util/env';
import { getApolloContext } from '@apollo/react-hooks';
import { useContext }       from 'react';
import { useCrossfilter }   from '../util/crossfilter';
import { useEffect }        from 'react';
import { useRef }           from 'react';
import { useState }         from 'react';
import Bluebird             from 'bluebird';
import query                from '../graphql/queries/provider_metrics.graphql';
import useLocations         from '../util/use_locations';


export default function useProviderMetrics({ startDate, endDate }) {
  const locations                                = useLocations();
  const { client }                               = useContext(getApolloContext());
  const [ error, setError ]                      = useState(null);
  const { cf, replace, clear: clearCrossfilter } = useCrossfilter();
  const [ isLoading, setIsLoading ]              = useState(true);
  const metrics                                  = useRef([]);
  const [ , refresh ]                            = useState();

  function onData(newMetrics) {
    metrics.current.push(...newMetrics);
  }

  function onError(err) {
    setError(err);
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    clearCrossfilter();
    metrics.current = [];
  }, [ locations, startDate, endDate ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    replace(metrics.current);
    refresh(v => !v);
  }, [ metrics, locations, isLoading ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(function() {
    return loadProviderMetrics({
      client,
      locations,
      startDate,
      endDate,
      onData,
      onComplete: () => setIsLoading(false),
      onError,
      first:      1000
    });
  }, [ startDate, endDate ]); // eslint-disable-line react-hooks/exhaustive-deps

  return { cf, error, isLoading };
}

function loadProviderMetrics({ client, locations, startDate, endDate, onData, onComplete, onError, first }) {
  let isUnmounted      = false;
  const allLocationIDs = [ ...locations.keys() ];

  async function queryProviderMetrics(locationIDs, cursor = null) {
    const { data, error } = await client.query({
      query,
      fetchPolicy: 'no-cache',
      variables:   {
        locationIDs,
        startDate,
        endDate,
        first,
        after: cursor
      }
    });

    // Component was unmounted, don't try to change the state.
    if (isUnmounted)
      return null;

    if (error)
      throw error;
    else
      return await onNewData(locationIDs, data);
  }

  async function onNewData(locationIDs, data) {
    const providerMetrics = getProviderMetrics({ locations, data });
    onData(providerMetrics);

    const { pageInfo } = data.providerMetrics;
    if (pageInfo.hasNextPage)
      return await queryProviderMetrics(locationIDs, pageInfo.endCursor);
    else
      return null;
  }

  // When user navigates to a different page and component unmounted, call this
  // function to stop loading more metrics, and stop updating component state.
  function stop() {
    isUnmounted = true;
  }

  async function start() {
    // Don't complicate tests with concurrency
    const chunkSize = ENV.isTest() ? 1 : 6;
    const chunks    = splitArray(allLocationIDs, chunkSize);

    try {
      await Bluebird.map(chunks, async function(locationIDs) {
        await queryProviderMetrics(locationIDs);
      }, { concurrency: Infinity });

      if (!isUnmounted)
        onComplete();
    } catch (error) {
      onError(error);
    }
  }

  start();

  return stop;
}

function getProviderMetrics({ locations, data }) {
  return data.providerMetrics.edges
    .map(({ node }) => {
      const location = locations.get(node.provider.location.id);
      return {
        ...node,
        location,
        providerID: node.provider.id
      };
    });
}

function splitArray(array, size) {
  const elementsPerArray = Math.ceil(array.length / size);
  return Array(size)
    .fill()
    .map((_, index) => index * elementsPerArray)
    .map(begin => array.slice(begin, begin + elementsPerArray));
}

import * as d3  from 'd3';
import currency from 'currency.js';
import XLSX     from 'xlsx';


const allColumns = [
  { header: 'Location ID', field: 'locationID', width: 25 },
  { header: 'Location Name', field: 'locationName', width: 40 },
  { header: 'Score', field: 'score' },
  { header: 'Promoters', field: 'promoters' },
  { header: 'Passives', field: 'passives' },
  { header: 'Detractors', field: 'detractors' },
  { header: 'Customers Asked', field: 'asked' },
  { header: 'Responded With Feedback', field: 'responded' },
  { header: 'Response Rate', field: 'responseRate' }
];


// See https://docs.sheetjs.com/
export default function createWorksheet({ cf, locations, isNPS, dateRange }) {
  const columns = getColumns(isNPS);
  const headers = columns.map(({ header }) => header);

  const rows   = cf.allFiltered()
    .reduce((map, metric) => byLocation({ map, metric, locations }), new Map())
    .values();
  const sorted = [ ...rows ]
    .sort((a, b) => a.location.name.localeCompare(b.location.name));

  const cells = sorted
    .map(row => calculate({ row, isNPS }))
    .map(row => columns.map(({ field }) => row[field]));

  const worksheet    = XLSX.utils.aoa_to_sheet([ headers, ...cells ]);
  worksheet['!cols'] = columns
    .map(({ width, header }) => ({ wch: width || header.length }));

  return {
    worksheet,
    worksheetName: `${dateRange.startDate} - ${dateRange.endDate}`
  };
}


function getColumns(isNPS) {
  return isNPS ?
    allColumns :
    allColumns.filter(({ field }) => field !== 'passives');
}


function byLocation({ map, metric, locations }) {
  const locationID = metric.location.id;
  const location   = locations.get(locationID);
  if (!map.has(locationID))
    map.set(locationID, { location });

  const entry        = map.get(locationID);
  entry.promoters    = (entry.promoters || 0) + (metric.classifiedAs.promoters || 0);
  entry.passives     = (entry.passives || 0) + (metric.classifiedAs.passives || 0);
  entry.detractors   = (entry.detractors || 0) + (metric.classifiedAs.detractors || 0);
  entry.asked        = (entry.asked || 0) + (metric.feedbackResponse.asked || 0);
  entry.responded    = (entry.responded || 0) + (metric.feedbackResponse.responded || 0);
  entry.responseRate = d3.format('.0%')(entry.asked ? Math.min(entry.responded / entry.asked, 1) : 0);
  entry.totalPaid    = currency(entry.totalPaid || 0).add(metric.payments.paid || 0);
  entry.outstanding  = currency(entry.outstanding || 0).add(metric.payments.outstanding || 0);
  entry.newLeads     = (entry.newLeads || 0) + (metric.leads.newLeads || 0);
  entry.respondedTo  = (entry.respondedTo || 0) + (metric.leads.respondedTo || 0);
  entry.days         = (entry.days || 0) + (metric.leads.responseTime ? 1 : 0);
  entry.responseTime = (entry.responseTime || 0) + (metric.leads.responseTime || 0);

  return map;
}


function calculate({ row, isNPS }) {
  const responseTime = row.days ? Math.round(row.responseTime / row.days) : 0;
  return {
    locationID:   { v: row.location.id, t: 's' },
    locationName: { v: row.location.name, t: 's' },
    ...row,
    score:        getScore({ ...row, isNPS }),
    totalPaid:    {
      v: row.totalPaid.value,
      w: row.totalPaid.format(true),
      t: 'n',
      z: '$#,##0.00'
    },
    outstanding: {
      v: row.outstanding.value,
      w: row.outstanding.format(true),
      t: 'n',
      z: '$#,##0.00'
    },
    // In Excel, dates are stored as the number of days since 1/1/1900 12:00 AM.
    // For example, the number 1.1122685185185186 is 1 day, 2 hours, 41 minutes,
    // and 40 seconds. This is the value of this cell. We apply the format
    // `hours:minutes:seconds` so the cell shows a time duration.  All the math
    // (sum, average, percentile, etc) works as expected.
    responseTime: {
      v: (responseTime) / 86400,
      t: 'n',
      z: '[h]:mm:ss'
    }
  };
}


function getScore({ promoters, passives, detractors, isNPS }) {
  if (isNPS) {
    const responded = promoters + passives + detractors;
    return responded ? Math.round((promoters - detractors) / responded * 100) : 0;
  } else {
    const responded = promoters + detractors;
    return responded ? Math.round(promoters / responded * 100) : 0;
  }
}

import './download_excel.less';

import * as DateFns                           from 'date-fns';
import { Download }                           from 'react-feather';
import { logEvent }                           from '../util/log_event';
import { useAccessToken }                     from '../app/authenticate';
import { useCurrentUser }                     from '../util/use_current_user';
import { useNotification }                    from './notification';
import { useOrganization }                    from '../util/use_organizations';
import { useSearchParameterState }            from '../util/use_search_params';
import { useSelectedLocationsWithUserAccess } from '../util/log_event';
import { useState }                           from 'react';
import Button                                 from '../components/button';
import React                                  from 'react';
import rollbar                                from '../rollbar';
import XLSX                                   from 'xlsx';


export default function DownloadExcel({
  disabled = false,
  // The name of the log for what we'll send to amplitude if provided
  logName,
  // Label for the button
  buttonLabel = 'Export',
  // Child function must return an object containing an XLSX Worksheet and name
  // {
  //   worksheet,
  //   name,
  //   worksheetName (optional, defaults to name)
  // }
  children
}) {
  const selectedLocationsWithUserAccess = useSelectedLocationsWithUserAccess();
  const { showError }                   = useNotification();
  const [ isActive, setIsActive ]       = useState(false);
  const organization                    = useOrganization();
  const currentUser                     = useCurrentUser();
  const accessToken                     = useAccessToken()?.accessToken;
  const [ locations ]                   = useSearchParameterState('locations');
  const userProperties                  = { id: currentUser.id, name: currentUser.name };

  function onClick() {
    const getWorksheetFunction = children;

    if (isActive)
      return;

    setIsActive(true);

    if (logName) {
      logEvent({
        accessToken,
        userProperties,
        organization,
        locations,
        selectedLocationsWithUserAccess,
        eventType: logName
      });
    }

    const functionTakesCallback = getWorksheetFunction.length === 1;
    if (functionTakesCallback) {
    // Call the async function to get data
      getWorksheetFunction(function(err, data) {
        if (err) {
          rollbar.error(err);
          showError('Oops. Something went wrong. We’re working on a fix.');
        }

        const { name, worksheets } = data;
        downloadWorkbook({ name, organization, worksheets });
        setIsActive(false);
      });
    } else {
      // Generating XLSX file takes a few ms, to the user it appears as if the
      // button is not doing anything, especially if the browser is not giving
      // any indication of download.  So we fake a delay.

      setTimeout(function() {
        try {
          const { name, worksheets } = getWorksheetFunction();
          downloadWorkbook({ name, organization, worksheets });
        } catch (error) {
          rollbar.error(error);
          showError('Oops. Something went wrong. We’re working on a fix.');
        } finally {
          setIsActive(false);
        }
      }, 300);
    }
  }

  return (
    <Button
      disabled={disabled}
      className="download-excel-button"
      onClick={onClick}
      loading={isActive}
      positive={isActive}
      title={buttonLabel}
    >
      <Download/>
      <span className="divider"/>
      {buttonLabel}
    </Button>
  );
}


function downloadWorkbook({ worksheets, name, organization }) {
  const filename         = name || worksheets[0].worksheetName;
  const filenameWithDate = getFilenameWithDate(filename);
  const workbook         = XLSX.utils.book_new();
  workbook.Props         = {
    Title:   filenameWithDate,
    Company: organization?.name || '',
    Author:  'Broadly Inc'
  };

  worksheets.map(({ worksheet, worksheetName }) => {
    XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);
  });
  XLSX.writeFile(workbook, filenameWithDate);
}


function getFilenameWithDate(name) {
  const date = DateFns.format(new Date(), 'yyyy-MM-dd');
  return `${name}_${date}.xlsx`;
}

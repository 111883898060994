import './feedback_item.less';

import * as DateFns                from 'date-fns';
import * as Phone                  from 'libphonenumber-js';
import { useSearchParameterState } from '../util/use_search_params';
import { useState }                from 'react';
import HighlightTerms              from '../components/highlight_terms';
import NextAction                  from './next_action';
import NpsBadge                    from '../components/nps_badge';
import React                       from 'react';
import serviceNames                from '../util/service_name';
import StarRating                  from '../components/star_rating';
import ThumbBadge                  from '../components/thumb_badge';
import useLocations                from '../util/use_locations';


export default function FeedbackItem({ feedback }) {
  const [ reviewResponse, setReviewResponse ] = useState(feedback.review?.response);

  const review = feedback.review ?
    {
      ...feedback.review,
      response: reviewResponse
    } :
    null;

  const updatedFeedback = { ...feedback, review };
  return (
    <div className="feedback-card">
      <FeedbackTop {...{ feedback: updatedFeedback }}/>
      <NextAction {...{ feedback: updatedFeedback, setReviewResponse }}/>
      <FeedbackFooter {...{ feedback: updatedFeedback }}/>
    </div>
  );
}

function FeedbackTop({ feedback }) {
  const response = feedback.review?.response;

  return (
    <section className="feedback-top">
      <FeedbackHeader feedback={feedback}/>
      <FeedbackReview feedback={feedback}/>
      {response && <FeedbackResponse response={response}/>}
    </section>
  );
}

function FeedbackHeader({ feedback }) {
  return (
    <div className="feedback-header">
      <div>
        <FeedbackContactName feedback={feedback}/>
        <FeedbackProviders feedback={feedback}/>
        <FeedbackLocation feedback={feedback}/>
      </div>
      <div>
        <FeedbackRatings feedback={feedback}/>
      </div>
    </div>
  );
}

function FeedbackContactName({ feedback }) {
  const contactName    = getContactName(feedback);
  const [ query ]      = useSearchParameterState('search');
  const highlightTerms = query && new Set(query.split(' '));
  return (
    <div className="contact-name">
      <HighlightTerms body={contactName} highlightTerms={highlightTerms}/>
    </div>
  );
}

function getContactName(feedback) {
  const { contact } = feedback;
  if (contact)
    return contact.displayName;

  const author     = feedback.review?.author;
  const authorName = author?.name || 'Anoymous';
  return authorName;
}


function FeedbackProviders({ feedback }) {
  const providerNames  = getProviderNames(feedback);
  const [ query ]      = useSearchParameterState('search');
  const highlightTerms = query && new Set(query.split(' '));
  if (providerNames) {
    return (
      <div className="providers">
        <em>for</em> <HighlightTerms body={providerNames} highlightTerms={highlightTerms}/>
      </div>
    );
  } else
    return null;
}

function getProviderNames(feedback) {
  return feedback.providers?.map(({ name }) => name)
    .filter(Boolean)
    .join(', ');
}


function FeedbackLocation({ feedback }) {
  const locations      = useLocations();
  const locationName   = getLocationName({ locations, feedback });
  const [ query ]      = useSearchParameterState('search');
  const highlightTerms = query && new Set(query.split(' '));

  if (locationName) {
    return (
      <div className="location">
        <em>at</em> <HighlightTerms body={locationName} highlightTerms={highlightTerms}/>
      </div>
    );
  } else
    return null;
}

function getLocationName({ locations, feedback }) {
  const location = locations.get(feedback.location.id);
  return location?.name;
}


function FeedbackReview({ feedback }) {
  const paragraphs     = getReviewParagraphs(feedback);
  const [ query ]      = useSearchParameterState('search');
  const highlightTerms = query && new Set(query.split(' '));

  if (paragraphs.length > 0) {
    return (
      <div className="feedback-review">
        {paragraphs.map((graf, key) => (
          <p key={key}>
            <HighlightTerms body={graf} highlightTerms={highlightTerms}/>
          </p>
        ))}
      </div>
    );
  } else
    return null;
}

function getReviewParagraphs(feedback) {
  // assume body is a string, so we always return an array, even if it's empty
  const body = feedback.body || '';

  const paragraphs = (
    body
      .split('\n')
      .filter(graf => graf.trim())
      .filter(Boolean)
  );

  return paragraphs;
}


function FeedbackResponse({ response }) {
  const published      = DateFns.parseISO(response.published);
  const formattedDate  = DateFns.format(published, 'PP');
  const name           = response.name;
  const body           = response.body;
  const [ query ]      = useSearchParameterState('search');
  const highlightTerms = query && new Set(query.split(' '));

  return (
    <div className="feedback-response">
      <div className="response-text">
        <HighlightTerms body={body} highlightTerms={highlightTerms}/>
      </div>
      <div className="response-meta">
        <div className="response-name">
            —{name}
        </div>
        <div className="response-date">
          {formattedDate}{response.pending ? ' - pending' : ''}
        </div>
      </div>
    </div>
  );
}


function FeedbackRatings({ feedback }) {
  return (
    <div className="ratings">
      <Stars feedback={feedback}/>
      <Sentiment feedback={feedback}/>
    </div>
  );
}

function Stars({ feedback }) {
  const { review } = feedback;
  if (!review)
    return null;

  const { rating }    = review;
  const { sourceURL } = review;
  const serviceName   = serviceNames[review.service] || review.service;

  return (
    <div className="rating">
      <StarRating score={rating} service={serviceName} url={sourceURL}/>
    </div>
  );
}

function Sentiment({ feedback }) {
  const { npsScore } = feedback;
  const hasNPSScore  = Number.isInteger(npsScore);

  if (hasNPSScore)
    return <NpsSentiment feedback={feedback}/>;
  else
    return <ThumbSentiment feedback={feedback}/>;
}

function ThumbSentiment({ feedback }) {
  const { classifiedAs } = feedback;
  const up               = classifiedAs === 'PROMOTER';

  return (
    <div className="rating">
      <ThumbBadge up={up}/>
    </div>
  );
}

function NpsSentiment({ feedback }) {
  const { npsScore } = feedback;
  return (
    <div className="rating">
      <NpsBadge score={npsScore}/>
    </div>
  );
}


function FeedbackFooter({ feedback }) {
  const isDeleted      = feedback.review?.status === 'deleted';
  const isOutdated     = feedback.review?.status === 'outdated';
  const service        = feedback.review?.service.toLowerCase();
  const email          = feedback.contact?.email?.address;
  const phone          = feedback.contact?.phone?.number;
  const timestamp      = DateFns.parseISO(feedback.timestamp);
  const formattedDate  = DateFns.format(timestamp, 'PP');
  const formattedPhone = phone && Phone.format(phone, 'National');

  return (
    <section className="feedback-footer">
      {isDeleted && <div className="review-deleted">{capitalizeFirstLetter(service)} has removed this review from your listing</div>}
      {isOutdated && <div className="review-outdated">{capitalizeFirstLetter(service)} may have removed this review from your listing</div>}
      <div className="contact-info">
        {email && (
          <div className="contact-email">
            <a href={`mailto:${email}`}>{email}</a>
          </div>
        )}
        {phone && (
          <div className="contact-phone">
            <a href={`tel:${phone}`}>{formattedPhone}</a>
          </div>
        )}
      </div>
      <div className="feedback-date">
        {formattedDate}
      </div>
    </section>
  );
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase().concat(string.slice(1));
}

export default [
  add,
  remove,
  initial
];


function initial() {
  return {
    reviews: {
      google:      initialReviews(),
      facebook:    initialReviews(),
      yelp:        initialReviews(),
      nextdoor:    initialReviews(),
      tripAdvisor: initialReviews()
    }
  };
}

function initialReviews() {
  return {
    totalRating:  0,
    ratingCount:  0,
    rating:       0,
    reviewsAdded: 0
  };
}


export function add(acc, value) {
  return reduceUsing(addOperation)(acc, value);
}

export function remove(acc, value) {
  return reduceUsing(subtractOperation)(acc, value);
}


function reduceUsing(fn) {
  return function({ reviews: acc }, { reviews: value }) {
    return {
      reviews: {
        google:      reduceReviews(fn, acc.google, value.google),
        facebook:    reduceReviews(fn, acc.facebook, value.facebook),
        yelp:        reduceReviews(fn, acc.yelp, value.yelp),
        nextdoor:    reduceRecommendations(fn, acc.nextdoor, value.nextdoor),
        tripAdvisor: reduceReviews(fn, acc.tripAdvisor, value.tripAdvisor)
      }
    };
  };
}

function reduceReviews(fn, acc, value) {
  if (value) {
    const reviewCount  = fn(acc.reviewCount, value.reviewCount);
    const totalRating  = fn(acc.totalRating, value.rating);
    const ratingCount  = fn(acc.ratingCount, value.rating ? 1 : 0);
    const rating       = ratingCount ? (totalRating / ratingCount) : 0;
    const reviewsAdded = fn(acc.reviewsAdded, value.reviewsAdded);
    return { reviewCount, reviewsAdded, totalRating, ratingCount, rating };
  } else
    return acc;
}

function reduceRecommendations(fn, acc, value) {
  if (value) {
    const reviewCount  = fn(acc.reviewCount, value.reviewCount);
    const reviewsAdded = fn(acc.reviewsAdded, value.reviewsAdded);
    return { reviewCount, reviewsAdded };
  } else
    return acc;
}


function addOperation(a, b) {
  return (a || 0) + (b || 0);
}

function subtractOperation(a, b) {
  return (a || 0) - (b || 0);
}

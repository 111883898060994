import * as d3 from 'd3';
import XLSX    from 'xlsx';


const allColumns = [
  { header: 'Brand ID', field: 'id', width: 25 },
  { header: 'Brand Name', field: 'name', width: 40 },
  { header: 'Locations', field: 'locationCount' },
  { header: 'Leads', field: 'newLeads' },
  { header: 'Customers Asked', field: 'asked' },
  { header: 'Response Rate', field: 'responseRate' },
  { header: 'Promoters', field: 'promoters' },
  { header: 'Passives', field: 'passives' },
  { header: 'Detractors', field: 'detractors' },
  { header: 'NPS Score', field: 'npsScore' },
  { header: 'Satisfaction Score', field: 'satisfactionScore' },
  { header: 'Google Review Count', field: 'googleReviewCount' },
  { header: 'Facebook Review Count', field: 'facebookReviewCount' },
  { header: 'Yelp Review Count', field: 'yelpReviewCount' },
  { header: 'Nextdoor Review Count', field: 'nextdoorReviewCount' }
];


// See https://docs.sheetjs.com/
export default function createWorksheet({ brandsMetrics, isNPS }) {
  const columns = getColumns(isNPS);
  const headers = columns.map(({ header }) => header);
  const rows    = brandsMetrics
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(row => calculate(row))
    .map(row => columns.map(({ field }) => row[field]));

  const worksheet    = XLSX.utils.aoa_to_sheet([ headers, ...rows ]);
  worksheet['!cols'] = columns
    .map(({ width, header }) => ({ wch: width || header.length }));

  return { worksheet, worksheetName: 'Brands Metrics' };
}


function getColumns(isNPS) {
  return isNPS ?
    allColumns :
    allColumns.filter(({ field }) => !([ 'passives', 'npsScore' ].includes(field)));
}


function calculate({ id, name, totalLocationCount, leads, feedbackResponse, classifiedAs, reviews }) {
  return {
    id,
    name,
    locationCount:       totalLocationCount ?? 1,
    newLeads:            leads?.newLeads,
    asked:               feedbackResponse?.asked,
    responseRate:        feedbackResponse?.responseRate ? d3.format('.0%')(feedbackResponse.responseRate) : '',
    promoters:           classifiedAs?.promoters,
    passives:            classifiedAs?.passives,
    detractors:          classifiedAs?.detractors,
    npsScore:            classifiedAs?.npsScore,
    satisfactionScore:   classifiedAs?.satisfactionScore,
    googleReviewCount:   reviews?.google?.reviewsAdded ?? '',
    facebookReviewCount: reviews?.facebook?.reviewsAdded ?? '',
    yelpReviewCount:     reviews?.yelp?.reviewsAdded ?? '',
    nextdoorReviewCount: reviews?.nextdoor?.reviewsAdded ?? ''
  };
}

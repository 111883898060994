import BrandSelector        from '../components/brand_selector';
import createWorksheet      from './worksheet';
import DateRangeSelector    from '../components/date_range_selector';
import DownloadExcel        from '../components/download_excel';
import getDisplayFeatures   from '../util/display_features';
import Locations            from '../facets/locations';
import React                from 'react';
import useSelectedLocations from '../util/use_selected_locations';


export default function Facets({ cf, dateRange, setDateRange, minDate, maxDate, isLoading }) {
  return (
    <div className="facets">
      <div className="brand-location-selectors">
        <BrandSelector/>
        <Locations cf={cf}/>
      </div>
      <div className="date-download">
        <DateRangeSelector {...{ dateRange, minDate, maxDate, setDateRange }}/>
        <Download {...{ cf, dateRange, isLoading }}/>
      </div>
    </div>
  );
}


function Download({ cf, dateRange, isLoading }) {
  const selectedLocations  = useSelectedLocations();
  const features           = getDisplayFeatures(selectedLocations.values());
  const isWebchatAvailable = features.webchat;
  const isNPS              = features.nps;

  return (
    <div>
      <DownloadExcel disabled={isLoading} logName="DashboardMetricsExported">
        {() => ({
          name:       'Key Metrics',
          worksheets: [
            createWorksheet({ cf, locations: selectedLocations, isNPS, isWebchatAvailable, dateRange })
          ]
        })}
      </DownloadExcel>
    </div>
  );
}

import './metrics_page_section.less';

import classnames from 'classnames';
import React      from 'react';


export default function MetricsPageSection({ className, children }) {
  const classNames = classnames('metrics-page-section', className);
  return (
    <div className={classNames}>
      {children}
    </div>
  );
}

import './account_form.less';

import { getBadRequestErrorMessage } from '../util/bad_request_error';
import { Input }                     from 'semantic-ui-react';
import { isBadRequestError }         from '../util/bad_request_error';
import { useCurrentUser }            from '../util/use_current_user';
import { useEffect }                 from 'react';
import { useHistory }                from 'react-router';
import { useMemo }                   from 'react';
import { useMutation }               from '@apollo/react-hooks';
import { useNotification }           from '../components/notification';
import { useState }                  from 'react';
import Block                         from '../components/block';
import Button                        from '../components/button';
import Dropdown                      from '../components/dropdown';
import inviteUserMutation            from '../graphql/mutations/invite_user.graphql';
import React                         from 'react';
import updateMeMutation              from '../graphql/mutations/update_me.graphql';
import updateUserMutation            from '../graphql/mutations/update_user.graphql';


export default function AccountDetailForm({ user, isCreate }) {
  const history                                                         = useHistory();
  const { id: currentUserID, memberOf: currentUserMemberOf, locations } = useCurrentUser();
  const [ userInfo, setUserInfo ]                                       = useState();
  const [ userMemberOf, setUserMemberOf ]                               = useState([]);
  const isCurrentUser                                                   = user?.id === currentUserID;
  const { showError }                                                   = useNotification();
  const [ isLoading, setIsLoading ]                                     = useState(false);

  function onSuccessCallback() {
    setIsLoading(false);

    const currentUserIsAdmin = currentUserMemberOf.some(item => item.location && item.role === 'ADMIN');

    if (currentUserIsAdmin)
      history.push('/account');
  }

  const [ updateUser, inviteUser, updateMe ] = useUserMutations({ userInfo, memberOf: userMemberOf, onSuccessCallback, setIsLoading  });

  useEffect(function() {
    const data = {
      id:    user?.id ?? '',
      name:  user?.name ?? '',
      email: user?.email ?? '',
      phone: user?.phone ?? '',
      title: user?.title ?? ''
    };

    setUserInfo(data);
  }, [ user ]);

  useEffect(function() {
    if (user) {
      setUserMemberOf(user.memberOf.filter(item => item.location).map(item => ({
        location: {
          id: item.location.id
        },
        role: item.role
      })));
    }
  }, [ user ]);

  function onUserInfoChange({ target }) {
    setUserInfo(prevInfo => ({
      ...prevInfo,
      [target.name]: target.value
    }));
  }

  function onPermissionChange(locationID, newRole) {
    const isAlreadyMemberOfLocation = userMemberOf.some(item => item.location.id === locationID);

    if (isAlreadyMemberOfLocation) {
      const updatedMemberOf = userMemberOf.map(item => {
        if (item.location.id === locationID) {
          return {
            location: {
              id: locationID
            },
            role: newRole
          };
        } else
          return item;
      });

      setUserMemberOf(updatedMemberOf);
    } else {
      setUserMemberOf(prev => ([
        ...prev,
        {
          location: {
            id: locationID
          },
          role: newRole
        }
      ]));
    }
  }

  function onSaveData() {
    if (!userInfo.name || !userInfo.email) {
      showError('Please complete the required fields');
      return;
    }

    if (isCreate && !userMemberOf.length) {
      showError('Use the dropdown to select permissions for this new team member');
      return;
    }

    setIsLoading(true);

    if (isCreate)
      inviteUser();
    else if (isCurrentUser)
      updateMe();
    else
      updateUser();
  }

  if (!userInfo)
    return null;

  return (
    <div className="account-detail">
      <Block>
        <Header {...{ user, isCurrentUser }}/>
        <PersonalInformation {...{ userInfo, onUserInfoChange, isCreate }}/>
        {!isCurrentUser && <Permissions {...{ currentUserMemberOf, userMemberOf, locations, onPermissionChange }}/>}

        <div className="save-section">
          <Button loading={isLoading} onClick={onSaveData}>{isCreate ? 'Invite User' : 'Update Information'}</Button>
        </div>
      </Block>
    </div>
  );
}

function Header({ user, isCurrentUser }) {
  if (user) {
    const name = isCurrentUser ? `${user.name} (you)` : user.name;

    return (
      <div className="header">
        <h2>{name}</h2>
      </div>
    );
  } else {
    return (
      <div className="header">
        <h2>Add Member</h2>
      </div>
    );
  }
}

function PersonalInformation({ userInfo, onUserInfoChange, isCreate }) {
  return (
    <div className="user-section">
      <div className="user-section-title">
        Personal Information
      </div>
      <div className="user-section-row">
        <div className="user-input">
          <label>* Full Name</label>
          <Input
            type="text"
            value={userInfo.name}
            name="name"
            onChange={onUserInfoChange}
          />
        </div>
        <div className="user-input">
          <label>* Email</label>
          <Input
            name="email"
            value={userInfo.email}
            onChange={onUserInfoChange}
          />
        </div>
      </div>
      <div className="user-section-row">
        <div className="user-input">
          <label>Phone Number</label>
          <Input
            type="tel"
            name="phone"
            value={userInfo.phone}
            onChange={onUserInfoChange}
          />
        </div>
        <div className="user-input">
          <label>Job Title</label>
          <Input
            disabled={!isCreate}
            type="text"
            name="title"
            value={userInfo.title}
            onChange={onUserInfoChange}
          />
        </div>
      </div>
    </div>
  );
}

function Permissions({ currentUserMemberOf, userMemberOf, locations, onPermissionChange }) {
  const roleOptions = [
    {
      text:  'Member',
      value: 'MEMBER'
    },
    {
      text:  'Manager',
      value: 'MANAGER'
    },
    {
      text:  'Administrator',
      value: 'ADMIN'
    },
    {
      text:  'No Access',
      value: 'NONE'
    }
  ];

  function getRole(locationID) {
    const location = userMemberOf.find(item => item.location.id === locationID);
    return location?.role ?? 'NONE';
  }

  const locationsMap = useMemo(() => getLocationsMap({ memberOf: currentUserMemberOf, locations }), [ currentUserMemberOf, locations ]);

  if (locationsMap.size === 0)
    return null;

  return (
    <div className="user-section">
      <div className="user-section-title">
        User Permissions
      </div>

      <div className="permissions">
        <div className="organizations">
          {[ ...locationsMap.keys() ].map(name => (
            <div key={name} className="organization">
              <h2>{name}</h2>
              <div className="locations">
                {locationsMap.get(name).map(location => (
                  <div key={location.name} className="location">
                    <div className="name">
                      {location.name}
                    </div>
                    <div className="permission">
                      <Dropdown
                        selection
                        name={location.id}
                        onChange={(event, data) => onPermissionChange(location.id, data.value)}
                        placeholder='Select Role'
                        value={getRole(location.id)}
                        options={roleOptions}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="permissions-info">
          <h2>What do these<br/> roles mean?</h2>
          <ul>
            <li><b>Member</b> can add contacts, send/receive messages, view metrics, receive notifications.</li>
            <li><b>Manager</b> can do the same, managers can also import files, view additional metrics.</li>
            <li><b>Administrator</b> can do anything member/manager can do, also add/remove users, change account settings.</li>
            <li><b>No Access</b> takes away the user’s access to that location.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

function getLocationsMap({ memberOf, locations }) {
  // Filter all the locations where currentUser is admin
  const adminLocationIDs = memberOf.reduce((acc, item) => {
    const isAdmin = item.location && item.role === 'ADMIN';
    if (isAdmin)
      acc.set(item.location.id, item);

    return acc;
  }, new Map());

  // Create map of organizations and locations.
  // E.g orgName/locName -> [locations]
  return locations.reduce((acc, location) => {
    const name = location?.organization?.name ?? location.name;

    if (adminLocationIDs.has(location.id)) {
      if (!acc.has(name))
        acc.set(name, []);

      acc.get(name).push(location);
    }

    return acc;
  }, new Map());
}

function useUserMutations({ userInfo, memberOf, onSuccessCallback, setIsLoading }) {
  const { showSuccess, showError } = useNotification();

  const [ updateUser ] = useMutation(
    updateUserMutation,
    {
      variables: {
        ...userInfo,
        title: undefined,
        memberOf
      },
      onCompleted() {
        showSuccess('Success! User updated.');
        onSuccessCallback();
      },
      onError(error) {
        setIsLoading(false);

        if (isBadRequestError(error))
          showError(getBadRequestErrorMessage(error));
        else
          showError('Something went wrong. Refresh the page to try again.');
      }
    }
  );

  const [ inviteUser ] = useMutation(
    inviteUserMutation,
    {
      variables: {
        ...userInfo,
        id: undefined,
        memberOf
      },
      onCompleted() {
        showSuccess('Success! User invited.');
        onSuccessCallback();
      },
      onError(error) {
        setIsLoading(false);

        if (isBadRequestError(error))
          showError(getBadRequestErrorMessage(error));
        else
          showError('Something went wrong. Refresh the page to try again.');
      }
    }
  );

  const [ updateMe ] = useMutation(
    updateMeMutation,
    {
      variables: {
        ...userInfo,
        id: undefined
      },
      onCompleted() {
        showSuccess('Success! Account updated.');
        onSuccessCallback();
      },
      onError(error) {
        setIsLoading(false);

        if (isBadRequestError(error))
          showError(getBadRequestErrorMessage(error));
        else
          showError('Something went wrong. Refresh the page to try again.');
      }
    }
  );

  return [ updateUser, inviteUser, updateMe ];
}

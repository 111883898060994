import 'react-virtualized/styles.css';

import * as DateFns   from 'date-fns';
import { IsVisible }  from '../util/is_visible';
import { useMemo }    from 'react';
import FeedbackItem   from './feedback_item';
import LoadingShimmer from '../components/loading_shimmer';
import React          from 'react';

export default function FeedbackList({ feedback }) {
  const groupedEntries = useMemo(() => feedback.reduce(groupByDate, new Map()), [ feedback ]);
  const totalEntries   = feedback.length;
  const hasResults     = (totalEntries > 0);

  if (hasResults) {
    return Array.from(groupedEntries, ([ date, entries ]) => (
      <DateGroup key={date} {...{ date, entries }}/>)
    );
  } else
    return <h2 className="date-group-header">No Results</h2>;
}


// We group each set of feedback by date header: Today, Yesterday, month, etc.
function DateGroup({ date, entries }) {
  const dateHeader = getDateHeader(date);
  const values     = [ ...entries.values() ];
  return (
    <>
      <IsVisible
        options={{
          // Prevent flash of empty elements when scrolling fast
          rootMargin: '500% 0px'
        }}
        placeholder={LoadingShimmer}
      >
        <h2 className="date-group-header">
          {dateHeader}
        </h2>
      </IsVisible>
      {values.map(feedback => (
        <IsVisible
          key={feedback.id}
          options={{
            // Prevent flash of empty elements when scrolling fast
            rootMargin: '500% 0px'
          }}
          placeholder={LoadingShimmer}
        >
          <FeedbackItem feedback={feedback}/>
        </IsVisible>
      ))}
    </>
  );
}


function getDateHeader(date) {
  // Date is yyyy-MM-dd, DateFns needs a Date object
  const realDate = DateFns.parseISO(date);
  if (DateFns.isToday(realDate))
    return 'Today';
  if (DateFns.isYesterday(realDate))
    return 'Yesterday';
  if (DateFns.isThisWeek(realDate))
    return 'This Week';
  if (DateFns.isThisWeek(DateFns.addWeeks(realDate, 1)))
    return 'Last Week';
  if (DateFns.isThisYear(realDate))
    return DateFns.format(realDate, 'MMMM');
  return DateFns.format(realDate, 'MMM yyyy');
}

function groupByDate(acc, item) {
  const date = getGroupByDate(item);
  if (!acc.has(date))
    acc.set(date, []);
  acc.get(date).push(item);

  return acc;
}

const date      = new Date();
const today     = DateFns.startOfDay(date).toISOString();
const yesterday = DateFns.subDays(DateFns.startOfDay(date), 1).toISOString();
const thisWeek  = DateFns.startOfWeek(date).toISOString();
const lastWeek  = DateFns.subWeeks(DateFns.startOfWeek(date), 1).toISOString();

// Using DateFns.isToday/isThisWeek, etc is extremely slow, much faster to do
// string comparison (100x quicker).
function getGroupByDate({ timestamp }) {
  if (timestamp >= today)
    return today;
  if (timestamp >= yesterday)
    return yesterday;
  if (timestamp >= thisWeek)
    return thisWeek;
  if (timestamp >= lastWeek)
    return lastWeek;
  return DateFns.lightFormat(DateFns.parseISO(timestamp), 'yyyy-MM');
}

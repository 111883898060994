import { getLocationDisplayName }  from '../util/locations';
import { useDimension }            from '../util/crossfilter';
import { useEffect }               from 'react';
import { useMemo }                 from 'react';
import { useParams }               from 'react-router';
import { useSearchParameterState } from '../util/use_search_params';
import MultiselectDropdown         from '../components/multiselect_dropdown';
import React                       from 'react';
import useLocations                from '../util/use_locations';


export default function Locations({ cf }) {
  const { organizationID }              = useParams();
  const [ fromQuery = '', updateQuery ] = useSearchParameterState('locations');
  const selected                        = useMemo(() => new Set(fromQuery.split(',').filter(Boolean)), [ fromQuery ]);
  const dimension                       = useDimension(cf, ({ location }) => location.id);

  useEffect(function() {
    if (selected.size === 0)
      dimension.filterAll();
    else
      dimension.filterFunction(id => selected.has(id));
  }, [ dimension, selected ]);

  const locations = useLocations();
  const options   = [ ...locations.values() ]
    .map(location => ({
      key:    location.id,
      value:  location.id,
      active: selected.has(location.id),
      text:   getLocationDisplayName(location)
    }))
    .sort((a, b) => a.text.localeCompare(b.text));

  function onClick(e, { value: clickedID }) {
    if (selected.has(clickedID)) {
      e.stopPropagation();
      updateQuery([ ...selected ].filter(id => id !== clickedID));
    } else {
      if (selected.size !== 0)
        e.stopPropagation();
      updateQuery([ ...selected, clickedID ]);
    }
  }

  function getPlaceholder() {
    const { size } = selected;
    if (size === 0)
      return 'All Locations';
    else if (size === 1) {
      const selectedOption = options.find(option => option.active);
      return selectedOption.text;
    } else
      return `${size} Locations`;
  }

  function onClearAll() {
    updateQuery([]);
  }

  const shouldRender = organizationID && options.length > 0;
  if (!shouldRender)
    return null;

  const placeholder = getPlaceholder();

  return (
    <MultiselectDropdown
      className="location-selector"
      options={options}
      onClick={onClick}
      placeholder={placeholder}
      onClearAll={onClearAll}
    />
  );
}

import './account.less';

import { Link }            from 'react-router-dom';
import { Plus }            from 'react-feather';
import { Redirect }        from 'react-router-dom';
import { useCurrentUser }  from '../util/use_current_user';
import { useNotification } from '../components/notification';
import Block               from '../components/block';
import Button              from '../components/button';
import LoadingShimmer      from '../components/loading_shimmer';
import React               from 'react';
import useUsers            from './use_users';


export default function AccountPage() {
  const { id: currentUserID, memberOf } = useCurrentUser();
  const { showError }                   = useNotification();
  const { users, error, isLoading }     = useUsers();

  const sortedUsers = [
    users.find(user => user.id === currentUserID),
    ...users.filter(user => user.id !== currentUserID)
  ];

  const isAdmin = memberOf.some(item => item.location && item.role === 'ADMIN');

  if (!isAdmin) {
    const to = { pathname: `/account/${currentUserID}` };
    return <Redirect to={to} push/>;
  }

  if (error)
    showError('Something went wrong. Refresh the page to try again.');

  if (isLoading)
    return <LoadingShimmer/>;

  return (
    <div className="account-page">
      <Block>
        <Facets/>
        <UsersTable users={sortedUsers} currentUserID={currentUserID}/>
      </Block>
    </div>
  );
}

function Facets() {
  const buttonLabel = 'Add Team Member';

  return (
    <div className="facets">
      <div className="header">
        <h2>Manage Your Account</h2>
        <p className="copy">Add, remove and manage permissions for team members.</p>
      </div>
      <Link to="/account/new">
        <Button
          className="add-team-member"
          onClick={() => {}}
          title={buttonLabel}
        >
          <Plus/>
          <span className="divider"/>
          {buttonLabel}
        </Button>
      </Link>
    </div>
  );
}

function UsersTable({ users, currentUserID }) {
  return (
    <div className="users-list">
      {users.map(user => (
        <div key={user.id} className="user-row">
          <div className="user">
            <div className="name">
              {user.name}
              {user.id === currentUserID && <span className="current-user">(You)</span>}
            </div>
            <div className="user-contact-details">
              <div className="email">{user.email}</div>
              <div className="phone">{user.phone}</div>
            </div>
          </div>
          <div className="actions">
            <Link to={`/account/${user.id}`}>
              Edit Details & Permissions
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}

import createWorksheet   from './worksheet';
import DateRangeSelector from '../components/date_range_selector';
import DownloadExcel     from '../components/download_excel';
import React             from 'react';


export default function Facets({ dateRange, minDate, maxDate, setDateRange, brandsMetrics, isLoading, isNPS }) {
  return (
    <div className="facets">
      <h2>All Brands</h2>
      <div className="date-download">
        <DateRangeSelector {...{ dateRange, minDate, maxDate, setDateRange }}/>
        <Download {...{ brandsMetrics, isLoading, isNPS }}/>
      </div>
    </div>
  );
}

function Download({ brandsMetrics, isLoading, isNPS }) {
  return (
    <DownloadExcel disabled={isLoading} logName="DashboardBrandsExported">
      {() => ({
        worksheets: [ createWorksheet({ brandsMetrics, isNPS }) ]
      })}
    </DownloadExcel>
  );
}

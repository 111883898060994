// helper functions for generating Y-axis tick values

export function getTicks({
  values       = [],
  lowerBound   = 0,
  upperBound   = Infinity,
  minRange     = 6,
  numIntervals = 2,
  includeZero  = false
} = {}) {
  const min      = includeZero ? Math.min(0, ...values) : Math.min(...values);
  const max      = Math.max(...values);
  const interval = getInterval(min, max, numIntervals);

  // If interval is not a number, return an empty list.
  // This usually means min and max are both 0.
  if (isNaN(interval))
    return [];

  // Round min/max values to nearest interval.
  const floor = Math.floor(min / interval) * interval;
  const ceil  = Math.ceil(max / interval) * interval;
  const range = ceil - floor;

  if (range < minRange) {
    // If range is too small, expand the range and recalculate. Have to recurse
    // because we need to calculate a new interval for the expanded range.
    const adjustedRange = getAdjustedRange({ floor, ceil, range, minRange, lowerBound, upperBound });
    return getTicks({ values: adjustedRange, lowerBound, upperBound, minRange, numIntervals, includeZero });
  }

  // Generate ticks for the floor, ceiling, and interval.
  const rawTicks = generateTicks({ floor, ceil, interval });

  // Sometimes the optimal interval will result in ticks that are out of bounds.
  const boundedTicks = checkBounds({ values: rawTicks, lowerBound, upperBound });

  // Sometimes the boundary shift will result in ticks that are no longer
  // needed. For example, if we shifted the range down, we might now have two
  // lower ticks that are less than the min value. Only need one tick below the
  // min value.
  const ticks = removeExtraTicks({ values: boundedTicks, min, max });

  return ticks;
}


function getAdjustedRange({ floor, ceil, range, minRange, lowerBound, upperBound }) {
  // Find the difference between minRange and range, and move floor down and
  // ceiling up by half that amount each.
  const difference     = minRange - range;
  const halfDifference = difference / 2;
  const adjustedFloor  = floor - halfDifference;
  const adjustedCeil   = ceil + halfDifference;
  const adjustedRange  = checkBounds({ values: [ adjustedFloor, adjustedCeil ], lowerBound, upperBound });
  return adjustedRange;
}


function generateTicks({ floor, ceil, interval }) {
  const range = ceil - floor;
  const steps = (range / interval) + 1; // Add one because there are e.g. 5 ticks for 4 intervals
  const ticks = Array.from(Array(steps)).map((_, i) => {
    return i * interval + floor;
  });
  return ticks;
}


function checkBounds({ values, lowerBound, upperBound }) {
  const offset = getBoundsOffset({ values, lowerBound, upperBound });
  return values.map(value => value + offset);
}


function getBoundsOffset({ values, lowerBound, upperBound }) {
  const min = Math.min(...values);
  const max = Math.max(...values);

  if (max > upperBound)
    return upperBound - max;
  else if (min < lowerBound)
    return lowerBound - min;
  else
    return 0;
}


function removeExtraTicks({ values, min, max }) {
  const upperTicks  = values.filter(tick => tick >= max);
  const lowerTicks  = values.filter(tick => tick <= min);
  const middleTicks = values.filter(tick => {
    return !upperTicks.includes(tick) && !lowerTicks.includes(tick);
  });
  const upperTick   = Math.min(...upperTicks);
  const lowerTick   = Math.max(...lowerTicks);

  return [
    lowerTick,
    ...middleTicks,
    upperTick
  ];
}


// Calculates an optimal interval size for a range of values and a given number
// of intervals, where 'numIntervals' is the number of ticks desired, not
// including the top and bottom ticks. Borrowed from
// https://github.com/sjoyal/pretty-intervals/blob/master/src/index.js
function getInterval(min, max, numIntervals) {
  const explicitTick  = Math.abs(max - min) / numIntervals;
  const magnitude     = Math.pow(10, Math.floor(Math.log(explicitTick) / Math.log(10)));
  const factor        = explicitTick / magnitude;
  const flooredFactor = Math.floor(explicitTick / magnitude);
  const breaks        = [];

  for (let i = 0; i <= 2; i++)
    breaks.push(flooredFactor + (i * .5));

  const bisector = breaks.reduce((p, c) => {
    if (c <= factor)
      return c;
    else
      return p;
  }, flooredFactor);

  return Math.ceil(bisector * magnitude);
}


// percentage values are all decimals from 0 to 1
// we modulate those by 100, so we get ticks from 0 to 100
// and then convert back to decimals from 0 to 1
export function getPercentageTicks({ values }) {
  const modifier       = 100;
  const modifiedValues = values.map(n => n * modifier);
  const minRange       = modifier / 10;
  const lowerBound     = 0;
  const upperBound     = 100;

  const ticks = getTicks({ values: modifiedValues, minRange, lowerBound, upperBound });

  return ticks.map(tick => tick / modifier);
}


// custom tick generator for star ratings which range from 0-5
// often charts display a range within 1, e.g. 4.2 - 4.7
// for those we use 0.5 as the interval
export function getStarsTicks({ values }) {
  const min      = Math.min(...values);
  const max      = Math.max(...values);
  const floor    = Math.floor(min);
  const ceil     = Math.ceil(max);
  const range    = ceil - floor;
  const interval = range > 1 ? 1 : 0.5;
  const ticks    = generateTicks({ floor, ceil, interval });
  return ticks;
}

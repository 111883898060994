import './message.less';

import { Message } from 'semantic-ui-react';


// This component imports our message styles and exports the Message component
// from semantic-ui-react. That Message component has a sensible and
// well-documented API https://react.semantic-ui.com/collections/message so we can
// use it to generate message markup, and apply our own appearance to it.

export default Message;

// Renders the web page layout:
// - Navigation bar
// - Current page title
// - Current page component

import { BrowserRouter }           from 'react-router-dom';
import { noOrgID }                 from './routes';
import { Redirect }                from 'react-router-dom';
import { Route }                   from 'react-router-dom';
import { routes  }                 from './routes';
import { Switch }                  from 'react-router-dom';
import { useBrands }               from '../util/use_brands';
import { useCurrentUser }          from '../util/use_current_user';
import { useRouteMatch }           from 'react-router-dom';
import { useSearchParameterState } from '../util/use_search_params';
import { WithLocation }            from '../util/use_locations';
import { WithOrganization }        from '../util/use_organizations';
import FullscreenMessage           from '../components/fullscreen_message';
import React                       from 'react';


export default function Router({ children }) {
  const localRoutes = Object.values(routes)
    .filter(({ path }) => path.startsWith('/'));

  const currentUser = useCurrentUser();
  const brands      = useBrands();

  const noAccess = currentUser.locations.length === 0 && currentUser.organizations.length === 0;
  if (noAccess) {
    return (
      <FullscreenMessage>
        <FullscreenMessage.Title>You do not have access to any locations.</FullscreenMessage.Title>
        <p>If you think this is an error contact support for help.</p>
        <p>
          Phone: <a href="tel:+18007270445">800.727.0445</a>
          <br/>Email: <a href="mailto:support@broadly.com">support@broadly.com</a>
        </p>
      </FullscreenMessage>
    );
  }


  return (
    <BrowserRouter>
      <Switch>
        {localRoutes.map(({ path, title, component }) => (
          hasOrganization(path) ? (
            <WithBrand key={path} path={path} brands={brands}>
              {React.cloneElement(children, { title, component })}
            </WithBrand>
          ) : (
            <Route key={path} path={path} exact>
              {React.cloneElement(children, { title, component })}
            </Route>
          )
        ))}
        <Route path="/"><RedirectToFirstPage brands={brands}/></Route>
      </Switch>
    </BrowserRouter>
  );
}


// Does this route reference an organization
function hasOrganization(path) {
  return path.includes('/:organizationID/');
}

// Make sure the organization exists before rendering the page.
function WithBrand({ path, children, brands }) {
  // With this trick, if the URL looks like "/_/metrics", then
  // params.organizationID will be undefined instead of "_".
  // The rest of the code doesn't need to know about "_".
  const paths = [ path.replace('/:organizationID/', `/${noOrgID}/`), path ];
  const brand = useBrandOutsideRoute(paths, brands);

  if (brand) {
    const isOrganization = brand.__typename === 'Organization';
    if (isOrganization) {
      return (
        <WithOrganization organization={brand}>
          <Route path={paths} exact>
            {children}
          </Route>
        </WithOrganization>
      );
    } else {
      return (
        <WithLocation location={brand}>
          <Route path={paths} exact>
            {children}
          </Route>
        </WithLocation>
      );
    }
  } else
    return <RedirectToFirstPage path={path} brands={brands}/>;
}


// Inside a route (ie anywhere on the page) you want to call
// useOrganization().  Outside the route, we need to be more crafty.
function useBrandOutsideRoute(paths, brands) {
  const { params }         = useRouteMatch({ path: paths });
  const [ locations ]      = useSearchParameterState('locations');
  const locationID         = `${locations}`.split(',').shift();
  const { organizationID } = params;
  const brand              = brands.find(({ id }) => [ organizationID, locationID ].includes(id));

  return brand;
}


// If URL doesn't exist or user has no access to that page, we redirect them to
// the metrics page of the first organization they have access to or to their
// first location. If the user has access to multiple brands, redirect to the
// brands page instead.
function RedirectToFirstPage({ path, brands }) {
  const defaultRoute   = brands.length > 1 ? routes.brands : routes.metrics;
  const organization   = brands.filter(({ isOrganization }) => isOrganization).sort(alphabetically).shift();
  const location       = brands.filter(({ isOrganization }) => !isOrganization).sort(alphabetically).shift();
  const organizationID = organization?.id || noOrgID;
  const search         = (
    !organization &&
    location &&
    `?locations=${location.id}&${window.location.search.slice(1)}` ||
    window.location.search
  );
  const pathname       = path?.replace(':organizationID', organizationID) || defaultRoute.url({ organizationID });

  return (
    <Redirect to={{ pathname, search }} push/>
  );
}

function alphabetically(a, b) {
  return a.name.localeCompare(b.name);
}

import { useContext }     from 'react';
import { useCurrentUser } from './use_current_user';
import { useMemo }        from 'react';
import Query              from '../components/query';
import query              from '../graphql/queries/organizations.graphql';
import React              from 'react';


const Context = React.createContext(null);


// Reads organization (including all locations) from backend
// and renders children elements.
//
// If the user has access to locations that belong to an organization,
// but no access to the organization, we don't try to get the
// organization details from the backend (no permission). Instead,
// we set the organization using the locations provided by the
// current user query.
//
// The organization is available in the context, use useOrganization to read it.
export function WithOrganization({ children, organization }) {
  const currentUser             = useCurrentUser();
  const hasAccessToOrganization = currentUser.organizations
    .find(o => o.id === organization.id);

  const organizationWithLocationsFromCurrentUser = useMemo(() => ({
    ...organization,
    locations: currentUser.locations.filter(l => l.organization?.id === organization.id)
  }), [ currentUser, organization ]);

  if (hasAccessToOrganization) {
    return (
      <Query query={query} variables={{ id: organization.id }}>
        {({ organizations }) => (
          <SetOrganization organization={organizations.edges[0].node}>
            {children}
          </SetOrganization>
        )}
      </Query>
    );
  } else {
    return (
      <SetOrganization organization={organizationWithLocationsFromCurrentUser}>
        {children}
      </SetOrganization>
    );
  }
}


// Context provider, exported for testing.
export function SetOrganization({ children, organization }) {
  return (
    <Context.Provider value={organization}>
      {children}
    </Context.Provider>
  );
}


// Returns the current organization.
export function useOrganization() {
  return useContext(Context);
}


// Returns a map of all the organizations the user has access to.
export function useOrganizations() {
  const { organizations } = useCurrentUser();
  return useMemo(
    () => organizations.reduce((accum, organization) => accum.set(organization.id, organization), new Map()),
    [ organizations ]
  );
}

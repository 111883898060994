import { getApolloContext } from '@apollo/react-hooks';
import { useContext }       from 'react';
import { useCurrentUser }   from '../util/use_current_user';
import { useEffect }        from 'react';
import { useRef }           from 'react';
import { useState }         from 'react';
import query                from '../graphql/queries/users.graphql';


export default function useUsers({ userIDs, first = 300 } = {}) {
  const currentUser                       = useCurrentUser();
  const { client }                        = useContext(getApolloContext());
  const [ error, setError ]               = useState(null);
  const [ loadingState, setLoadingState ] = useState('pending');
  const users                             = useRef([]);

  function onComplete() {
    setLoadingState('finished');
  }

  function onData(newUsers) {
    users.current.push(...newUsers);
  }

  function onError(err) {
    setError(err);
    setLoadingState('complete');
  }

  useEffect(function() {
    function isCurrentUser() {
      if (userIDs && userIDs.length === 1)
        return userIDs[0] === currentUser.id;
      else
        return false;
    }

    if (isCurrentUser()) {
      setLoadingState('complete');
      return onData([ currentUser ]);
    } else {
      return loadUsers({
        client,
        onComplete,
        onData,
        onError,
        first,
        userIDs
      });
    }
  }, [ client, userIDs, first, currentUser ]);

  const isLoading = (loadingState === 'pending');

  return { users: users.current, error, isLoading };
}

function loadUsers({ client, onData, onComplete, onError, first, userIDs }) {
  let isUnmounted = false;

  function start() {
    queryUsers();
  }

  async function queryUsers(cursor = null) {
    const { data, error } = await client.query({
      query,
      fetchPolicy: 'no-cache',
      variables:   {
        userIDs,
        first,
        after: cursor
      }
    });

    // Component was unmounted, don't try to change the state.
    if (isUnmounted)
      return null;

    if (error)
      return onError(error);
    else
      return await onNewData(data);
  }

  async function onNewData(data) {
    const users = data.users.edges.map(({ node }) => node);

    onData(users);

    const { pageInfo } = data.users;
    if (pageInfo.hasNextPage)
      return await queryUsers(pageInfo.endCursor);
    else {
      onComplete();
      return null;
    }
  }

  function stop() {
    isUnmounted = true;
  }

  start();

  return stop;
}

// eslint-disable-next-line no-undef
window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer.push(arguments);
}

gtag('js', new Date());
gtag('config', 'UA-44184500-7', {
  send_page_view: false,
  custom_map:     {
    dimension1: 'organization'
  }
});


export function sendScreenView({ screenName, ...other }) {
  gtag('config', 'UA-44184500-7', {
    page_title:     document.title,
    page_location:  window.location.href,
    send_page_view: false
  });

  gtag('event', 'screen_view', {
    app_name:    'Dashboard',
    screen_name: screenName,
    ...other
  });
}

import * as DateFns from 'date-fns';
import { useGroup } from '../util/crossfilter';


// Returns aggregate for current and previous time period.
//
// Returns an object { current, previous }.
//
// Each aggregate has { promoters, passives, detractors, responded, score }.
export default function useAggregates({ dimension, reducers, dateRange }) {
  const group = useGroup({ dimension, reducers });

  const daysInRange   = DateFns.differenceInDays(
    DateFns.parseISO(dateRange.endDate),
    DateFns.parseISO(dateRange.startDate)
  ) + 1;
  const previousRange = {
    startDate: DateFns.lightFormat(
      DateFns.subDays(DateFns.parseISO(dateRange.startDate), daysInRange + 1),
      'yyyy-MM-dd'
    ),
    endDate: DateFns.lightFormat(
      DateFns.subDays(DateFns.parseISO(dateRange.startDate), 1),
      'yyyy-MM-dd'
    )
  };

  const current  = addInRange({ group, dateRange, reducers });
  const previous = addInRange({ group, dateRange: previousRange, reducers });

  return { current, previous };
}


function addInRange({ group, dateRange, reducers }) {
  const addReducer     = reducers[0];
  const initialReducer = reducers[2];
  return group.all()
    .filter(function({ key: date }) {
      return date >= dateRange.startDate && date <= dateRange.endDate;
    })
    .map(({ value }) => value)
    .reduce(addReducer, initialReducer());
}

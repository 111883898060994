import { getApolloContext } from '@apollo/react-hooks';
import { useContext }       from 'react';
import { useEffect }        from 'react';
import { useState }         from 'react';
import query                from '../graphql/queries/feedback_next.graphql';
import removeEmptyVariables from './remove_empty_variables_from_query';

export function useFeedback({
  localStartDate,
  localEndDate,
  responseOption,
  keyword,
  locationIDs,
  providerIDs,
  searchText,
  sentiment,
  visibility,
  first,
  after,
  setEndCursor,
  setError,
  setAfter
}) {
  const [ feedback, setFeedback ] = useState(null);
  const { client }                = useContext(getApolloContext());

  const loadFeedbackInput = {
    client,
    localStartDate,
    localEndDate,
    responseOption,
    keyword,
    locationIDs,
    providerIDs,
    searchText,
    sentiment,
    visibility,
    first,
    after,
    setEndCursor,
    setError,
    setFeedback
  };

  useEffect(() => {
    setFeedback(null);
    setEndCursor(null);
    setAfter(null);
    return loadFeedback({ ...loadFeedbackInput, after: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ localStartDate, localEndDate, responseOption, keyword, locationIDs, providerIDs, searchText, sentiment, visibility, setEndCursor, setAfter ]);

  // Load more feedback (without clearing the crossfilter) when after changes
  useEffect(() => {
    // Don't run this initially when 'after' is not set. The useEffect hook
    // above will do that.
    if (after)
      return loadFeedback(loadFeedbackInput);
    else
      return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ after ]);

  const isLoading = !feedback;
  return { isLoading, feedback };
}

export function loadFeedback({
  client,
  localStartDate,
  localEndDate,
  responseOption,
  keyword,
  locationIDs,
  providerIDs,
  searchText,
  sentiment,
  visibility,
  first,
  after,
  setEndCursor,
  setError,
  setFeedback
}) {
  let isUnmounted = false;

  function start() {
    setError(null);
    queryFeedback();
  }

  async function queryFeedback() {
    const variables = {
      locationIDs,
      startDate: localStartDate,
      endDate:   localEndDate,
      responseOption,
      keyword,
      providerIDs,
      searchText,
      sentiment,
      visibility,
      first,
      after
    };

    const notEmptyVariables = removeEmptyVariables(variables);

    const { data, error } = await client.query({
      query,
      variables: notEmptyVariables
    });

    // Component was unmounted, don't try to change the state.
    if (isUnmounted)
      return;

    if (error)
      onError(error);
    else
      onNewData(data);
  }

  function onError(error) {
    setError(error);
    setFeedback([]);
  }

  function onNewData(data) {
    const feedback = getFeedback(data);
    setFeedback(prev => {
      if (prev)
        return [ ...prev, ...feedback ];
      else
        return feedback;
    });

    const { pageInfo: { hasNextPage, endCursor } } = data.feedbackNext;
    if (hasNextPage)
      setEndCursor(endCursor);
    else
      setEndCursor(null);
  }

  // When user navigates to a different page and component unmounted, call this
  // function to stop loading more feedback, and stop updating component state.
  function stop() {
    isUnmounted = true;
  }

  start();

  return stop;
}


function getFeedback(data) {
  return data.feedbackNext.edges.map(({ node }) => node.feedback);
}

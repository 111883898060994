import currency      from 'currency.js';
import useAggregates from './use_aggregates';

const currencyFormatting = { symbol: '' };

const reducers = [
  function addReducer({ leads: acc }, { leads: value }) {
    const newLeads          = acc.newLeads          + (value.newLeads || 0);
    const respondedTo       = acc.respondedTo       + (value.respondedTo || 0);
    const totalResponseTime = acc.totalResponseTime + (value.responseTime || 0) * (value.respondedTo || 0);
    const totalConverted    = acc.totalConverted    + (value.totalConverted || 0);
    const responseRate      = newLeads ? (respondedTo / newLeads) : 0;
    const responseTime      = respondedTo ? (totalResponseTime / respondedTo) : 0;
    const totalRevenue      = currency(acc.totalRevenue).add(value.totalRevenue || 0).format(currencyFormatting);
    const sources           = getSources({
      currentSources: acc.sources,
      newSources:     value.sources || [],
      aggregator:     (a, b = 0) => a + b
    });

    return {
      leads: {
        newLeads,
        respondedTo,
        totalResponseTime,
        responseRate,
        responseTime,
        totalConverted,
        totalRevenue,
        sources
      }
    };
  },
  function subtractReducer({ leads: acc }, { leads: value }) {
    const newLeads          = acc.newLeads          - (value.newLeads || 0);
    const respondedTo       = acc.respondedTo       - (value.respondedTo || 0);
    const totalResponseTime = acc.totalResponseTime - (value.responseTime || 0) * (value.respondedTo || 0);
    const totalConverted    = acc.totalConverted    - (value.totalConverted || 0);
    const responseRate      = newLeads ? (respondedTo / newLeads) : 0;
    const responseTime      = respondedTo ? (totalResponseTime / respondedTo) : 0;
    const totalRevenue      = currency(acc.totalRevenue).subtract(value.totalRevenue || 0).format(currencyFormatting);
    const sources           = getSources({
      currentSources: acc.sources,
      newSources:     value.sources || [],
      aggregator:     (a, b = 0) => a - b
    });

    return {
      leads: {
        newLeads,
        respondedTo,
        totalResponseTime,
        responseRate,
        responseTime,
        totalConverted,
        totalRevenue,
        sources
      }
    };
  },
  function initialReducer() {
    return ({
      leads: {
        newLeads:          0,
        respondedTo:       0,
        responseRate:      0,
        totalResponseTime: 0,
        responseTime:      0,
        sources:           [],
        totalConverted:    0,
        totalRevenue:      '0.00'
      }
    });
  }
];


function getSources({ currentSources, newSources = [], aggregator }) {
  const currentSourceNames = currentSources?.map(({ source }) => source) ?? [];
  const newSourceNames     = newSources?.map(({ source }) => source) ?? [];
  const allSourceNames     = [ ...newSourceNames, ...currentSourceNames ].filter(Boolean);

  const sources = [ ...new Set(allSourceNames) ].map(source => {
    const currentSource = currentSources?.find(({ source: sourceName }) => source === sourceName);
    const newSource     = newSources?.find(({ source: sourceName }) => source === sourceName);
    const currentValue  = currentSource?.sourceTotal || 0;
    const newValue      = newSource?.sourceTotal || 0;
    const sourceTotal   = aggregator(currentValue, newValue);

    return {
      source,
      sourceTotal
    };
  });

  return sources;
}


export default function useLeadAggregates({ dimension, dateRange }) {
  return useAggregates({ dimension, reducers, dateRange });
}

import './locations.less';

import { useCallback }         from 'react';
import { useDimension }        from '../util/crossfilter';
import { useFilter }           from '../util/crossfilter';
import { useGroup }            from '../util/crossfilter';
import { useLogPageViewEvent } from '../util/use_log_page_view_event';
import { useNotification }     from '../components/notification';
import { useOnChange }         from '../util/crossfilter';
import { WideBlock }           from '../components/block';
import Block                   from '../components/block';
import Facets                  from './facets';
import getFilterDate           from '../util/filter_date';
import getFilteredGroup        from '../util/get_filtered_group';
import LoadingShimmer          from '../components/loading_shimmer';
import LocationsOrBrandsTable  from '../components/locations_or_brands_table';
import React                   from 'react';
import reducers                from './location_reducers';
import useDateDimension        from '../hooks/use_date_dimension';
import useDateRange            from '../hooks/use_date_range';
import useIsNPS                from '../util/use_is_nps';
import useLocations            from '../util/use_locations';
import useMetrics              from '../brands/use_location_metrics';
import useSelectedLocations    from '../util/use_selected_locations';


export default function LocationsPage() {
  const locations                   = useLocations();
  const isNPS                       = useIsNPS();
  const { showError }               = useNotification();
  const { minDate, maxDate }        = getFilterDate();
  const [ dateRange, setDateRange ] = useDateRange({ maxDate });
  const { cf, error, isLoading }    = useMetrics({ locations, ...dateRange, minDate, maxDate });
  useDateDimension(cf, dateRange);
  useOnChange(cf);

  useLogPageViewEvent('DashboardLocationViewed');

  const locationsSummary = useLocationsMetrics({ cf });

  if (error) {
    console.error(error);
    showError('Something went wrong. Refresh the page to try again.');
    return null;
  }

  return (
    <div className="locations-page">
      <Block>
        <Facets {...{ cf, minDate, maxDate, dateRange, setDateRange, isLoading, locationsSummary, isNPS }}/>
        <h2>Locations</h2>
        <Copy/>
        {isLoading && <LoadingShimmer/>}
      </Block>
      <WideBlock>
        {!isLoading && <LocationsOrBrandsTable data={locationsSummary} type="locations"/>}
      </WideBlock>
    </div>
  );
}


function useLocationsMetrics({ cf }) {
  const selectedLocations = useSelectedLocations();
  const dimension         = useDimension(cf, ({ location }) => location.id);
  const filter            = useCallback(locationID => (
    selectedLocations.has(locationID)
  ), [ selectedLocations ]);
  useFilter(dimension, filter);
  const group            = useGroup({ dimension, reducers });
  const locationsMetrics = getLocations({ locationsMap: selectedLocations, group, dimension });
  return locationsMetrics;
}


export function getLocations({ locationsMap, group, dimension }) {
  const filtered = getFilteredGroup({ group, dimension });

  return [ ...locationsMap.keys() ].map(locationID => {
    const location       = locationsMap.get(locationID);
    const locationMetric = filtered.find(({ key }) => key === locationID);
    const metric         = locationMetric?.value;
    const classifiedAs   = metric?.classifiedAs;
    return {
      ...metric,
      ...location,
      classifiedAs
    };
  });
}

function Copy() {
  return (
    <p className="copy">Compare your customer satisfaction and online reputation across all of your locations.</p>
  );
}

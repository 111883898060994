import { getApolloContext } from '@apollo/react-hooks';
import { useContext }       from 'react';
import { useCrossfilter }   from '../util/crossfilter';
import { useEffect }        from 'react';
import { useState }         from 'react';
import query                from '../graphql/queries/organization_metrics.graphql';
import uniqBy               from '../util/uniq_by';


export default function useOrganizationMetrics({ organizations, startDate, endDate }) {
  const { client }                               = useContext(getApolloContext());
  const [ error, setError ]                      = useState(null);
  const { cf, replace, clear: clearCrossfilter } = useCrossfilter();
  const [ isLoading, setIsLoading ]              = useState(true);
  const [ metrics, setMetrics ]                  = useState([]);

  function onData(newNodes) {
    setMetrics(previousNodes => uniqBy([ ...previousNodes, ...newNodes ]));
  }

  function clearMetrics() {
    setMetrics([]);
  }

  function onError(err) {
    setError(err);
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    clearCrossfilter();
    clearMetrics();
  }, [ organizations, startDate, endDate ]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update the crossfilter every time we get updated metrics.
  useEffect(() => {
    replace(metrics);
  }, [ metrics ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(function() {
    if (isLoading) {
      return loadOrganizationMetrics({
        client,
        organizations,
        startDate,
        endDate,
        onData,
        onComplete: () => setIsLoading(false),
        onError,
        first:      3000
      });
    } else
      return () => {};
  }, [ startDate, endDate, isLoading ]); // eslint-disable-line react-hooks/exhaustive-deps

  return { cf, error, isLoading };
}

function loadOrganizationMetrics({ client, organizations, startDate, endDate, onData, onComplete, onError, first }) {
  let isUnmounted = false;

  async function queryOrganizationMetrics(cursor = null) {
    const { data, error } = await client.query({
      query,
      variables: {
        organizationIDs: [ ...organizations.keys() ],
        startDate,
        endDate,
        first,
        after:           cursor
      }
    });

    // Component was unmounted, don't try to change the state.
    if (isUnmounted)
      return;

    if (error)
      onError(error);
    else
      onNewData(data);
  }

  function onNewData(data) {
    const nodes = data.organizationMetrics.edges
      .map(({ node }) => ({
        ...node,
        organization: organizations.get(node.organization.id)
      }));
    onData(nodes);

    const { pageInfo } = data.organizationMetrics;
    if (pageInfo.hasNextPage)
      queryOrganizationMetrics(pageInfo.endCursor);
    else
      onComplete();
  }

  // When user navigates to a different page and component unmounted, call this
  // function to stop loading more metrics, and stop updating component state.
  function stop() {
    isUnmounted = true;
  }

  queryOrganizationMetrics();

  return stop;
}

import { loadFeedback }        from './use_feedback';
import { useAccessToken }      from '../app/authenticate';
import { useState }            from 'react';
import BrandSelector           from '../components/brand_selector';
import createFeedbackWorksheet from './feedback_worksheet';
import createKeywordWorksheet  from './keyword_worksheet';
import DateRangeSelector       from '../components/date_range_selector';
import DownloadExcel           from '../components/download_excel';
import Locations               from '../facets/locations_without_cf';
import newApolloClient         from '../app/new_apollo_client';
import React                   from 'react';
import useIsNPS                from '../util/use_is_nps';
import useSelectedLocations    from '../util/use_selected_locations';


export default function Facets({ isLoading, dateRange, minDate, maxDate, setDateRange, keywordsFacet }) {
  return (
    <div className="facets">
      <div className="brand-location-selectors">
        <BrandSelector/>
        <Locations/>
      </div>
      <div className="date-download">
        <DateRangeSelector {...{ dateRange, minDate, maxDate, setDateRange }}/>
        <Download {...{ isLoading, keywordsFacet, dateRange }}/>
      </div>
    </div>
  );
}


function Download({ isLoading, keywordsFacet, dateRange }) {
  // Using a separate apollo client so that the data for the export
  // is independent of the data shown on the screen
  const { accessToken, reauthenticate } = useAccessToken();
  const client                          = newApolloClient({ accessToken, reauthenticate });

  const locations              = useSelectedLocations();
  const isNPS                  = useIsNPS();
  const [ , setButtonClicked ] = useState(false);


  function exportClickHandler(dataCallback) {
    // loadData requires a setFeedback function, which normally triggers an
    // async render cycle, but we need access to the resulting feedback
    // syncronously when loadData resolves
    let feedback = [];
    function setFeedback(input) {
      if (typeof input === 'function')
        feedback = input(feedback);
      else
        feedback = input;
    }

    setButtonClicked(true);

    const params = {
      ...dateRange,
      client,
      locations,
      setFeedback
    };

    loadData(params)
      .then(() => {
        const worksheets = [
          createFeedbackWorksheet({ allFeedback: feedback, locations, isNPS }),
          createKeywordWorksheet({ keywords: keywordsFacet, isNPS })
        ];
        dataCallback(null, { worksheets });
      })
      .finally(() => {
        // Reset everything
        setButtonClicked(false);
        client.resetStore(); // Clear the apollo cache to free the memory
      });
  }

  return (
    <div className="download">
      <DownloadExcel disabled={isLoading} logName="DashboardReviewsExported">
        {exportClickHandler}
      </DownloadExcel>
    </div>
  );
}


function loadData({ localStartDate, localEndDate, client, setFeedback, locations }) {
  return new Promise((resolve, reject) => {
    const params = {
      localStartDate,
      localEndDate,
      client,
      locationIDs: [ ...locations.keys() ],
      first:       1000,
      after:       null,
      setEndCursor,
      setError,
      setFeedback
    };

    function setEndCursor(endCursor) {
      if (endCursor)
        loadFeedback({ ...params, after: endCursor });
      else
        resolve();
    }

    function setError(error) {
      if (error)
        reject(error);
    }

    loadFeedback(params);
  });
}

import { useLocation }             from './use_locations';
import { useMemo }                 from 'react';
import { useOrganization }         from './use_organizations';
import { useSearchParameterState } from '../util/use_search_params';



export default function useSelectedLocations() {
  const organization = useOrganization();
  const location     = useLocation();
  const [ query ]    = useSearchParameterState('locations');

  return useMemo(
    () => toMap(getEntries({ organization, location, query })),
    [ organization, location, query ]
  );
}


function getEntries({ organization, location, query }) {
  if (organization) {
    if (query) {
      const ids = new Set(query.split(','));
      return organization.locations.filter(({ id }) => ids.has(id));
    } else
      return organization.locations;
  } else if (location)
    return [ location ];
  else
    return [];
}


function toMap(locationsArray) {
  const locationsMap = new Map(
    locationsArray.map(location => [ location.id, location ])
  );
  return locationsMap;
}

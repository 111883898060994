
export function isBadRequestError(error) {
  const graphQLErrors = error.graphQLErrors || [];
  return graphQLErrors.some(err => err?.extensions?.code === 'BAD_USER_INPUT');
}

export function getBadRequestErrorMessage(error) {
  const graphQLErrors = error.graphQLErrors || [];

  const invalidArgs = graphQLErrors
    .filter(err => err?.extensions?.code === 'BAD_USER_INPUT' && !!err?.extensions?.invalidArgs.length)
    .flatMap(err => err.extensions.invalidArgs)
    .map(invalidArg => invalidArg.charAt(0).toUpperCase() + invalidArg.slice(1))
    .join(', ');

  return `Invalid arguments, please try again after updating these fields: ${invalidArgs}`;
}

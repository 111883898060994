export function alphaNumericCompare(rowA, rowB, ascending) {
  const [ lower, equal, higher ] = [ -1, 0, 1 ];

  if (isUndef(rowA) && !isUndef(rowB))
    return ascending ? lower : higher;

  if (!isUndef(rowA) && isUndef(rowB))
    return ascending ? higher : lower;

  if (isUndef(rowA) && isUndef(rowB))
    return equal;

  if (isNaN(rowA) || isNaN(rowB)) {
    if (ascending)
      return rowA.localeCompare(rowB);
    else
      return rowB.localeCompare(rowA);
  }

  if (ascending)
    return rowA - rowB;
  else
    return rowB - rowA;
}

function isUndef(val) {
  return typeof val === 'undefined';
}

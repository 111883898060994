import { useSearchParameterState } from '../util/use_search_params';
import useLocations                from '../util/use_locations';
import useProvidersData            from '../util/use_providers';


export default function useProviders() {
  const { providers }     = useProvidersData();
  const locations         = useLocations();
  const [ fromQuery ]     = useSearchParameterState('locations');
  const selected          = new Set((fromQuery || '').split(',').filter(Boolean));
  const filteredLocations = getFilteredLocations([ ...locations.values() ], selected);
  const locationsIDs      = filteredLocations.map(({ id }) => id);

  return providers.filter(provider => locationsIDs.includes(provider.location.id));
}


function getFilteredLocations(locations, selected) {
  if (selected.size)
    return locations.filter(location => selected.has(location.id));
  else
    return locations;
}

// Given a list of locations, decide which features we need to show.
// Not to be confused with features exposed from GraphQL -- those are
// the features available to the location).
// Here we also consider whether the location decided to use the feature
// (e.g. Facebook available and profile set up).
export default function getDisplayFeatures(locations) {
  let google      = false;
  let facebook    = false;
  let tripAdvisor = false;
  let nextdoor    = false;
  let yelp        = false;

  let nps               = false;
  let satisfactionScore = false;
  let webchat           = false;

  for (const location of locations) {
    google      = google      ||
        location.features.profiles.google &&
        !!(location.profiles?.google.id || location.profiles?.google.cid);
    facebook    = facebook    ||
        location.features.profiles.facebook &&
        !!location.profiles?.facebook.pageID;
    yelp        = yelp        ||
        location.features.profiles.yelp &&
        !!location.profiles?.yelp.id;
    tripAdvisor = tripAdvisor ||
        location.features.profiles.tripAdvisor &&
        !!location.profiles?.tripAdvisor.id;
    nextdoor    = nextdoor    ||
        location.features.profiles.nextdoor &&
        !!location.profiles?.nextdoor.id;

    nps               = nps || location.askForFeedback === 'NPS';
    satisfactionScore = satisfactionScore || location.askForFeedback !== 'NPS';

    // TODO: Should be false if location disabled Web Chat?
    webchat = webchat || location.features.webchat;

    if (google && facebook && yelp && tripAdvisor && nextdoor && nps && satisfactionScore && webchat)
      break;
  }

  return {
    google,
    facebook,
    tripAdvisor,
    nextdoor,
    yelp,
    nps,
    satisfactionScore,
    webchat
  };
}

import './sortable_table.less';

import { Link }      from 'react-router-dom';
import classnames    from 'classnames';
import React         from 'react';
import SortableArrow from './sortable_arrow.svg';


function SortableTable({ children }) {
  return (
    <table className="sortable-table">
      {children}
    </table>
  );
}


SortableTable.Head = function Head({ children: headerCells, direction, ...props }) {
  return (
    <thead {...props}>
      <tr className="row-title">
        {headerCells.map((headerCell, i) => {
          if (!headerCell.props)
            return null;

          const { children, className, active, ...headerCellProps } = headerCell.props;

          const cellClassName = classnames(className, direction, { active });

          return (
            <th key={`table-header-${i}`} {...headerCellProps} className={cellClassName}>
              <div className="column-label">
                {children}
              </div>
              <div className="sort-arrow"><SortableArrow/></div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};


SortableTable.Body = function Body({ children, ...props }) {
  return (
    <tbody {...props}>
      {children}
    </tbody>
  );
};


SortableTable.Cell = function Cell({ href, children, value, ...props }) {
  if (href) {
    return (
      <td {...props}>
        <Link to={href}>{children}</Link>
      </td>
    );
  } else {
    return (
      <td {...props}>
        {shouldRender(children, value) ? <span>{children}</span> : null}
      </td>
    );
  }
};


function shouldRender(children, value) {
  const parsedValue = value && value.toString().includes('.') ? parseFloat(value) : parseInt(value, 10);
  const isZero      = parsedValue === 0 || parseInt(children, 10) === 0;
  return children && !isZero;
}


export default SortableTable;

import './metrics_reviews.less';

import * as d3                  from 'd3';
import { getStarsTicks }        from '../../util/ticks';
import { Star }                 from 'react-feather';
import { useGroup }             from '../../util/crossfilter';
import { VisualizeMetricsBar }  from '../../components/visualize_metrics';
import { VisualizeMetricsLine } from '../../components/visualize_metrics';
import classnames               from 'classnames';
import FacebookLogo             from '../../images/facebook_logo.svg';
import getDisplayFeatures       from '../../util/display_features';
import GoogleLogo               from '../../images/google_logo.svg';
import MetricsPageSection       from '../metrics_page_section';
import NextdoorLogo             from '../../images/nextdoor_logo.svg';
import React                    from 'react';
import reducers                 from './review_reducers';
import TripAdvisorLogo          from '../../images/trip_advisor_logo.svg';
import useSelectedLocations     from '../../util/use_selected_locations';
import YelpLogo                 from '../../images/yelp_logo.svg';


export const allServices = [
  {
    service:    'google',
    title:      'Google',
    icon:       GoogleLogo,
    color:      '#F2C94C',
    hasRatings: true
  },
  {
    service:    'facebook',
    title:      'Facebook',
    icon:       FacebookLogo,
    color:      '#2F80ED',
    hasRatings: true
  },
  {
    service:    'yelp',
    title:      'Yelp',
    icon:       YelpLogo,
    color:      '#D32323',
    hasRatings: true
  },
  {
    service:    'tripAdvisor',
    title:      'TripAdvisor',
    icon:       TripAdvisorLogo,
    color:      '#34e0a1',
    hasRatings: true
  },
  {
    service:    'nextdoor',
    title:      'Nextdoor',
    icon:       NextdoorLogo,
    color:      '#7DC49B',
    hasRatings: false
  }
];


export default function Reviews({ cf, dimension, dateRange }) {
  const services          = useAvailableServices();
  const currentValues     = useCurrentServiceValues(services);
  const serviceDates      = useHistoricalData({ services, dimension, dateRange });
  const serviceAggregates = getAggregates({ services, cf, dateRange });

  const twoColumnServices = services.filter(({ hasRatings }) => hasRatings);
  const oneColumnServices = services.filter(({ hasRatings }) => !hasRatings);

  // put two-column services first so we don't have holes in the layout
  const orderedServices = [
    ...twoColumnServices,
    ...oneColumnServices
  ];

  return (
    <MetricsPageSection className="metrics-reviews">
      {orderedServices.map(({ service, title, icon, color, hasRatings }) => (
        <Service
          key={service}
          dates={serviceDates[service]}
          aggregate={serviceAggregates[service]}
          {...{ service, title, icon, color, hasRatings, ...currentValues[service] }}
        />
      ))}
    </MetricsPageSection>
  );
}



function useAvailableServices() {
  const features = getDisplayFeatures(useSelectedLocations().values());

  const enabledServices = allServices.filter(service => {
    return features[service.service];
  });

  return enabledServices;
}


function useCurrentServiceValues(services) {
  const locations      = Array.from(useSelectedLocations().values());
  const servicesValues = locations.map(({ profiles }) => profiles)
    .reduce((acc, locationProfiles) => {
      services.forEach(({ service }) => {
        const serviceValue = locationProfiles[service];
        if (!serviceValue && !Object.values(serviceValue).some(Boolean))
          return;

        if (!serviceValue.reviewCount)
          return;

        /* eslint-disable no-param-reassign */
        if (!acc[service])
          acc[service] = { rating: 0, ratingSum: 0, reviews: 0, sumCount: 0 };

        acc[service].sumCount++;
        acc[service].ratingSum += serviceValue.rating || 0;
        acc[service].reviews   += serviceValue.reviewCount || 0;
        acc[service].rating     = roundRating(acc[service].ratingSum / acc[service].sumCount) || 0;
        /* eslint-enable no-param-reassign */
      });

      return acc;
    }, {});

  return servicesValues;
}

function Service({ service, title, icon: Icon, color, hasRatings, dates, aggregate, rating, reviews }) {
  if (dates.length === 0)
    return null;

  const subtitle  = hasRatings ? 'Ratings & Reviews' : 'Reviews';
  const className = classnames('service', service, {
    'has-ratings': hasRatings
  });

  return (
    <div className={className}>
      <div className="section-header">
        {Icon && (
          <div className="section-header-icon">
            <Icon/>
          </div>
        )}
        <div className="section-header-text">
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </div>
      </div>
      <p>
        {rating !== 0 && <>You have an overall rating of {rating} out of 5 and have {reviews} reviews on {title}. </>}
        {rating === 0 && <>You have {reviews} reviews on {title}. </>}
        Below is a breakdown of your performance over time.
      </p>
      <div className="charts">
        {hasRatings && <StarsChart {...{ color, dates, aggregate }}/>}
        <ReviewsChart {...{ color, dates, aggregate }}/>
      </div>
    </div>
  );
}

function StarsChart({ color, dates, aggregate }) {
  const starsMetric = getStarsMetric({ color, dates, aggregate });
  return (
    <VisualizeMetricsLine metrics={[ starsMetric ]} formatter={formatRating}/>
  );
}

function ReviewsChart({ color, dates }) {
  const formatter     = d3.format(',d');
  const reviewsMetric = getReviewsMetric({ color, dates });
  return (
    <VisualizeMetricsBar metrics={[ reviewsMetric ]} formatter={formatter}/>
  );
}


function getStarsMetric({ color = '#F2C94C', dates, aggregate }) {
  const first  = aggregate.baseline.averageRating;
  const last   = aggregate.last.averageRating;
  const change = roundRating(last) - roundRating(first);
  const delta  = getStarsDelta(change);

  const title   = 'Stars';
  const meta    = 'On Average';
  const current = <CurrentStarRating rating={last}/>;

  const points = dates.map(({ key, value }) => {
    const x = key;
    const y = roundRating(value.rating);
    return { x, y };
  });

  const allYValues = points.map(point => point.y);
  const ticks      = getStarsTicks({ values: allYValues });

  return {
    title,
    meta,
    current,
    delta,
    color,
    points,
    ticks
  };
}

function getStarsDelta(change) {
  const formatter = d3.format('+.1f');
  const rounded   = roundRating(change);
  const formatted = formatter(rounded);

  const text    = rounded === 0 ? '' : formatted;
  const quality = rounded < 0 ? 'bad' : 'good';
  return {
    text,
    quality
  };
}

export function roundRating(rating) {
  // rounding to nearest 0.1, using EPSILON for precision
  // https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
  // if the number is positive, add epsilon; else subtract
  const epsilon = rating >= 0 ? Number.EPSILON : -Number.EPSILON;
  return Math.round((rating + epsilon) * 10) / 10;
}

function formatRating(value) {
  const formatter       = d3.format('.1f');
  const roundedRating   = roundRating(value);
  const formattedRating = formatter(roundedRating);
  return formattedRating;
}

function CurrentStarRating({ rating }) {
  return (
    <div className="current-star-rating">
      <Star/>
      <span>{formatRating(rating)}</span>
    </div>
  );
}


function getReviewsMetric({ color = '#F2C94C', dates }) {
  const formatReviewCount = d3.format(',d');

  const points = dates.map(({ key, value }) => {
    const x = key;
    const y = value.reviewsAdded;
    return { x, y };
  });

  const title   = 'Reviews';
  const meta    = 'Added';
  const total   = points.reduce((accumulator, current) => current.y + accumulator, 0);
  const current = formatReviewCount(total);

  return {
    title,
    meta,
    current,
    delta: '',
    color,
    points
  };
}


function useHistoricalData({ services, dimension, dateRange }) {
  const groupedByDate = useGroup({ dimension, reducers });

  const inDateRange = groupedByDate.all()
    .filter(({ key: date }) => (date >= dateRange.startDate && date <= dateRange.endDate));

  const hashedByService = services.reduce((memo, { service }) => {
    const serviceDates = inDateRange.map(({ key, value }) => ({ key, value: value.reviews[service] }));
    return {
      ...memo,
      [service]: serviceDates
    };
  }, {});

  return hashedByService;
}

function getAggregates({ services, cf, dateRange }) {
  const { baseline, last } = cf.allFiltered()
    .filter(({ date }) => date >= dateRange.startDate && date <= dateRange.endDate)
    .reduce(reduceMetrics, { baseline: initial(), last: initial() });

  const totals = {
    baseline: totalAllServices(baseline),
    last:     totalAllServices(last)
  };

  const hashedByService = services.reduce((memo, { service }) => {
    const serviceAggregate = {
      baseline: totals.baseline[service],
      last:     totals.last[service]
    };
    return {
      ...memo,
      [service]: serviceAggregate
    };
  }, {});

  return hashedByService;
}


// Reduces baseline to first metric, and current to last metric, in the given
// time period, for each location/service.
//
// Note that each location has a different start date, and each service may have
// a different state date (when added to location). So Google reviews for
// location A may start October 1st, but for location B on October 4th, and the
// Facebook reviews for location B may start on October 11th.
function reduceMetrics({ baseline, last }, { location: { id: locationID }, reviews }) {
  for (const { service } of allServices) {
    const forService  = reviews[service];
    const rating      = forService?.rating;
    const reviewCount = forService?.reviewCount;

    if (baseline[service].get(locationID)) {
      // fill in missing data in the baseline if needed
      const current = baseline[service].get(locationID);

      baseline[service].set(locationID, {
        ...current,
        reviewCount: current?.reviewCount || reviewCount,
        rating:      current?.rating || rating
      });
    } else {
      // otherwise establish baseline with the first record
      baseline[service].set(locationID, forService);
    }

    if (last[service].get(locationID)) {
      const current = last[service].get(locationID);

      last[service].set(locationID, {
        ...forService,
        reviewCount: reviewCount || current?.reviewCount,
        rating:      rating || current?.rating
      });
    } else
      last[service].set(locationID, forService);
  }
  return { baseline, last };
}

// Returns object { service => Map(locationID => metric) } for reduceMetrics
function initial() {
  return allServices.reduce((all, { service }) => ({ ...all, [service]: new Map() }), {});
}


// For each time period, we have an object
// { service => Map(locationID => metric) }
//
// Calculate total review count and average rating for each service.
function totalAllServices(byService) {
  return allServices
    .map(({ service }) => service)
    .reduce((all, service) => ({
      ...all,
      [service]: totalAllLocations(byService[service])
    }),
    {});
}

// For each service we have a map of Map(locationID => most recent metrics)
//
// Calculate total review count and average rating from all locations.
function totalAllLocations(map) {
  return [ ...map.values() ]
    .reduce((sum, values) => {
      const { reviewCount, rating } = values || {};
      const totalRating             = sum.totalRating + (rating || 0);
      const ratingCount             = sum.ratingCount + (rating ? 1 : 0);
      const averageRating           = ratingCount ? totalRating / ratingCount : 0;
      return {
        reviewCount: sum.reviewCount + (reviewCount || 0),
        totalRating,
        ratingCount,
        averageRating
      };
    }, {
      reviewCount:   0,
      totalRating:   0,
      ratingCount:   0,
      averageRating: null
    });
}

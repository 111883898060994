import { useMemo }  from 'react';
import useLocations from './use_locations';


export default function useClassifySetting() {
  const locations = useLocations();
  const isNone    = useMemo(
    () => [ ...locations.values() ].every(({ askForFeedback }) => askForFeedback === 'NONE'),
    [ locations ]
  );
  const isNPS     = useMemo(
    () => [ ...locations.values() ].some(({ askForFeedback }) => askForFeedback === 'NPS'),
    [ locations ]
  );

  if (isNone)
    return 'NONE';
  else if (isNPS)
    return 'NPS';
  else
    return 'YESNO';
}


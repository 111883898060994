// Load profile of current user.
import { useContext } from 'react';
import Query          from '../components/query';
import query          from '../graphql/queries/current_user.graphql';
import React          from 'react';


const Context = React.createContext(null);


// Gets current user from server and then renders child component.
// The current user is available in the context, use useCurrentUser to read it.
export function WithCurrentUser({ children }) {
  return (
    <Query query={query}>
      {({ me }) => (
        <Context.Provider value={me}>
          {children}
        </Context.Provider>
      )}
    </Query>
  );
}


// Returns the current user.
export function useCurrentUser() {
  return useContext(Context);
}

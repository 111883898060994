import './metrics.less';

import { useLogPageViewEvent } from '../util/use_log_page_view_event';
import { useNotification }     from '../components/notification';
import { useOnChange }         from '../util/crossfilter';
import Block                   from '../components/block';
import CustomersSurveyed       from './customers_surveyed';
import Facets                  from './facets';
import getFilterDate           from '../util/filter_date';
import LeadConversion          from './lead_conversion';
import LoadingShimmer          from '../components/loading_shimmer';
import React                   from 'react';
import Reviews                 from './reviews';
import Satisfaction            from './satisfaction';
import useDateDimension        from '../hooks/use_date_dimension';
import useDateRange            from '../hooks/use_date_range';
import useIsSalesforceUser     from '../util/use_is_salesforce_user';
import useLocations            from '../util/use_locations';
import useMetrics              from './use_metrics';
import useSelectedLocations    from '../util/use_selected_locations';


export default function MetricsPage() {
  const isSalesforceUser            = useIsSalesforceUser();
  const { showError }               = useNotification();
  const locations                   = useLocations();
  const selectedLocations           = useSelectedLocations();
  const { maxDate, minDate }        = getFilterDate();
  const [ dateRange, setDateRange ] = useDateRange({ maxDate });
  const { cf, error, isLoading }    = useMetrics({ locations, ...dateRange, minDate, maxDate });
  const dimension                   = useDateDimension(cf, dateRange);
  useOnChange(cf);

  useLogPageViewEvent('DashboardMetricsViewed');

  if (error) {
    console.error(error);
    showError('Something went wrong. Refresh the page to try again.');
    return null;
  }

  return (
    <Block className="metrics-page">
      <Facets {...{ cf, dateRange, setDateRange, minDate, maxDate, isLoading }}/>
      <h2>Key Metrics</h2>
      <p className="topText">
        It’s important to know how your customers feel about your services.
        Tracking key metrics over time help you understand your customer
        satisfaction and reveal trends to help you to provide a better customer
        experience every time.
      </p>
      {isLoading && <LoadingShimmer/>}
      {!isLoading && <Metrics {...{ cf, locations: selectedLocations, dimension, dateRange, isSalesforceUser }}/>}
    </Block>
  );
}


function Metrics({ cf, dimension, dateRange, isSalesforceUser }) {
  return (
    <div className="metrics-content">

      <Reviews {...{ cf, dimension, dateRange }}/>

      {dimension && dateRange && (
        <Satisfaction {...{ dimension, dateRange }}/>
      )}

      <CustomersSurveyed {...{ dimension, dateRange }}/>

      {isSalesforceUser && <LeadConversion {...{ dimension, dateRange }}/>}
    </div>
  );
}

import 'intersection-observer';
import * as ENV      from './env';
import { useEffect } from 'react';
import { useRef }    from 'react';
import { useState }  from 'react';
import React         from 'react';


// Renders child elements when this component is visible on the screen.
// Until then, renders the placeholder component.
//
// Note, the child elements are not removed from display when they scroll off
// screen.
//
// For example:
//   <IsVisible key={item.id} placeholder={ItemPlaceholder}>
//     <Item {...item}/>
//   </IsVisible>
//
// For options see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Creating_an_intersection_observer
export function IsVisible({ options, children, placeholder }) {
  if (ENV.isTest())
    return children;
  else
    return <CheckIsVisible options={options} placeholder={placeholder}>{children}</CheckIsVisible>;
}

function CheckIsVisible({ options, children, placeholder }) {
  const { nodeRef, wasVisible } = useIsVisible(options);
  const Placeholder             = placeholder;

  return (
    <div ref={nodeRef}>
      {wasVisible ? children : <Placeholder/>}
    </div>
  );
}


// Returns:
// nodeRef:    Use with the element you want to check for visibility
// isVisible:  True if the element is currently visible
// wasVisible: True if the element is currently visible, or was at any time
//
// Common use case is to avoid rendering elements until the user scrolls them
// into view, which for a large list, could be very slow. But once the user has
// scrolled to view the element, there may be no benefit to hiding it from view.
// So for the common use case, you want wasVisible and not isVisible.
//
// For options see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Creating_an_intersection_observer
export function useIsVisible({ rootMargin }) {
  const nodeRef                       = useRef();
  const [ isVisible, setIsVisible ]   = useState(false);
  const [ wasVisible, setWasVisible ] = useState(false);

  useEffect(function() {
    function onChange(entries) {
      const { isIntersecting } = entries[0];
      setIsVisible(isIntersecting);
      if (isIntersecting)
        setWasVisible(true);
    }

    const observer = new IntersectionObserver(onChange, { rootMargin });
    observer.observe(nodeRef.current);
    return function cleanup() {
      observer.disconnect();
    };
  }, [ rootMargin ]);

  return { nodeRef, isVisible, wasVisible };
}

import './navigation.less';

import { Link }                     from 'react-router-dom';
import { noOrgID }                  from './routes';
import { Responsive }               from 'semantic-ui-react';
import { Route }                    from 'react-router-dom';
import { routes }                   from './routes';
import { signOut }                  from './authenticate';
import { useBrands }                from '../util/use_brands';
import { useParams }                from 'react-router';
import { useSearchParametersState } from '../util/use_search_params';
import Block                        from '../components/block';
import BroadlySVG                   from './broadly-full.svg';
import Dropdown                     from '../components/dropdown';
import queryStringPersisted         from '../facets/query_string_persisted';
import React                        from 'react';

import { LogIn }                    from 'react-feather';
import { Menu }                     from 'react-feather';

const defaultRoutes = [
  routes.metrics,
  routes.feedback,
  routes.team,
  routes.locations,
  routes.account
];

export default function Navigation() {
  const brands           = useBrands();
  const navigationRoutes = brands.length > 1 ?
    [ routes.brands, ...defaultRoutes ] :
    defaultRoutes;

  const { organizationID = noOrgID } = useParams();
  const queryString                  = toQueryString(useSearchParametersState());

  // The first route is used as the home page. This will be the brands page if
  // the user has access to more than one brand or the metrics page if not.
  const homePage = navigationRoutes[0];
  const homeURL  = homePage.url({ organizationID, queryString });

  return (
    <Block className="navigation">
      <div className="logo-links">
        <Link to={homeURL} className="logo">
          <BroadlySVG/>
        </Link>
        <div className="links">
          <Responsive maxWidth={Responsive.onlyTablet.minWidth - 1}>
            <MobileLinks navigationRoutes={navigationRoutes}/>
          </Responsive>
          <Responsive minWidth={Responsive.onlyTablet.minWidth}>
            <DesktopLinks navigationRoutes={navigationRoutes}/>
          </Responsive>
        </div>
      </div>
    </Block>
  );
}

// basic logo-only navigation for use on the loading screen
export function NavigationWithLogoOnly() {
  return (
    <Block className="navigation">
      <div className="logo-links">
        <div className="logo">
          <BroadlySVG/>
        </div>
      </div>
    </Block>
  );
}


function DesktopLinks({ navigationRoutes }) {
  const { organizationID = noOrgID } = useParams();
  const queryString                  = toQueryString(useSearchParametersState());
  return (
    <nav className="main-navigation">
      {navigationRoutes.map(({ path, title, url, icon: Icon, className }) => (
        <Route key={path} path={path} exact>
          {({ match }) => (
            <Link to={url({ organizationID, queryString })} className={match ? `current ${className}` : className}>
              <Icon/>
              <span className="text">{title}</span>
            </Link>
          )}
        </Route>
      ))}

      <Link to="#" className="sign-out" onClick={signOut}>
        <LogIn/>
        <span className="text">Sign out</span>
      </Link>
    </nav>
  );
}

// mobile links are rendered as a dropdown instead of a horizontal list
function MobileLinks({ navigationRoutes }) {
  const { organizationID = noOrgID } = useParams();
  const queryString                  = toQueryString(useSearchParametersState());

  return (
    <Dropdown icon={<Menu/>}>
      <Dropdown.Menu>
        {navigationRoutes.map(({ path, title, url }) => (
          <Route key={path} path={path} exact>
            {({ match }) => (
              <Dropdown.Item active={!!match} as={Link} to={url({ organizationID, queryString })}>
                {title}
              </Dropdown.Item>
            )}
          </Route>
        ))}
        <Dropdown.Divider/>
        <Dropdown.Item as={Link} to="#" className="sign-out" onClick={signOut}>
          Sign out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function toQueryString([ query ]) {
  return query && [ ...query.entries() ]
    .filter(([ key ]) => queryStringPersisted.includes(key))
    .map(([ key, value ]) => `${key}=${value}`).join('&');
}

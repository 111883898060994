import { useMemo }         from 'react';
import { useNotification } from '../components/notification';
import { useParams }       from 'react-router';
import AccountForm         from './account_form';
import Block               from '../components/block';
import LoadingShimmer      from '../components/loading_shimmer';
import React               from 'react';
import useUsers            from './use_users';


export default function EditAccountForm() {
  const { showError }               = useNotification();
  const { userID }                  = useParams();
  const userIDs                     = useMemo(() => [ userID ], [ userID ]);
  const { users, error, isLoading } = useUsers({ userIDs, first: 1 });

  if (error)
    showError('Something went wrong. Refresh the page to try again.');

  if (isLoading)
    return <LoadingShimmer/>;

  // Gql query returns an array with only 1 item because it's filtered by userID
  const user = users[0];

  return (
    <div className="account-detail">
      <Block>
        <AccountForm user={user}/>
      </Block>
    </div>
  );
}

import './lead_conversion.less';

import { ArrowUpRight }   from 'react-feather';
import { Clock }          from 'react-feather';
import { Disc }           from 'react-feather';
import { Facebook }       from 'react-feather';
import { Instagram }      from 'react-feather';
import { Menu }           from 'react-feather';
import { MessageSquare }  from 'react-feather';
import { Smartphone }     from 'react-feather';
import { Users }          from 'react-feather';
import currency           from 'currency.js';
import MetricsPageSection from './metrics_page_section';
import React              from 'react';
import useLeadAggregates  from './use_lead_aggregates';


export default function LeadConversation({ dimension, dateRange }) {
  const { current: currentLead } = useLeadAggregates({ dimension, dateRange });

  const { newLeads, respondedTo, responseTime, sources, totalConverted, totalRevenue } = currentLead.leads;

  const percentResponded = Math.round((respondedTo / newLeads) * 100) || null;
  const percentConverted = Math.round((totalConverted / respondedTo) * 100) || null;

  const timeToFollowUp = getTimeToFollowUp({ responseTime });

  return (
    <MetricsPageSection className="lead-conversion">
      <h2>Lead Conversion</h2>
      <p className="top-text">
        Promptly follow up with as many leads as possible, to maximize your<br/>resulting revenue.
      </p>
      {!newLeads && (<p className="empty-metric">No data available at this time.</p>)}
      {!!newLeads && (
        <div className="flow-chart">
          <MetricBox {...{
            title:        newLeads,
            subtitle:     'New Leads',
            topClassName: 'green-1',
            items:        getSources({ newLeads, sources })
          }}/>
          <ConversionRate rate={percentResponded && `${percentResponded}%`}/>
          <MetricBox {...{
            title:        respondedTo,
            subtitle:     'You followed up',
            topClassName: 'green-2',
            items:        [
              {
                itemTitle: <><Clock/> Time to follow up</>,
                itemValue: timeToFollowUp
              }
            ]
          }}/>
          <ConversionRate rate={percentConverted && `${percentConverted}%`}/>
          <MetricBox {...{
            title:        totalConverted,
            subtitle:     'Booked a Service',
            topClassName: 'green-3',
            items:        getRevenueRounded(totalRevenue)
          }}/>
        </div>
      )}
      <p className="bottom-text">
        View our <a href="https://broadlyhelp.force.com/s/article/what-are-some-best-practices-on-how-to-respond-to-my-web-inquiries">best practices for converting leads to paying customers.</a>
      </p>
    </MetricsPageSection>
  );
}


function MetricBox({ title, subtitle, items, topClassName }) {
  return (
    <div className="metrics-box">
      <div className={`top ${topClassName}`}>
        <h2 className="title">{title}</h2>
        <p className="subtitle">{subtitle}</p>
      </div>
      {!!(items && items.length) && (
        <ul className="list">
          {items.map(MetricBoxListItem)}
        </ul>
      )}
    </div>
  );
}


function MetricBoxListItem({ itemTitle, itemValue }, key) {
  return (
    <li key={`itemTitle-${key}`}>
      <p className="item-title">{itemTitle}</p>
      <p className="item-value">{itemValue}</p>
    </li>
  );
}


function ConversionRate({ rate }) {
  return (
    <div className="conversion">
      {rate && (<div className="conversion-rate">{rate}</div>)}
    </div>
  );
}


function getSources({ newLeads, sources }) {
  return sources?.map(({ source, sourceTotal }) => {
    const total = parseFloat(sourceTotal / newLeads * 100).toFixed(1);
    return {
      itemTitle: getSourceTitle(source),
      itemValue: `${total}%`
    };
  });
}


function getSourceTitle(source) {
  switch (source) {
    case 'webchat':
      return <><MessageSquare/> Webchat</>;
    case 'website':
      return <><Menu/> Contact Form</>;
    case 'sms':
      return <><Smartphone/> SMS</>;
    case 'gmb':
      return <><ArrowUpRight/> Google</>;
    case 'facebook':
      return <><Facebook/> Facebook</>;
    case 'instagram':
      return <><Instagram/> Instagram</>;
    case 'yelp':
      return <><ArrowUpRight/> Yelp</>;
    default:
      return <><Disc/> Other</>;
  }
}


function getTimeToFollowUp({ responseTime }) {
  const minutesToRespond = Math.round(responseTime / 60);
  const hoursToRespond   = Math.floor(minutesToRespond / 60);
  const daysToRespond    = Math.floor(hoursToRespond / 24);

  if (minutesToRespond < 2)
    return '~1m';

  if (hoursToRespond < 1)
    return `${minutesToRespond}m`;

  if (daysToRespond < 1)
    return `${hoursToRespond}h`;
  else
    return `${daysToRespond}d`;
}


function getRevenueRounded(totalRevenue) {
  if (totalRevenue && totalRevenue !== '0.00') {
    const parsedRevenue = currency(totalRevenue).dollars();
    const total         = parseFloat(parsedRevenue / 1000).toFixed(1);
    return [
      {
        itemTitle: <><Users/> Revenue</>,
        itemValue: total >= 1 ? `$${total}k` : `$${Math.round(totalRevenue)}`
      }
    ];
  } else
    return [];
}

import * as DateFns            from 'date-fns';
import { ExternalLink }        from 'react-feather';
import { RotateCcw }           from 'react-feather';
import { useMutation }         from '@apollo/react-hooks';
import { useNotification }     from '../components/notification';
import { useState }            from 'react';
import Button                  from '../components/button';
import Confirm                 from '../components/confirm';
import Form                    from '../components/form';
import React                   from 'react';
import removeFromCache         from '../graphql/remove_from_cache';
import requestReviewMutation   from '../graphql/mutations/review_request.graphql';
import respondToReviewMutation from '../graphql/mutations/review_respond.graphql';
import TextArea                from '../components/textarea';
import useLocations            from '../util/use_locations';
import uuidv4                  from 'uuid/v4';


// Suggest the next action for the user. This component can render an alert,
// suggestion, and buttons.
//
// The alert draws attention to actions we strongly recommend the user takes.
// Specifically, responding to any 1-3 star review, and responding to detractors
// in a timely manner.
//
// The suggestions give the user more context about the action, such as why they
// should respond to 1-3 star review, and also respond to 4-5 star reviews.
//
// The button is selected based on what we think is the most appropriate action
// at the moment: respond in public on review site, or send a direct message to
// the contact.
export default function NextAction({ feedback, setReviewResponse }) {
  const { review } = feedback;

  if (review)
    return <ForReview {...{ feedback, setReviewResponse }}/>;
  else
    return <ForFeedback {...{ feedback }}/>;
}


function ForFeedback({ feedback }) {
  if (feedback.classifiedAs === 'DETRACTOR')
    return <ForNegativeFeedback {...{ feedback }}/>;
  else
    return null;
}


function ForNegativeFeedback({ feedback }) {
  const reclassifyAskDate               = getReclassifyAskDate({ feedback });
  const [ requestDate, setRequestDate ] = useState(reclassifyAskDate);

  function onReviewRequested() {
    setRequestDate(DateFns.format(new Date(), 'yyyy-MM-dd'));
  }

  if (requestDate)
    return <ReclassifyAsked {...{ requestDate }}/>;
  else
    return <RequestReview {...{ feedback, onReviewRequested }}/>;
}


function getReclassifyAskDate({ feedback }) {
  return (
    feedback &&
    feedback.reclassifyNext &&
    feedback.reclassifyNext.ask &&
    feedback.reclassifyNext.ask.presented
  );
}


function ReclassifyAsked({ requestDate }) {
  const parsedDate    = DateFns.parseISO(requestDate);
  const formattedDate = DateFns.format(parsedDate, 'PP');

  return (
    <section className="feedback-next-action pending">
      <div className="description">
        Customer was asked to review their experience again on {formattedDate}.
      </div>
    </section>
  );
}


function RequestReview({ feedback, onReviewRequested }) {
  const locations                                                     = useLocations();
  const reviewRequests                                                = getLocationReviewRequests({ locations, feedback });
  const limit                                                         = reviewRequests?.limit;
  const used                                                          = reviewRequests?.used;
  const customerID                                                    = getContactID(feedback);
  const classifiedID                                                  = getClassifiedID(feedback);
  const [ showMaxReviewRequestsModal, setShowMaxReviewRequestsModal ] = useState(false);
  const [ isConfirming, setIsConfirming ]                             = useState(false);
  const [ clientID, setClientID ]                                     = useState(() => uuidv4());
  const [ isLoading, setIsLoading ]                                   = useState(false);
  const { showSuccess, showError }                                    = useNotification();
  const [ requestReview ]                                             = useMutation(
    requestReviewMutation,
    {
      variables: {
        customerID,
        clientID,
        classifiedID
      },
      onError(error) {
        const graphQLError          = error.graphQLErrors?.[0];
        const limitReachedErrorCode = 'REVIEW_REQUESTS_LIMIT_REACHED_ERROR';
        const isReviewRequestError  = graphQLError?.extensions.code === limitReachedErrorCode;

        if (isReviewRequestError)
          showError(`The monthly limit of ${limit} review requests has been reached.`);
        else
          showError('Something went wrong. Refresh the page to try again.');
      },
      update(cache, { data }) {
        if (data) {
          showSuccess('Success! Review request sent.');
          removeFromCache(cache, 'feedback');
          onReviewRequested();
        }
      }
    }
  );

  async function sendReviewRequest() {
    closeConfirm();
    setIsLoading(true);
    try {
      await requestReview();
      setClientID(uuidv4());
    } finally {
      setIsLoading(false);
    }
  }

  function onClick() {
    const hasMaxReviewRequests = limit && used >= limit;

    if (hasMaxReviewRequests)
      setShowMaxReviewRequestsModal(true);
    else
      setIsConfirming(true);
  }

  function closeMaxReviewsRequestModal() {
    setShowMaxReviewRequestsModal(false);
  }

  function closeConfirm() {
    setIsConfirming(false);
  }

  function onUpgradeClick() {
    window.open('https://getbroadly.com/getstarted', '_blank');
  }

  return (
    <section className="feedback-next-action negative">
      <div className="description">
        Once you’ve addressed their concerns, you could ask them for another review.
      </div>
      <div className="action">
        <Button
          rounded
          disabled={isLoading}
          loading={isLoading}
          onClick={onClick}
          title="This button opens in a new window"
        >
          <RotateCcw/>
          Request a review
        </Button>
        <Confirm
          className="confirm-request-review"
          content="Are you sure you want to request a new review?"
          cancelButton="Cancel"
          confirmButton="Request Review"
          open={isConfirming}
          onCancel={closeConfirm}
          onConfirm={sendReviewRequest}
        />
        <Confirm
          className="max-review-requests"
          header="Monthly Limit Reached"
          content={`The monthly limit of ${limit} review requests has been reached.`}
          cancelButton="Cancel"
          confirmButton="Upgrade Now"
          open={showMaxReviewRequestsModal}
          onCancel={closeMaxReviewsRequestModal}
          onConfirm={onUpgradeClick}
        />
      </div>
    </section>
  );
}

function getLocationReviewRequests({ locations, feedback }) {
  const location = locations.get(feedback.location.id);
  return location?.limits?.reviewRequests;
}


function ForReview({ feedback, setReviewResponse }) {
  const { review } = feedback;

  if (review.rating < 4 && !review.response)
    return <RespondToNegativeReview {...{ feedback, setReviewResponse }}/>;

  if (review.rating >= 4 && !review.response)
    return <RespondToPositiveReview {...{ feedback, setReviewResponse }}/>;

  const { contact } = review;
  if (contact)
    return <ActionSendMessage feedback={feedback}/>;

  return null;
}


function RespondToNegativeReview({ feedback, setReviewResponse }) {
  const { review } = feedback;

  if (review.canRespond) {
    return (
      <section className="feedback-next-action negative can-respond">
        <div className="description">
          Responding to negative reviews shows future customers that you
          care, and willing to do right by them.
        </div>
        <div className="action">
          <ActionRespondToReview {...{ review, setReviewResponse }}/>
        </div>
      </section>
    );
  } else if (review.sourceURL) {
    return (
      <section className="feedback-next-action negative">
        <div className="description">
          Responding to negative reviews shows future customers that you
          care, and willing to do right by them.
        </div>
        <div className="action">
          <ActionRespondOnline review={review}/>
        </div>
      </section>
    );
  } else if (review.contact) {
    return (
      <section className="feedback-next-action negative">
        <div className="description">
          Reply to the customer so they feel heard, and maybe you can turn
          their experience around.
        </div>
        <div className="action">
          <ActionSendMessage primary={true} feedback={feedback}/>
        </div>
      </section>
    );
  } else
    return null;
}


function RespondToPositiveReview({ feedback, setReviewResponse }) {
  const { review } = feedback;

  if (review.canRespond) {
    return (
      <section className="feedback-next-action positive can-respond">
        <div className="description">
          Responding to positive reviews shows future customers that you value
          your business and care about their experience.
        </div>
        <div className="action">
          <ActionRespondToReview {...{ review, setReviewResponse }}/>
        </div>
      </section>
    );
  } else if (review.sourceURL) {
    return (
      <section className="feedback-next-action positive">
        <div className="description">
          Responding to positive reviews shows future customers that you value
          your business and care about their experience.
        </div>
        <div className="action">
          <ActionRespondOnline review={review}/>
        </div>
      </section>
    );
  } else
    return null;
}


function getContactID(feedback) {
  const { contact } = feedback;
  if (contact)
    return contact.id;
  else
    return null;
}


function getClassifiedID(feedback) {
  const { classified } = feedback;
  if (classified)
    return classified.id;
  else
    return null;
}


function ActionRespondOnline({ review }) {
  return (
    <Button
      rounded
      onClick={() => window.open(review.sourceURL)}
      title="This button opens in a new window"
    >
      <ExternalLink/>
      Respond Online
    </Button>
  );
}


function ActionRespondToReview({ review, setReviewResponse }) {
  const [ responseBody, setResponseBody ] = useState('');
  const [ isLoading, setIsLoading ]       = useState(false);
  const { showError }                     = useNotification();
  const [ respondToReview ]               = useMutation(
    respondToReviewMutation,
    {
      variables: {
        reviewID:           review.id,
        reviewResponseBody: responseBody
      },
      onError() {
        showError('Something went wrong. Refresh the page to try again.');
      },
      update(cache, { data }) {
        if (data) {
          setReviewResponse({
            name:       'Owner',
            body:       responseBody,
            published:  new Date().toISOString(),
            pending:    true,
            __typename: 'ReviewResponse'
          });
        }
      }
    }
  );

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    if (responseBody?.trim().length)
      await respondToReview();

    setIsLoading(false);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <TextArea rows={2} onChange={e => setResponseBody(e.target.value)}/>
      <Button type="submit" disabled={isLoading} loading={isLoading}>
        Respond
      </Button>
    </Form>
  );
}


// eslint-disable-next-line no-unused-vars
function ActionSendMessage({ feedback, primary = false }) {
  return null;

  //
  //
  // function onClick() {
  //   const { contact, location } = feedback;
  //   const url = routes.conversation.url({ contact, location });
  //   window.open(url);
  // }
  //
  // return (
  // <Button primary={primary} onClick={onClick}>Send Message</Button>
  // );
  //
  //
}

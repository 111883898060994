import './loading_shimmer.less';
import React from 'react';


export default function LoadingShimmer() {
  return (
    <div className="loading-shimmer">
      <div className="dots">
        <div className="dot"/>
        <div className="dot"/>
        <div className="dot"/>
      </div>
    </div>
  );
}

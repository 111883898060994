// This is the main application component. Everything runs under this component,
// and it provides common services like error handling, authentication, and
// GraphQL client.

import './app.less';

import { ApolloProvider }  from '@apollo/react-hooks';
import { Authenticate }    from './authenticate';
import { Notification }    from '../components/notification';
import { useAccessToken }  from './authenticate';
import { WithCurrentUser } from '../util/use_current_user';
import ErrorBoundary       from './error_boundary';
import newApolloClient     from './new_apollo_client';
import PageLayout          from './page_layout';
import React               from 'react';
import Router              from './router';


function App() {
  // Notification rendered first — ErrorBoundary and other components can use it
  // to show an error message at the top of the screen.
  //
  // ApolloProvider loaded before BrowserRouter, so we can use the Apollo cache
  // to fetch already loaded data while navigating betweeen pages, or when
  // changing filters on current page.
  return (
    <Notification>
      <ErrorBoundary>
        <Authenticate>
          <AuthenticatedView/>
        </Authenticate>
      </ErrorBoundary>
    </Notification>
  );
}


function AuthenticatedView() {
  const { accessToken, reauthenticate, impersonate } = useAccessToken();

  return (
    <ApolloProvider client={newApolloClient({ accessToken, reauthenticate, impersonate })}>
      <WithCurrentUser>
        <Router>
          <PageLayout/>
        </Router>
      </WithCurrentUser>
    </ApolloProvider>
  );
}


export default App;

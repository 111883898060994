import './feedback.less';

import { useFeedback }              from './use_feedback';
import { useFeedbackFacets }        from './use_feedback_facets';
import { useLogPageViewEvent }      from '../util/use_log_page_view_event';
import { useNotification }          from '../components/notification';
import { Users }                    from 'react-feather';
import { useSearchParameterState }  from '../util/use_search_params';
import Block                        from '../components/block';
import Button                       from '../components/button';
import Facets                       from './facets';
import FeedbackList                 from './feedback_list';
import Filters                      from './filters';
import getFilterDate                from '../util/filter_date';
import LoadingShimmer               from '../components/loading_shimmer';
import React, { useMemo, useState } from 'react';
import rollbar                      from '../rollbar';
import TeamMember                   from '../facets/team_member_without_cf';
import TextAnalytics                from './text_analytics';
import useDateRange                 from '../hooks/use_date_range';
import useSelectedLocations         from '../util/use_selected_locations';


export default function FeedbackPage() {
  useLogPageViewEvent('DashboardReviewsViewed');

  const { showError }               = useNotification();
  const { minDate, maxDate }        = getFilterDate();
  const [ dateRange, setDateRange ] = useDateRange({ maxDate });
  const [ after, setAfter ]         = useState(null);
  const [ error, setError ]         = useState(null);
  const [ endCursor, setEndCursor ] = useState(null);
  const minimumOfRecordsToShow      = 100;
  const filters                     = useFilters();

  // Make sure we are passing exactly the same filters to feedback and
  // feedbackFacets queries.
  const feedbackFilters = {
    ...filters,
    ...dateRange
  };

  const { feedback, isLoading } = useFeedback({
    ...feedbackFilters,
    setEndCursor,
    setError,
    first: minimumOfRecordsToShow,
    setAfter,
    after
  });

  const { feedbackFacets } = useFeedbackFacets({
    ...feedbackFilters,
    setError
  });

  const keywordsFacet   = feedbackFacets?.keywords;
  const sentimentFacet  = feedbackFacets?.sentiment;
  const visibilityFacet = feedbackFacets?.visibility;
  const responseFacet   = feedbackFacets?.response;

  if (error) {
    rollbar.error(error);
    showError('Something went wrong. Refresh the page to try again.');
  }

  return (
    <div className="feedback-page">
      <Block>
        <Facets {...{ minDate, maxDate, dateRange, setDateRange, isLoading, keywordsFacet }}/>
      </Block>
      <Block className="feedback-content">
        <Filters {...{ sentimentFacet, visibilityFacet, responseFacet }}/>
        <FeedbackColumns {...{ feedback, keywordsFacet, isLoading, endCursor, handleLoadMoreClick: () => setAfter(endCursor) }}/>
      </Block>
    </div>
  );
}

function FeedbackColumns({ feedback, keywordsFacet, isLoading, endCursor, handleLoadMoreClick }) {
  return (
    <div className="feedback-columns">
      <div className="feedback-column feedback-list-column">
        {isLoading && <LoadingShimmer/>}
        {!isLoading && <FeedbackList {...{ feedback, isLoading }}/>}
        {!isLoading && endCursor && <Button className="loadMore" onClick={handleLoadMoreClick}>Load More</Button>}
      </div>
      <div className="feedback-column filters-column">
        <TeamFilter/>
        <TextAnalytics keywords={keywordsFacet}/>
      </div>
    </div>
  );
}

function TeamFilter() {
  return (
    <div className="team-filter">
      <h1 className="filter-header">
        <Users/>
        Team Member
      </h1>
      <TeamMember/>
    </div>
  );
}

function useFilters() {
  const [ sentiment ]      = useSearchParameterState('sentiment');
  const [ visibility ]     = useSearchParameterState('visibility');
  const [ responseOption ] = useSearchParameterState('response');
  const [ searchText ]     = useSearchParameterState('search');
  const [ keyword ]        = useSearchParameterState('keyword');
  const [ providers ]      = useSearchParameterState('member');
  const providerIDs        = useMemo(() => getProviderIDs(providers), [ providers ]);
  const selectedLocations  = useSelectedLocations();
  const locationIDs        = useMemo(() => {
    if (selectedLocations)
      return [ ...selectedLocations.keys() ];
    else
      return [];
  }, [ selectedLocations ]);

  return {
    locationIDs,
    providerIDs,
    sentiment,
    visibility,
    responseOption,
    searchText,
    keyword
  };
}

function getProviderIDs(query = '') {
  return query
    .split(',')
    .filter(Boolean)
    .map(function(value) {
      const hasLegacyFormat = !value.includes(':');
      if (hasLegacyFormat) {
        // In case the user tries to use an old query string format we crash the
        // APP. The user will refresh and it will start using the new format.
        throw new Error('Legacy provider format is not supported');
      }

      const [ type, id ] = value.split(':');
      return {
        id,
        type: type.toUpperCase()
      };
    });
}

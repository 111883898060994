export default function FeatureAvailable({ locations = [], feature, children, notAvailable = null }) {
  const isAvailable = isFeatureAvailable({ locations, feature });

  if (isAvailable)
    return children;
  else
    return notAvailable;
}

export function isFeatureAvailable({ locations = [], feature }) {
  if (!feature)
    return true;

  const locationsArray = locations.values && Array.from(locations.values()) || locations;

  if (!locations.length === 0)
    return false;

  const featuresEnabled = new Set(
    locationsArray.reduce((acc, { features }) => [ ...acc, ...getFeaturesEnabled(features) ], [])
  );

  if (featuresEnabled.has(feature))
    return true;
  else
    return false;
}

function getFeaturesEnabled(features) {
  return features && Object.entries(features)
    .reduce((acc, [ key, value ]) => {
      if (value)
        acc.push(key);

      return acc;
    }, []) || [];
}

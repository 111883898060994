import { useContext }      from 'react';
import { useCurrentUser }  from './use_current_user';
import { useMemo }         from 'react';
import { useOrganization } from './use_organizations';
import React               from 'react';


const Context = React.createContext(null);


// Returns all the locations user has access to in the current organization.
// Returns a Map, with location ID as the key.
export default function useLocations() {
  const organization = useOrganization();
  const location     = useContext(Context);

  const locations = useMemo(
    () => {
      if (organization)
        return new Map(organization.locations.map(l => [ l.id, l ]));
      else
        return new Map([ [ location.id, location ] ]);
    },
    [ organization, location ]
  );

  return locations;
}


export function useAllLocations() {
  const { locations } = useCurrentUser();
  const locationsMap  = useMemo(() => new Map(locations.map(location => [ location.id, location ])), [ locations ]);
  return locationsMap;
}


export function useLocation() {
  return useContext(Context);
}

// Gets current user from server and then renders child component.
// The current user is available in the context, use useCurrentUser to read it.
export function WithLocation({ children, location }) {
  return (
    <Context.Provider value={location}>
      {children}
    </Context.Provider>
  );
}

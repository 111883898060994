// These are all the application routes in the order in which they should show
// in the UI.
//
// Routes with :organization will show in the navigation bar left -> right.
//
// Routes without :organization will show in the user menu top -> bottom.

import { generatePath }           from 'react-router';
import { Redirect }               from 'react-router-dom';
import { useRouteMatch }          from 'react-router';
import AccountPage                from '../account';
import BrandsPage                 from '../brands';
import EditAccount                from '../account/edit_account';
import FeedbackPage               from '../feedback';
import LocationsPage              from '../locations';
import MetricsPage                from '../metrics';
import NewAccount                 from '../account/new_account';
import React                      from 'react';
import RedirectToOrganizationPage from '../redirect_to_organization';
import ScratchPage                from '../scratchpad';
import TeamPage                   from '../team';

import { Activity }               from 'react-feather';
import { MapPin }                 from 'react-feather';
import { MessageCircle }          from 'react-feather';
import { Shield }                 from 'react-feather';
import { Sliders }                from 'react-feather';
import { Users }                  from 'react-feather';


// Routes by name. Each route has path, title, component, and
// url method to generate new URL from supplied parameters.
//
// For example:
//   routes.metrics.url({ organizationID })
export const routes = {

  brands: addURLMethod({
    path:      '/brands',
    title:     'Brands',
    component: BrandsPage,
    icon:      Shield
  }),

  metrics: addURLMethod({
    path:      '/:organizationID/metrics',
    title:     'Metrics',
    component: MetricsPage,
    icon:      Activity
  }),

  feedback: addURLMethod({
    path:      '/:organizationID/feedback',
    title:     'Reviews',
    component: FeedbackPage,
    icon:      MessageCircle
  }),

  conversation: {
    path: 'https://app.broadly.com/:locationID/customers/:contactID',
    url({ contact, location }) {
      const locationID = location.id;
      const contactID  = contact.id;
      return generatePath(this.path, { locationID, contactID });
    }
  },

  locations: addURLMethod({
    path:      '/:organizationID/locations',
    title:     'Locations',
    component: LocationsPage,
    icon:      MapPin
  }),

  team: addURLMethod({
    path:      '/:organizationID/team',
    title:     'Team',
    component: TeamPage,
    icon:      Users
  }),

  account: addURLMethod({
    path:      '/account',
    title:     'Account',
    component: AccountPage,
    icon:      Sliders,
    className: 'account'
  }),

  newAccount: addURLMethod({
    path:      '/account/new',
    title:     'New Account',
    component: NewAccount
  }),

  editAccount: addURLMethod({
    path:      '/account/:userID',
    title:     'Edit Account',
    component: EditAccount
  }),

  // This is a page I'm using for UI experimentation and round-tripping design
  // with Kumail. We'll remove this before we ship. -p
  scratchpad: addURLMethod({
    path:      '/scratchpad',
    title:     'Scratch Pad',
    component: ScratchPage
  }),

  redirect: {
    path:      '/redirect',
    component: RedirectToOrganizationPage
  },

  impersonate: {
    path:      '/impersonate/*',
    title:     'Impersonate',
    component: function RedirectToRoot() {
      return <Redirect to='/' push/>;
    }
  }

};


// This is what we use in the URL when not associated with any
// organization and we don't have an organization ID.
export const noOrgID = '_';


// Returns the route for the current URL.
export function useCurrentRoute() {
  // We can use useRouteMatch({ path: route.path }) to find the current route.
  // But Lint will warn us about calling useRouteMatch in a loop, and we don't
  // want to upset Lint, so instead we call useRouteMatch with an array of paths
  // (this works!), and then find out which specific route it refers to.
  const allRoutes = Object.values(routes);
  const allPaths  = allRoutes.map(({ path }) => path);
  const match     = useRouteMatch({ path: allPaths });
  const route     = match && allRoutes.find(({ path }) => path === match.path);
  return route;
}


function addURLMethod({ path, title, component, icon, className = '' }) {
  function url({ queryString = '', ...params }) {
    return generatePath(path, params).concat(queryString ? `?${queryString}` : '');
  }

  return { path, title, component, url, icon, className };
}

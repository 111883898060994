// Returns the location name suitable for display
// next to the organization name.
//
// "Window Genie of Atlanta" → "Atlanta"
//
export function getLocationDisplayName(location) {
  const { organization } = location;
  const { name }         = location;

  if (organization) {
    const regex = new RegExp(`^${organization.name} (of the |of )?`, 'i');
    return name.replace(regex, '');
  } else
    return name;
}

const servicesAvailable = [ 'google', 'facebook', 'nextdoor', 'tripAdvisor', 'yelp' ];

export function getReviewServices(locations) {
  return locations.reduce((acc, location) => {
    if (servicesAvailable.length === acc.length)
      return acc;

    servicesAvailable.forEach(service => {
      if (location.reviews[service]?.reviewsAdded > 0 && !acc.includes(service))
        acc.push(service);
    });

    return acc;
  }, []);
}

import './text_analytics.less';

import { capitalCase }              from '../util/change_case';
import { useSearchParametersState } from '../util/use_search_params';
import classNames                   from 'classnames';
import LoadingShimmer               from '../components/loading_shimmer';
import React, { useMemo, useState } from 'react';
import useIsNPS                     from '../util/use_is_nps';

import { BarChart2 }                from 'react-feather';


export default function TextAnalytics({ keywords }) {
  const [ sortBy, setSortBy ] = useState('occurrences');

  const isLoading  = !keywords;
  const hasResults = (keywords?.length > 0);

  const sortedTerms = useMemo(() => keywords?.sort((a, b) => {
    if (sortBy === 'occurrences')
      return b.sentiment.total - a.sentiment.total;
    else
      return a.term.localeCompare(b.term);
  }), [ sortBy, keywords ]);

  return (
    <div className="text-analytics">
      <h1 className="terms-header">
        <BarChart2/>
        Keywords
      </h1>

      <div className="terms-box">
        {isLoading && <LoadingShimmer/>}
        {!isLoading && hasResults && <TermsList termItems={sortedTerms} setSortBy={setSortBy}/>}
        {!isLoading && !hasResults && <NoResults/>}
      </div>
    </div>
  );
}

function NoResults() {
  return (
    <div className="no-results">
      No keywords available for the current set of reviews
    </div>
  );
}

function TermsList({ termItems, setSortBy }) {
  const [ query, setQuery ] = useSearchParametersState();
  const isNPS               = useIsNPS();

  return (
    <ul className="terms-list">
      <li className="terms-legend">
        <button className="filter-by" onClick={() => setSortBy('term')}>Term</button>
        <button className="filter-by" onClick={() => setSortBy('occurrences')}>Occurrences</button>
      </li>
      {termItems.map(item => (
        <TermItem key={item.term} {...{ query, setQuery, keywordSentiment: item.sentiment, isNPS, ...item }}/>
      ))}
    </ul>
  );
}

function TermItem({ term, keywordSentiment, query, setQuery, isNPS }) {
  const isActiveKeyword = query.get('keyword') === term;
  const { positive }    = keywordSentiment;
  const { passive }     = keywordSentiment;
  const { negative }    = keywordSentiment;
  const { total }       = keywordSentiment;
  const className       = classNames('term-item', { active: isActiveKeyword });
  const termClassName   = classNames('term', { active: isActiveKeyword });

  function toggleKeywordFilter() {
    if (isActiveKeyword) {
      setQuery(previousQuery => {
        previousQuery.delete('keyword');
        return previousQuery;
      });
    } else
      setQuery(previousQuery => previousQuery.set('keyword', term));
  }

  return (
    <li className={className} onClick={toggleKeywordFilter}>
      <div className="term-and-total">
        <div className={termClassName}>
          {capitalCase(term)}
        </div>

        <div className="total">
          {total}
        </div>
      </div>
      <div className="percent-chart">
        <PercentBar {...{ type: 'PROMOTER', isNPS, value: positive, total, query, setQuery, term }}/>
        <PercentBar {...{ type: 'PASSIVE', isNPS, value: passive, total, query, setQuery, term }}/>
        <PercentBar {...{ type: 'DETRACTOR', isNPS, value: negative, total, query, setQuery, term }}/>
      </div>
    </li>
  );
}

function PercentBar({ type, isNPS, value, total, term, query, setQuery }) {
  if (!value)
    return null;

  function toggleSentimentFilter(e) {
    // Prevent deselecting keyword
    e.preventDefault();
    e.stopPropagation();

    if (query.get('sentiment') === type && query.get('keyword') === term) {
      setQuery(previousQuery => {
        previousQuery.delete('sentiment');
        return previousQuery;
      });
    } else {
      setQuery(previousQuery => {
        previousQuery.set('sentiment', type);
        previousQuery.set('keyword', term);
        return previousQuery;
      });
    }
  }

  const className = classNames('percent-bar', type.toLowerCase());
  const style     = getPercentageStyle(value / total);
  const tooltip   = getBarTooltip({ value, type, isNPS });

  return (
    <div className={className} style={style} title={tooltip} onClick={toggleSentimentFilter}/>
  );
}

function getPercentageStyle(num) {
  const percent = num * 100;
  return {
    width: `${percent}%`
  };
}

function getSentimentDisplayName({ type, isNPS }) {
  switch (type) {
    case 'promoter':
      return isNPS ? 'promoters' : 'positives';
    case 'passive':
      return 'passives';
    case 'detractor':
      return isNPS ? 'detractors' : 'negatives';
    default:
      return '';
  }
}

function getBarTooltip({ value, type, isNPS }) {
  const plural      = getSentimentDisplayName({ type: type.toLowerCase(), isNPS });
  const singular    = plural.substring(0, plural.length - 1);
  const noun        = value === 1 ? singular : plural;
  const timeOrTimes = value === 1 ? 'time' : 'times';
  return `This term was mentioned ${value} ${timeOrTimes} by ${noun}`;
}

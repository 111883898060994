import { Link }            from 'react-router-dom';
import { noOrgID }         from '../app/routes';
import { routes }          from '../app/routes';
import { useBrand }        from '../util/use_brands';
import { useBrands }       from '../util/use_brands';
import { useCurrentRoute } from '../app/routes';
import Dropdown            from './dropdown';
import React               from 'react';


export default function BrandSelector() {
  const brands  = useBrands();
  const brand   = useBrand();
  const current = brand?.name || 'All Brands';

  return (
    <Dropdown text={current} className="brand-selector">
      <Dropdown.Menu>
        <BrandItems brands={brands}/>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function BrandItems({ brands }) {
  return brands.filter(({ name }) => name)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ id, name, isOrganization }) => {
      if (isOrganization)
        return <LinkToOrganization key={id} name={name} organizationID={id}/>;
      else
        return <LinkToLocation key={id} name={name} locationID={id}/>;
    });
}

function LinkToOrganization({ organizationID, name }) {
  const route = useCurrentRoute() || routes.metrics;
  return (
    <Dropdown.Item
      as={Link}
      to={route.url({ organizationID })}
      text={name}
      title={name}
    />
  );
}


function LinkToLocation({ locationID, name }) {
  const route       = useCurrentRoute() || routes.metrics;
  const queryString = `locations=${locationID}`;

  return (
    <Dropdown.Item
      as={Link}
      to={route.url({ organizationID: noOrgID, queryString })}
      text={name}
      title={name}
    />
  );
}

// Renders the web page layout:
// - Navigation bar
// - Current page title
// - Current page component

import { sendScreenView }  from '../util/ga';
import { useEffect }       from 'react';
import { useLocation }     from 'react-router';
import { useOrganization } from '../util/use_organizations';
import Footer              from './footer';
import FullscreenSpinner   from '../components/fullscreen_spinner';
import Navigation          from './navigation';
import React               from 'react';


export default function PageLayout({ title, component }) {
  const organization = useOrganization();
  useGoogleAnalytics({ title, organization });
  useDocumentTitle({ title, organization });

  return (
    <>
      <Navigation/>
      <main>
        <React.Suspense fallback={<FullscreenSpinner/>}>
          {React.createElement(component)}
        </React.Suspense>
      </main>
      <Footer/>
    </>
  );
}

// - Updates document title.
function useDocumentTitle({ title, organization }) {
  useEffect(function() {
    document.title = getSubtitle({ title, organization });
  }, [ title, organization ]);
}

function getSubtitle({ title, organization }) {
  if (organization) {
    if (organization.name)
      return `${title} - ${organization.name}`;

    const { locations } = organization;
    if (locations.length === 1) {
      const location = locations[0];
      return `${title} - ${location.name}`;
    }
  }

  return title;
}


// Send event to GA when use navigates to a different page.
function useGoogleAnalytics({ title, organization }) {
  const { pathname } = useLocation();

  useEffect(function() {
    sendScreenView({
      screenName:   title,
      organization: organization?.name
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ pathname ]);
}

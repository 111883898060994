import * as d3                    from 'd3';
import { alphaNumericCompare }    from '../util/sort';
import { Frown }                  from 'react-feather';
import { getLocationDisplayName } from '../util/locations';
import { Meh }                    from 'react-feather';
import { Smile }                  from 'react-feather';
import { Star }                   from 'react-feather';
import { ThumbsDown }             from 'react-feather';
import { ThumbsUp }               from 'react-feather';
import { useState }               from 'react';
import Facebook                   from '../locations/facebook_logo.svg';
import get                        from 'lodash.get';
import getDisplayFeatures         from '../util/display_features';
import Google                     from '../locations/google_logo.svg';
import Nextdoor                   from '../locations/nextdoor_logo.svg';
import React                      from 'react';
import SortableTable              from './sortable_table';
import Yelp                       from '../locations/yelp_logo.svg';


export default function LocationsOrBrandsTable({ type, data }) {
  const [ column, setColumn ]       = useState('name');
  const [ direction, setDirection ] = useState('ascending');
  const Cell                        = SortableTable.Cell;
  const sortedData                  = getSortedData({ data, column, direction });
  const isBrand                     = type === 'brands';
  const features                    = getDisplayFeatures(data);
  const columnsInUse                = getColumnsInUse(features, type);
  const firstColumnLabel            = isBrand ? 'Brands' : 'Locations';

  function tableHeader(columnName, displayName, options = { className: false }) {
    const { className } = options;

    return (
      <th
        onClick={handleSort(columnName)}
        active={column === columnName}
        {...(className && { className })}
      >
        {displayName}
      </th>
    );
  }


  function handleSort(newColumn) {
    return () => {
      if (newColumn === column)
        setDirection(direction === 'ascending' ? 'descending' : 'ascending');
      else
        setColumn(newColumn);
    };
  }

  return (
    <SortableTable>
      <SortableTable.Head {...{ direction }}>
        {tableHeader('name', firstColumnLabel)}
        {columnsInUse.has('locations') && (
          tableHeader('locations', <>Locations</>)
        )}
        {columnsInUse.has('leads.newLeads') && tableHeader('leads.newLeads', <>Leads</>)}
        {tableHeader('feedbackResponse.asked', <>Customers<br/>Asked</>)}
        {tableHeader('feedbackResponse.responseRate', <>Resp.<br/>Rate</>)}
        {tableHeader('classifiedAs.promoters', features.nps ? <Smile/> : <ThumbsUp/>, { className: 'borderless' })}
        {(columnsInUse.has('classifiedAs.passives') || columnsInUse.has('classifiedAs.npsScore')) && (
          tableHeader('classifiedAs.passives', <Meh/>, { className: 'borderless' })
        )}
        {tableHeader('classifiedAs.detractors', features.nps ? <Frown/> : <ThumbsDown/>, { className: 'borderless' })}
        {columnsInUse.has('classifiedAs.npsScore') && (
          tableHeader('classifiedAs.npsScore', <>NPS<br/>Score</>)
        )}
        {columnsInUse.has('classifiedAs.satisfactionScore') && (
          tableHeader('classifiedAs.satisfactionScore', <>Satisfaction<br/>Score</>)
        )}
        {columnsInUse.has('reviews.google.rating') && columnsInUse.has('reviews.google.reviewsAdded') && (
          tableHeader('reviews.google.rating', <></>, { className: 'brand-icon google' })
        )}
        {columnsInUse.has('reviews.google.reviewsAdded') && (
          tableHeader('reviews.google.reviewsAdded', <Google/>, { className: `brand-icon google ${columnsInUse.has('reviews.google.rating') ? 'icon-span-col' : ''}` })
        )}
        {columnsInUse.has('reviews.facebook.rating') && (
          tableHeader('reviews.facebook.rating', <></>, { className: 'brand-icon facebook' })
        )}
        {columnsInUse.has('reviews.facebook.reviewsAdded') && (
          tableHeader('reviews.facebook.reviewsAdded', <Facebook/>, { className: `brand-icon facebook ${columnsInUse.has('reviews.facebook.rating') ? 'icon-span-col' : ''}` })
        )}
        {columnsInUse.has('reviews.yelp.rating') && (
          tableHeader('reviews.yelp.rating', <></>, { className: 'brand-icon yelp' })
        )}
        {columnsInUse.has('reviews.yelp.reviewsAdded') && (
          tableHeader('reviews.yelp.reviewsAdded', <Yelp/>, { className: `brand-icon yelp ${columnsInUse.has('reviews.yelp.rating') ? 'icon-span-col' : ''}` })
        )}
        {columnsInUse.has('reviews.nextdoor.reviewsAdded') && (
          tableHeader('reviews.nextdoor.reviewsAdded', <Nextdoor/>, { className: 'brand-icon nextdoor' })
        )}
      </SortableTable.Head>
      <SortableTable.Body>
        {sortedData.map(location => (
          <tr key={location.id}>
            <Cell>{getLocationDisplayName(location)}</Cell>
            {columnsInUse.has('locations') && (
              <Cell>{location.totalLocationCount ?? 1}</Cell>
            )}
            {columnsInUse.has('leads.newLeads') && (
              <Cell>{location.leads?.newLeads?.toLocaleString()}</Cell>
            )}
            <Cell>{location.feedbackResponse?.asked?.toLocaleString()}</Cell>
            <Cell value={location.feedbackResponse?.responseRate}>{location.feedbackResponse && d3.format('.0%')(location.feedbackResponse.responseRate)}</Cell>
            <Cell>{location.classifiedAs?.promoters?.toLocaleString()}</Cell>
            {columnsInUse.has('classifiedAs.passives') && (
              <Cell>{location.classifiedAs?.passives?.toLocaleString()}</Cell>
            )}
            <Cell>{location.classifiedAs?.detractors?.toLocaleString()}</Cell>
            {columnsInUse.has('classifiedAs.npsScore') && (
              <Cell>{location.classifiedAs?.npsScore?.toLocaleString()}</Cell>
            )}
            {columnsInUse.has('classifiedAs.satisfactionScore') && (
              <Cell>
                {location.classifiedAs?.satisfactionScore && `${location.classifiedAs.satisfactionScore}%`}
              </Cell>
            )}
            {columnsInUse.has('reviews.google.rating') && (
              <Cell className="rating" value={location.reviews?.google?.rating}>
                <Rating review={location.reviews?.google}/>
              </Cell>
            )}
            {columnsInUse.has('reviews.google.reviewsAdded') && (
              <Cell>
                <ReviewsAdded review={location.reviews?.google}/>
              </Cell>
            )}
            {columnsInUse.has('reviews.facebook.rating') && (
              <Cell className="rating" value={location.reviews?.facebook?.reviewsAdded}>
                <Rating review={location.reviews?.facebook}/>
              </Cell>
            )}
            {columnsInUse.has('reviews.facebook.reviewsAdded') && (
              <Cell>
                <ReviewsAdded review={location.reviews?.facebook}/>
              </Cell>
            )}
            {columnsInUse.has('reviews.yelp.rating') && (
              <Cell className="rating" value={location.reviews?.yelp?.rating}>
                <Rating review={location.reviews?.yelp}/>
              </Cell>
            )}
            {columnsInUse.has('reviews.yelp.reviewsAdded') && (
              <Cell>
                <ReviewsAdded review={location.reviews?.yelp}/>
              </Cell>
            )}
            {columnsInUse.has('reviews.nextdoor.reviewsAdded') && (
              <Cell>
                <ReviewsAdded review={location.reviews?.nextdoor}/>
              </Cell>
            )}
          </tr>
        ))}
      </SortableTable.Body>
    </SortableTable>
  );
}


function getSortedData({ data, column, direction }) {
  // Don't mutate data.
  return data.slice().sort((a, b) => {
    const rowA      = get(a, column);
    const rowB      = get(b, column);
    const ascending = direction === 'ascending';

    return alphaNumericCompare(rowA, rowB, ascending);
  });
}


function Rating({ review }) {
  const rating = review?.rating;

  if (!rating)
    return null;

  return (
    <>
      <Star/>
      {parseFloat(rating).toFixed(1)}
    </>
  );
}


function ReviewsAdded({ review }) {
  const reviewsAdded = review?.reviewsAdded;

  if (reviewsAdded)
    return reviewsAdded.toLocaleString();
  else
    return null;
}

function getColumnsInUse(features, type) {
  const isBrand    = type === 'brands';
  const isLocation = !isBrand;
  const columns    = new Set();

  if (isBrand)
    columns.add('locations');

  if (features.webchat)
    columns.add('leads.newLeads');

  if (features.yelp) {
    columns.add('reviews.yelp.reviewsAdded');
    if (isLocation)
      columns.add('reviews.yelp.rating');
  }

  if (features.google) {
    columns.add('reviews.google.reviewsAdded');
    if (isLocation)
      columns.add('reviews.google.rating');
  }

  if (features.facebook) {
    columns.add('reviews.facebook.reviewsAdded');
    if (isLocation)
      columns.add('reviews.facebook.rating');
  }

  if (features.tripAdvisor) {
    columns.add('reviews.tripAdvisor.reviewsAdded');
    if (isLocation)
      columns.add('reviews.tripAdvisor.rating');
  }

  if (features.nextdoor)
    columns.add('reviews.nextdoor.reviewsAdded');

  if (features.nps) {
    columns.add('classifiedAs.passives');
    columns.add('classifiedAs.npsScore');
  }

  if (features.satisfactionScore)
    columns.add('classifiedAs.satisfactionScore');

  return columns;
}

import { useContext } from 'react';
import { useState }   from 'react';
import Message        from './message';
import React          from 'react';


const Context = React.createContext();


export function Notification({ children }) {
  const [ message, setMessage ] = useState(null);

  return (
    <Context.Provider value={setMessage}>
      {message && (
        <Message className="notification" onDismiss={() => setMessage(null)} {...message}/>
      )}
      {children}
    </Context.Provider>
  );
}


export function useNotification() {
  const setMessage = useContext(Context);
  return {
    showError(message) {
      setMessage({ content: message, error: true });
    },
    showSuccess(message) {
      setMessage({ content: message, success: true });
    }
  };
}

import { useMemo }                 from 'react';
import { useSearchParameterState } from '../util/use_search_params';
import MultiselectDropdown         from '../components/multiselect_dropdown';
import React                       from 'react';
import useProviders                from '../feedback/use_providers';


export default function TeamMember() {
  const [ fromQuery = '', updateQuery ] = useSearchParameterState('member');
  const selected                        = useMemo(() => new Set(fromQuery.split(',').filter(Boolean)), [ fromQuery ]);

  const providers  = useProviders();
  const optionsMap = useMemo(() => providers
    .map(provider => {
      const { user } = provider;
      // User will have different provider IDs in each business. We want to show
      // them only once, so use user ID if available.
      const isUser = !!user;
      const model  = isUser ? 'user' : 'provider';
      const id     = isUser ? user.id : provider.id;
      const key    = `${model}:${id}`;
      const text   = isUser ? user.name : provider.name;

      return {
        key,
        value:  key,
        active: selected.has(key),
        text
      };
    })
    .reduce((accum, item) => accum.set(item.key, item), new Map()), [ providers, selected ]);

  const options = useMemo(() => Array.from(optionsMap.values())
    .sort((a, b) => a.text.localeCompare(b.text)), [ optionsMap ]);

  function onClick(e, { value: clickedID }) {
    if (selected.has(clickedID)) {
      e.stopPropagation();
      updateQuery([ ...selected ].filter(id => id !== clickedID));
    } else {
      if (selected.size !== 0)
        e.stopPropagation();
      updateQuery([ ...selected, clickedID ]);
    }
  }

  function getPlaceholder() {
    const { size } = selected;
    if (size === 0)
      return 'All Team Members';
    else if (size === 1) {
      const selectedOption = options.find(option => option.active);
      if (selectedOption)
        return selectedOption.text;
      else
        return 'All Team Members';
    } else
      return `${size} Team Members`;
  }

  function onClearAll() {
    updateQuery([]);
  }

  const placeholder = getPlaceholder();

  return (
    <MultiselectDropdown
      className="team-member-selector"
      options={options}
      onClick={onClick}
      placeholder={placeholder}
      onClearAll={onClearAll}
      maxSizeOptions={50}
    />
  );
}

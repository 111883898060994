import './highlight_terms.less';
import posTagger from 'wink-pos-tagger';
import React     from 'react';


const tagger = posTagger();

export default function HighlightTerms({ body, highlightTerms }) {
  if (!body)
    return null;

  if (!highlightTerms)
    return body;

  const highlightText = new Set(
    tagger.tagSentence(body)
      .filter(({ normal, lemma }) => highlightTerms.has(lemma || normal))
      .map(({ value }) => value)
  );

  const markers = [];
  body.replace(/\b\w+\b/g, (match, offset) => {
    if (highlightText.has(match))
      markers.push([ offset, match.length ]);
  });
  markers.push([ body.length, 0 ]);

  let index = 0;
  return markers
    .map(([ offset, length ]) => {
      const prefix   = body.slice(index, offset);
      const highlght = body.slice(offset, offset + length);
      index          = offset + length;
      return (
        <span key={offset}>
          {prefix}
          {highlght && (
            <span className="highlighted-term">{highlght}</span>
          )}
        </span>
      );
    });
}

import './fullscreen_message.less';

import Block       from '../components/block';
import BroadlyLogo from '../app/broadly-full.svg';
import React       from 'react';


export default function FullscreenMessage({ children }) {
  return (
    <Block>
      <div className="fullscreen-message">
        <BroadlyLogo/>
        <div className="error-message">
          {children}
        </div>
      </div>
    </Block>
  );
}


function Title({ children }) {
  return (
    <h1 className="error-title">{children}</h1>
  );
}

FullscreenMessage.Title = Title;

// Returns all groups filtered by the dimension's current filter.
//
// > A grouping intersects the crossfilter's current filters,
// > except for the associated dimension's filter.
// > Thus, group methods consider only records that satisfy
// > every filter except this dimension's filter.
//
// https://github.com/crossfilter/crossfilter/wiki/Crossfilter-Gotchas#a-group-does-not-observe-its-dimensions-filters
export default function getFilteredGroup({ group, dimension }) {
  const currentFilter = dimension.currentFilter();

  if (currentFilter)
    return group.all().filter(({ key }) => currentFilter(key));
  else
    return group.all();
}

import './scratchpad.less';

import { Download }        from 'react-feather';
import { ExternalLink }    from 'react-feather';
import { Tab }             from 'semantic-ui-react';
import { useNotification } from '../components/notification';
import Block               from '../components/block';
import Button              from '../components/button';
import Confirm             from '../components/confirm';
import Dropdown            from '../components/dropdown';
import LoadingShimmer      from '../components/loading_shimmer';
import NpsBadge            from '../components/nps_badge';
import React               from 'react';
import StarRating          from '../components/star_rating';
import ThumbBadge          from '../components/thumb_badge';


/* eslint-disable react/display-name */
const panes = [
  { menuItem: 'Buttons', render: () => <ButtonsPane/> },
  { menuItem: 'Notifications', render: () => <NotificationsPane/> },
  { menuItem: 'Dropdowns', render: () => <DropdownsPane/> },
  { menuItem: 'Badges', render: () => <BadgesPane/> },
  { menuItem: 'Loading Shimmer', render: () => <LoadingShimmerPane/> },
  { menuItem: 'Text Elements', render: () => <TextPane/> }
];
/* eslint-enable react/display-name */

export default function ScratchPage() {
  return (
    <Block className="scratchpad">
      <h1>Scratch Pad</h1>
      <Tab className="scratchpad-tabs" menu={{ fluid: true, vertical: true, tabular: true }} panes={panes}/>
    </Block>
  );
}


function NotificationsPane() {
  const { showSuccess, showError } = useNotification();
  const [ open, setOpen ]          = React.useState(false);

  function onCancel() {
    setOpen(false);
  }
  function onConfirm() {
    setOpen(false);
    showSuccess('Confirmed!');
  }

  return (
    <Tab.Pane className="notifications-tab">
      <div className="example">
        <Button onClick={() => showSuccess('This is a success message.')}>Show Success</Button>
        <Button onClick={() => showError('This is an error message.')}>Show Error</Button>
      </div>
      <div className="example">
        <h4>Confirm Dialog</h4>
        <p><Button onClick={() => setOpen(true)}>Show Confirm Dialog</Button></p>
        <Confirm
          content="Are you sure you want to do this?"
          cancelButton="Cancel"
          confirmButton="Confirm"
          open={open}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      </div>
    </Tab.Pane>
  );
}


function DropdownsPane() {
  return (
    <Tab.Pane className="dropdowns-tab">
      <div className="spread-row">
        <div className="spread-row-left">
          <ButtonGroupExample/>
        </div>
        <div className="spread-row-right">
          <Dropdown text="Select one…">
            <Dropdown.Menu>
              <Dropdown.Item text="New"/>
              <Dropdown.Item text="Open..."/>
              <Dropdown.Item text="Save as..."/>
              <Dropdown.Item text="Rename"/>
              <Dropdown.Item text="Make a copy"/>
              <Dropdown.Item text="Move to folder"/>
              <Dropdown.Item text="Move to trash"/>
              <Dropdown.Divider/>
              <Dropdown.Item text="Download As..."/>
              <Dropdown.Item text="Publish To Web"/>
              <Dropdown.Item text="E-mail Collaborators"/>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <p>
        Occaecat laboris irure sunt deserunt quis cupidatat do consectetur. Fugiat enim excepteur
        adipisicing enim labore minim excepteur cupidatat anim excepteur proident ad ea. Laboris
        ad adipisicing cillum adipisicing incididunt aliqua ea cillum esse laborum dolore adipisicing
        ea laborum. Commodo do fugiat cillum est magna sint magna eu exercitation. Reprehenderit magna
        do consectetur duis et ea pariatur deserunt do dolore.
      </p>
    </Tab.Pane>
  );
}


function BadgesPane() {
  return (
    <Tab.Pane>
      <div className="example">
        {[ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ].map(n => (
          <NpsBadge key={n} score={n}/>
        ))}
      </div>
      <div className="example">
        <ThumbBadge up/>
        <ThumbBadge down/>
      </div>
      <div className="example">
        <StarRating score={1} service="google"/>
      </div>
      <div className="example">
        <StarRating score={2} service="facebook"/>
      </div>
      <div className="example">
        <StarRating score={3}/>
      </div>
      <div className="example">
        <StarRating score={4}/>
      </div>
      <div className="example">
        <StarRating score={5}/>
      </div>
    </Tab.Pane>
  );
}


function LoadingShimmerPane() {
  return (
    <Tab.Pane>
      <LoadingShimmer/>
    </Tab.Pane>
  );
}


function ButtonGroupExample() {
  const [ active, setActive ] = React.useState(1);
  return (
    <div className="example">
      <Button.Group>
        <Button active={active === 1} onClick={() => setActive(1)}>One</Button>
        <Button active={active === 2} onClick={() => setActive(2)}>Two</Button>
        <Button active={active === 3} onClick={() => setActive(3)}>Three</Button>
        <Button active={active === 4} onClick={() => setActive(4)}>Four</Button>
      </Button.Group>
    </div>
  );
}


function TextPane() {
  return (
    <Tab.Pane>
      <div className="example">
        <h1 className="weight-light">H1 Light Header</h1>
        <h2 className="weight-light">H2 Light Header</h2>
        <h3 className="weight-light">H3 Light Header</h3>
        <h4 className="weight-light">H4 Light Header</h4>
        <h5 className="weight-light">H5 Light Header</h5>
        <h6 className="weight-light">H6 Light Header</h6>
      </div>
      <div className="example">
        <h1>H1 Normal Header</h1>
        <h2>H2 Normal Header</h2>
        <h3>H3 Normal Header</h3>
        <h4>H4 Normal Header</h4>
        <h5>H5 Normal Header</h5>
        <h6>H6 Normal Header</h6>
      </div>
      <div className="example">
        <h1 className="weight-bold">H1 Bold Header</h1>
        <h2 className="weight-bold">H2 Bold Header</h2>
        <h3 className="weight-bold">H3 Bold Header</h3>
        <h4 className="weight-bold">H4 Bold Header</h4>
        <h5 className="weight-bold">H5 Bold Header</h5>
        <h6 className="weight-bold">H6 Bold Header</h6>
      </div>
      <div className="example">
        <h1 className="weight-heavy">H1 Heavy Header</h1>
        <h2 className="weight-heavy">H2 Heavy Header</h2>
        <h3 className="weight-heavy">H3 Heavy Header</h3>
        <h4 className="weight-heavy">H4 Heavy Header</h4>
        <h5 className="weight-heavy">H5 Heavy Header</h5>
        <h6 className="weight-heavy">H6 Heavy Header</h6>
      </div>
      <div className="example">
        <p>
          Cillum anim ad non aliquip nisi duis. Nulla pariatur pariatur eu deserunt non dolor aute adipisicing
          proident consequat commodo. Veniam non nulla ipsum veniam cillum sint veniam proident consectetur.
          Ex exercitation reprehenderit occaecat nostrud quis consectetur commodo laborum laborum eu sunt mollit
          in nostrud.
        </p>
        <p>
          Ullamco occaecat aute adipisicing dolor dolore ullamco exercitation consequat. Nulla mollit culpa
          commodo proident ut tempor in voluptate tempor nulla magna adipisicing laboris. Duis consectetur est
          est officia proident labore ullamco nulla tempor et minim ipsum proident proident.
        </p>
      </div>

      <div className="example">
        <p className="sans-example">This is Open Sans.</p>
        <p className="mono-example">This is Nova Mono. 0 1 2 3 4 5 6 7 8 9</p>
      </div>

    </Tab.Pane>
  );
}


function ButtonsPane() {
  return (
    <Tab.Pane>
      <div className="example">
        <h4>Regular buttons</h4>
        <Button>Regular Button</Button>
        <Button primary>Primary Button</Button>
        <Button disabled>Disabled Regular Button</Button>
        <Button primary disabled>Disabled Primary Button</Button>
      </div>
      <div className="example">
        <h4>Icon buttons</h4>
        <IconButtons/>
      </div>
      <div className="example">
        <h4>Rounded buttons</h4>
        <RoundButtons/>
      </div>
      <div className="example">
        <h4>Buttons with Disabled & Loading states</h4>
        <p>Click a button to cycle through disabled and loading states.</p>
        <DisabledLoadingButtons/>
      </div>
      <div className="example">
        <h4>Button Group</h4>
        <ButtonGroupExample/>
      </div>
    </Tab.Pane>
  );
}

function IconButtons() {
  return (
    <div className="icon-buttons-example">
      <div>
        <p>before</p>
        <Button>
          <Download/>
          <span className="divider"></span>Export
        </Button>
      </div>
      <div>
        <p>after</p>
        <Button className="thick-stroke">
          Export<span className="divider"></span>
          <Download/>
        </Button>
      </div>
    </div>
  );
}

function DisabledLoadingButtons() {
  const normalToggle  = useButtonToggle();
  const primaryToggle = useButtonToggle();
  return (
    <div className="loading-buttons-example">
      <Button {...normalToggle}>Normal Button</Button>
      <Button primary {...primaryToggle}>Primary Button</Button>
    </div>
  );
}

function useButtonToggle() {
  const [ disabled, setDisabled ] = React.useState(false);
  const [ loading, setLoading ]   = React.useState(false);
  function onClick() {
    setDisabled(true);
    setTimeout(() => {
      setLoading(true);
      setTimeout(() => {
        setDisabled(false);
        setLoading(false);
      }, 3000);
    }, 1000);
  }
  return {
    disabled,
    loading,
    onClick
  };
}

function RoundButtons() {
  const toggleOne = useButtonToggle();
  const toggleTwo = useButtonToggle();
  return (
    <div className="round-buttons-example">
      <Button rounded {...toggleOne}>Rounded</Button>
      <Button rounded {...toggleTwo}>
        <ExternalLink/>
        Respond Online
      </Button>
    </div>
  );
}

import * as DateFns         from 'date-fns';
import * as ENV             from '../util/env';
import { getApolloContext } from '@apollo/react-hooks';
import { useContext }       from 'react';
import { useCrossfilter }   from '../util/crossfilter';
import { useEffect }        from 'react';
import { useRef }           from 'react';
import { useState }         from 'react';
import Bluebird             from 'bluebird';
import query                from '../graphql/queries/metrics.graphql';


// Use this hook to load metrics for all locations in the currently specified
// organization.
//
// Returns instance of Crossfilter. Loads metrics asynchronously, adding them to
// the crossfilter, so UI components must listen to crossfilter change events
// and update as new metrics are added.
//
// Loads metrics in two steps: an initial date range first (the minimum range
// required to display data) and then a full range (so user can filter
// information without a server roundtrip).
//
// If an error occurred, stops loading metrics and returns the error.
export default function useMetrics({ locations, startDate, endDate, minDate, maxDate }) {
  const { client }                               = useContext(getApolloContext());
  const [ error, setError ]                      = useState(null);
  const { cf, replace, clear: clearCrossfilter } = useCrossfilter();
  const [ loadingState, setLoadingState ]        = useState('pending');
  const metrics                                  = useRef([]);
  const [ , refresh ]                            = useState();
  const [ startTime ]                            = useState(() => Date.now());

  useEffect(() => {
    setLoadingState('pending');
    setError(null);
    clearCrossfilter();
    metrics.current = [];
  }, [ locations ]); // eslint-disable-line react-hooks/exhaustive-deps

  function onError(err) {
    setError(err);
    setLoadingState('complete');
  }

  function onData(newMetrics) {
    metrics.current.push(...newMetrics);
  }

  useEffect(() => {
    // When transitioning states (initial-loaded, complete),
    // normalize metrics and update Crossfilter.
    const normalizedMetrics = normalizeMetrics({
      locations,
      metrics: metrics.current,
      minDate,
      maxDate
    });
    replace(normalizedMetrics);
    refresh(v => !v);
  }, [ loadingState, metrics, locations, minDate, maxDate ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(function() {
    if (loadingState === 'pending') {
      return loadMetrics({
        client,
        locations,
        fetchPolicy: 'cache-first', // Cache what's needed for initial render.
        startDate,
        endDate,
        onData,
        onComplete:  () => setLoadingState('initial-loaded'),
        onError,
        first:       1000
      });
    } else if (loadingState === 'initial-loaded') {
      const elapsed = Math.round((Date.now() - startTime));
      // eslint-disable-next-line no-console
      console.log(`Loaded initial data set in ${elapsed} ms`);

      const fullDateRange = getFullDateRange({ initialDateRange: { startDate, endDate }, minDate, maxDate });
      return loadMetrics({
        client,
        fetchPolicy: 'no-cache',
        locations,
        ...fullDateRange,
        onData,
        onComplete:  () => setLoadingState('complete'),
        onError,
        first:       1000
      });
    } else
      return () => {};
  }, [ startDate, endDate, minDate, maxDate, loadingState ]); // eslint-disable-line react-hooks/exhaustive-deps

  const isLoading = (loadingState === 'pending');

  return { cf, error, isLoading };
}


// Load metrics concurrently in batches.
//
// Intent to be used from useEffect, and returns a function that will stop
// any loading that's still in progress.
function loadMetrics({ client, locations, fetchPolicy, startDate, endDate, onData, onComplete, onError, first }) {
  let isUnmounted      = false;
  const allLocationIDs = [ ...locations.keys() ];

  async function queryMetrics(locationIDs, cursor = null) {
    const { data, error } = await client.query({
      query,
      fetchPolicy,
      variables: {
        locationIDs,
        startDate,
        endDate,
        first,
        after: cursor
      }
    });

    // Component was unmounted, don't try to change state.
    if (isUnmounted)
      return null;

    if (error)
      throw error;
    else
      return await onNewData(locationIDs, data);
  }

  async function onNewData(locationIDs, data) {
    if (!data) // https://github.com/apollographql/react-apollo/issues/1314
      return null;

    const nodes = data.metrics.edges.map(({ node }) => node);
    onData(nodes);

    const { pageInfo } = data.metrics;
    if (pageInfo.hasNextPage)
      return await queryMetrics(locationIDs, pageInfo.endCursor);
    else
      return null;
  }

  // When user navigates to a different page and component unmounted, call this
  // function to stop loading more metrics, and stop updating component state.
  function stop() {
    isUnmounted = true;
  }

  async function start() {
    // Don't complicate tests with concurrency
    const chunkSize = ENV.isTest() ? 1 : 6;
    const chunks    = splitArray(allLocationIDs, chunkSize);

    try {
      await Bluebird.map(chunks, async function(locationIDs) {
        await queryMetrics(locationIDs);
      }, { concurrency: Infinity });

      if (!isUnmounted)
        onComplete();
    } catch (error) {
      onError(error);
    }
  }

  start();

  return stop;
}


// List all dates between start and end date (inclusive), so we can create
// metrics for dates that are not included in the response.
function listDates(startDate, endDate) {
  let date    = startDate;
  const dates = [];
  while (date <= endDate) {
    dates.push(date);
    const nextDate = new Date(date);
    nextDate.setUTCDate(nextDate.getUTCDate() + 1);
    date = nextDate.toISOString().slice(0, 10);
  }
  return dates;
}


// Remove Nextdoor when we use review count from the location profile.
const servicesWithRating = [ 'google', 'facebook', 'tripAdvisor', 'yelp', 'nextdoor' ];


// Review metrics are sparse, only for days when we got new info.
// This function fills up the missing metrics based on the previous day.
function fillMissingReviews({ dates, location, metrics }) {
  const lastReviews  = {};
  const firstReviews = {};
  const byDate       = metrics.reduce((map, metric) => map.set(metric.date, metric), new Map());
  const sorted       = dates.map(function(date, index) {
    const metric      = byDate.get(date) || emptySet(date);
    const { reviews } = metric;

    // Project rating from the past to the future.
    // [ , , 4.5, , 5.0, ] → [ , , 4.5, 4.5, 5.0, 5.0 ]
    for (const service of servicesWithRating) {
      // Rating is the only property we care to project
      // to the dates where we don't have data.
      if (metric.reviews?.[service]?.rating) {
        if (firstReviews[service] == null)
          firstReviews[service] = { object: reviews[service], index };
        lastReviews[service] = reviews[service];
      } else if (lastReviews[service]) {
        reviews[service]             = reviews[service] ||
          { ...lastReviews[service], reviewsAdded: 0 };
        reviews[service].rating      = lastReviews[service].rating;
        reviews[service].reviewCount = lastReviews[service].reviewCount;
      }
    }

    return {
      ...metric,
      id: `${location.id}:${date}`,
      location
    };
  });

  // Project ratings from the future to the past.
  // This isn't entirely accurate, and we hope that
  // as more data comes in we'll have a more accurate
  // rating. But ratings don't change drastically,
  // so assuming a value from the future is better
  // than assuming a zero.
  // [ , , 4.5, 4.5, 5.0, 5.0 ] → [ 4.5, 4.5, 4.5, 4.5, 5.0, 5.0 ]
  for (const service of servicesWithRating) {
    for (let i = 0; i < firstReviews[service]?.index ?? -1; i++) {
      sorted[i].reviews[service]             = sorted[i].reviews[service] ||
        { ...firstReviews[service].object, reviewsAdded: 0 };
      sorted[i].reviews[service].rating      = firstReviews[service].object.rating;
      sorted[i].reviews[service].reviewCount = firstReviews[service].object.reviewCount;
    }
  }

  return sorted;
}


function emptySet(date) {
  return {
    date,
    classifiedAs:     {},
    feedbackResponse: {},
    leads:            {},
    payments:         {},
    reviews:          {}
  };
}


// If looking at one of the default date ranges (ending today),
// return the date range that is missing (from minimum date until
// the start of the partial date range).
//
// If using a custom date range, return the full date range
// available in dashboard (from minimum date until today).
function getFullDateRange({ initialDateRange, minDate, maxDate }) {
  const today     = DateFns.lightFormat(DateFns.parseISO(maxDate), 'yyyy-MM-dd');
  const startDate = minDate;
  const endsToday = (initialDateRange.endDate === today);
  const endDate   = endsToday ? initialDateRange.startDate : today;
  return {
    startDate,
    endDate
  };
}


function normalizeMetrics({ metrics, locations, minDate, maxDate }) {
  const dates      = listDates(minDate, maxDate);
  const byLocation = groupBy(metrics, ({ location }) => location.id);
  return [ ...byLocation.values() ]
    .flatMap(locationMetrics => {
      const location = locations.get(locationMetrics[0].location.id);
      if (location)
        return fillMissingReviews({ dates, location, metrics: locationMetrics });
      else {
        // No location found, this means we got a metric
        // document for a location that is not in the
        // organization.
        return [];
      }
    });
}


function groupBy(array, identity) {
  const groupedObject = array.reduce((accum, item) => {
    const value = identity(item);
    if (!accum.has(value))
      accum.set(value, []);
    accum.get(value).push(item);
    return accum;
  }, new Map());
  return groupedObject;
}


function splitArray(array, size) {
  const elementsPerArray = Math.ceil(array.length / size);
  return Array(size)
    .fill()
    .map((_, index) => index * elementsPerArray)
    .map(begin => array.slice(begin, begin + elementsPerArray));
}

import { useEffect } from 'react';
import { useState }  from 'react';


export default function useDebounce(value, delay = 400) {
  const [ debouncedValue, setDebouncedValue ] = useState(value);

  useEffect(function() {
    const handler = setTimeout(() => setDebouncedValue(value), delay);

    return function() {
      clearTimeout(handler);
    };
  }, [ value, delay ]);

  return debouncedValue;
}

import './thumb_badge.less';
import { ThumbsDown } from 'react-feather';
import { ThumbsUp }   from 'react-feather';
import classnames     from 'classnames';
import React          from 'react';

//
// Usage:
// <ThumbBadge up/>
// <ThumbBadge down/>
//
// (Technically the down prop is ignored. The appearance is determined by the
// presence/absence of the up prop.)
//

export default function ThumbBadge({ up }) {
  const ratingClass = up ? 'thumb-up' : 'thumb-down';
  const classNames  = classnames('thumb-badge', ratingClass);
  return (
    <div className={classNames}>
      {up ? <ThumbsUp/> : <ThumbsDown/>}
    </div>
  );
}

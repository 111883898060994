import { useSearchParameterState } from '../util/use_search_params';
import Button                      from '../components/button';
import classnames                  from 'classnames';
import React                       from 'react';
import Search                      from '../facets/search_without_cf';
import useIsNPS                    from '../util/use_is_nps';


export default function Filters({ sentimentFacet, visibilityFacet, responseFacet }) {
  return (
    <div className="filters">
      <div className="filter-group">
        <SentimentFilter {...{ sentimentFacet }}/>
        <VisibilityFilter {...{ visibilityFacet }}/>
        <ResponseFilter {...{ responseFacet }}/>
        <SearchFilter/>
      </div>
    </div>
  );
}

function SentimentFilter({ sentimentFacet }) {
  const isNPS                 = useIsNPS();
  const [ active, setActive ] = useSearchParameterState('sentiment');
  const positive              = sentimentFacet?.positive;
  const passive               = sentimentFacet?.passive;
  const negative              = sentimentFacet?.negative;

  return (
    <div className="sentiment-filter">
      <h4 className="filter-header">
        Sentiment
      </h4>
      <Button.Group>
        <Button active={active === 'PROMOTER'} onClick={() => setActive(active === 'PROMOTER' ? '' : 'PROMOTER')}>
          Positive
          <TotalCount count={positive}/>
        </Button>
        {isNPS && (
          <Button active={active === 'PASSIVE'} onClick={() => setActive(active === 'PASSIVE' ? '' : 'PASSIVE')}>
          Neutral
            <TotalCount count={passive}/>
          </Button>
        )}
        <Button active={active === 'DETRACTOR'} onClick={() => setActive(active === 'DETRACTOR' ? '' : 'DETRACTOR')}>
          Negative
          <TotalCount count={negative}/>
        </Button>
      </Button.Group>
    </div>
  );
}

function VisibilityFilter({ visibilityFacet }) {
  const [ active, setActive ] = useSearchParameterState('visibility');
  const privateCount          = visibilityFacet?.private;
  const publicCount           = visibilityFacet?.public;

  return (
    <div className="visibility-filter">
      <h4 className="filter-header">
        Visibility
      </h4>
      <Button.Group>
        <Button active={active === 'PRIVATE'} onClick={() => setActive(active === 'PRIVATE' ? '' : 'PRIVATE')}>
          Private
          <TotalCount count={privateCount}/>
        </Button>
        <Button active={active === 'PUBLIC'} onClick={() => setActive(active === 'PUBLIC' ? '' : 'PUBLIC')}>
          Public
          <TotalCount count={publicCount}/>
        </Button>
      </Button.Group>
    </div>
  );
}


function ResponseFilter({ responseFacet }) {
  const [ active, setActive ] = useSearchParameterState('response');
  const unrepliedCount        = responseFacet?.unreplied;

  return (
    <div className="response-filter">
      <h4 className="filter-header">
        Status
      </h4>
      <Button.Group>
        <Button active={active === 'UNREPLIED'} onClick={() => setActive(active === 'UNREPLIED' ? '' : 'UNREPLIED')}>
          Unreplied
          <TotalCount count={unrepliedCount}/>
        </Button>
      </Button.Group>
    </div>
  );
}

function SearchFilter() {
  return (
    <div className="search-filter">
      <h4 className="filter-header">
        Search
      </h4>
      <Search/>
    </div>
  );
}

function TotalCount({ count }) {
  const isLoading = typeof count === 'undefined';
  const className = classnames('total-count', { loading: isLoading });
  const rounded   = isLoading ? '-' : roundNumber(count);

  return (
    <div className={className}>{rounded}</div>
  );
}

// 930 -> 930
// 3000 -> 3k
// 1999 -> 2k
// 2593 -> 2.6k
function roundNumber(value) {
  if (typeof value !== 'number')
    return 0;

  if (value < 1000)
    return value;
  else {
    const abbreviation = value / 1000;
    const rounded      = Number(abbreviation.toFixed(1));
    return `${rounded}k`;
  }
}

import { useDimension } from '../util/crossfilter';
import { useEffect }    from 'react';


export default function useDateDimension(cf, dateRange, fn = ({ date }) => date) {
  const dimension              = useDimension(cf, fn);
  const { startDate, endDate } = dateRange;

  useEffect(() => {
    dimension.filterFunction(date => (
      date >= startDate && date <= endDate
    ));
  }, [ dimension, startDate, endDate ]);

  return dimension;
}

// React hooks for using Crossfilter efficiently.

import { useEffect } from 'react';
import { useState }  from 'react';
import crossfilter   from 'crossfilter2';


// For functional components that need their own crossfilter.
//
// Caches the crossfilter in the state.
//
// Returns the crossfilter, and a function for adding new nodes.
// Nodes must have a unique ID (node.id).  It is safe to add the
// same node multiple times, this will not affect any component
// using this crossfilter.
export function useCrossfilter() {
  const [ cf ]  = useState(() => crossfilter());
  const [ ids ] = useState(() => new Set());

  function add(nodes) {
    if (Array.isArray(nodes)) {
      const newNodes = nodes.filter(({ id }) => !ids.has(id));
      cf.add(newNodes);
      for (const node of newNodes)
        ids.add(node.id);
    } else
      add([ nodes ]);
  }

  function replace(nodes) {
    if (Array.isArray(nodes)) {
      const existingIDsArray = nodes
        .map(({ id }) => id)
        .filter(id => ids.has(id));
      const existingIDs      = new Set(existingIDsArray);
      cf.remove(node => existingIDs.has(node.id));
      cf.add(nodes);
      for (const node of nodes)
        ids.add(node.id);
    } else
      replace([ nodes ]);
  }

  function clear() {
    // If we don't pass a predicate function, then cf.remove only removes the
    // filtered entries.  Learned this the hard way.
    cf.remove(() => true);
    ids.clear();
  }

  return { cf, add, replace, clear };
}


// For functional components that need their own dimension.
//
// Caches the dimension in the state, and forces the component to render
// whenever the crossfilter changes (data or filters).
export function useDimension(
  // Crossfilter instance from useCrossfilter
  cf,
  // Dimension accessor function
  // See https://github.com/crossfilter/crossfilter/wiki/API-Reference#dimension
  fn,
  // Set to true when dimension accessor returns an array
  // (aka "tag dimension")
  isArray = false
) {
  // It's expensive to create new dimensions, so store in state.
  // It's also expensive to have more than 8 dimensions, so dispose
  // of dimension when component unmounts.
  //
  // We assume parent component always renders with same cf,
  // see useCrossfilter.
  const [ dimension ] = useState(() => cf.dimension(fn, isArray));

  useEffect(function() {
    // Cleanup when component unmounted
    return function() {
      dimension.dispose();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return dimension;
}


// For functional components that need a group.
export function useGroup({
  dimension,
  groupBy = identity,
  orderBy = identity,
  reducers
}) {
  const [ group ] = useState(() =>
    dimension.group(groupBy).reduce(...reducers).order(orderBy)
  );
  useEffect(function() {
    // Cleanup when component unmounted
    return function() {
      group.dispose();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return group;
}


// For functional components that need to re-render whenever there is
// new data in the crossfilter, or change to the filter.
export function useOnChange(cf) {
  const [ , refresh ] = useState(false);

  useEffect(function() {
    // https://github.com/crossfilter/crossfilter/wiki/API-Reference#crossfilter_onChange
    return cf.onChange(function() {
      refresh(x => !x);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}


// For functional components that need to apply a filter to a dimension.
export function useFilter(dimension, filter) {
  useEffect(() => {
    dimension.filterFunction(filter);
    return () => dimension.filterAll();
  }, [ dimension, filter ]);
}


export function disposeOfAllGroups(groups) {
  while (groups.length) {
    const group = groups.pop();
    group.dispose();
  }
}


function identity(x) {
  return x;
}

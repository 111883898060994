import { Input }                   from 'semantic-ui-react';
import { useEffect }               from 'react';
import { useSearchParameterState } from '../util/use_search_params';
import { useState }                from 'react';
import React                       from 'react';
import useDebounce                 from '../util/use_debounce';


export default function Search() {
  const [ searchQuery, setSearchQuery ] = useSearchParameterState('search');
  const [ query, setQuery ]             = useState(searchQuery || '');
  const debouncedQuery                  = useDebounce(query, 400);

  useEffect(function setQueryString() {
    setSearchQuery(debouncedQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ debouncedQuery ]);

  function onChange({ target }) {
    setQuery(target.value);
  }

  return (
    <Input
      className="search-facet"
      placeholder='Search...'
      type="search"
      value={query}
      onChange={onChange}
    />
  );
}

import { useCallback } from 'react';
import { useMemo }     from 'react';

// Works similar to useState but stores the value in the query string parameter.
// Returns [ currentValue, setValue ].
//
// If the query string parameter is missing/empty, returns null.
//
// If you change the query string parameter to null/empty, it removes it.
export function useSearchParameterState(name) {
  const [ query, updateQuery ] = useSearchParametersState();
  const currentValue           = useMemo(() => query.get(name), [ name, query ]);

  const setValue = useCallback(value => {
    updateQuery(map => {
      const isEmpty = (value == null || value === '' ||
                       (Array.isArray(value) && value.length === 0));
      if (isEmpty)
        map.delete(name);
      else
        map.set(name, value);
      return map;
    });
  }, [ name, updateQuery ]);

  return [ currentValue, setValue ];
}

import { useHistory }  from 'react-router';
import { useLocation } from 'react-router';



// Works similar to useState but stores the value as the query string.
// Returns [ query, setQuery ].
//
// The query is a Map (name => value).
//
// You can update the query by replacing with a new Map, or supplying
// a function that takes the current query and returns a modified Map.
export function useSearchParametersState() {
  const location = useLocation();
  const history  = useHistory();
  const query    = useParseQueryString(location);

  const setQuery = useCallback(newQueryOrFunction => {
    if (typeof newQueryOrFunction === 'function') {
      const newQuery = newQueryOrFunction(query);
      setQuery(newQuery);
    } else if (newQueryOrFunction instanceof Map) {
      const newQuery = newQueryOrFunction;
      replaceQueryString({ history, location, query: newQuery });
    } else
      throw Error('Either Map or function');
  }, [ history, location, query ]);

  return [ query, setQuery ];
}


function useParseQueryString(location) {
  const { search } = location;

  return useMemo(() => new Map(
    search
      .slice(1)
      .split('&')
      .filter(Boolean)
      .map(pair => pair.split('=').map(decodeURIComponent))
      .filter(([ , value ]) => value)
  ), [ search ]);
}

function replaceQueryString({ history, location, query }) {
  const search = [ ...query.entries() ]
    .map(([ name, value ]) => [ name, value ].map(encodeValue))
    .map(pair => pair.join('='))
    .join('&');

  history.replace({ ...location, search });
}

function encodeValue(value) {
  return Array.isArray(value) ?
    value.map(encodeURIComponent).join(',') :
    encodeURIComponent(value);
}

import './metrics_section.less';

import React from 'react';


export default function MetricsSection({ title, subtitle, children }) {
  return (
    <div className="metrics-section">
      <div className="head">
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </div>

      {children}
    </div>
  );
}

import AccountForm from './account_form';
import Block       from '../components/block';
import React       from 'react';


export default function NewAccountForm() {
  return (
    <div className="account-detail">
      <Block>
        <AccountForm isCreate/>
      </Block>
    </div>
  );
}

import { logEvent }                           from './log_event';
import { useAccessToken }                     from '../app/authenticate';
import { useCurrentUser }                     from './use_current_user';
import { useEffect }                          from 'react';
import { useOrganization }                    from './use_organizations';
import { useSearchParameterState }            from './use_search_params';
import { useSelectedLocationsWithUserAccess } from './log_event';


// Used to make sure that logEvent is only called once per page
// Accepts same parameters as the amplitude.logEvent method
export function useLogPageViewEvent(eventType, eventProperties, callback) {
  const selectedLocationsWithUserAccess = useSelectedLocationsWithUserAccess();
  const { id, name }                    = useCurrentUser();
  const organization                    = useOrganization();
  const [ locations ]                   = useSearchParameterState('locations');
  const accessToken                     = useAccessToken()?.accessToken;

  useEffect(function() {
    const userProperties = { id, name };
    if (accessToken) {
      logEvent({
        accessToken,
        userProperties,
        organization,
        locations,
        selectedLocationsWithUserAccess,
        eventType,
        eventProperties,
        callback
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ organization, locations, accessToken ]);
}

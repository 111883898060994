import * as DateFns                 from 'date-fns';
import { useMemo }                  from 'react';
import { useSearchParametersState } from '../util/use_search_params';


export default function useDateRange({ daysAgo = 30, maxDate }) {
  const [ query, updateQuery ] = useSearchParametersState();

  const endDate   = getEndDate({ query, maxDate });
  const startDate = getStartDate({ query, daysAgo, endDate });

  // eslint-disable-next-line no-shadow
  function setDateRange({ startDate, endDate, days }) {
    updateQuery(map => {
      if (startDate && endDate) {
        map.set('from',  DateFns.lightFormat(startDate, 'yyyy-MM-dd'));
        map.set('until', DateFns.lightFormat(endDate, 'yyyy-MM-dd'));
        map.delete('days');
      } else {
        map.delete('from');
        map.delete('until');
        map.set('days', Math.max(days || 1, 1));
      }
      return map;
    });
  }

  const dateRange = useMemo(
    () => ({
      // Start and end date string using `yyyy-MM-dd` format.
      startDate,
      endDate,
      // Start and end date as Date object using local timezone.
      localStartDate: new Date(`${startDate} 00:00`),
      localEndDate:   DateFns.endOfDay(new Date(`${endDate} 00:00`))
    }),
    [ startDate, endDate ]
  );

  return [ dateRange, setDateRange ];
}


function getEndDate({ query, maxDate }) {
  return getDateFromQuery({
    queryValue:  query.get('until'),
    maxDate,
    defaultDate: DateFns.parseISO(maxDate)
  });
}

function getStartDate({ query, daysAgo, endDate }) {
  const daysfromQuery = Number.parseInt(query.get('days'), 10);
  return getDateFromQuery({
    queryValue:  query.get('from'),
    maxDate:     endDate,
    defaultDate: DateFns.subDays(
      DateFns.parseISO(endDate),
      (daysfromQuery || daysAgo) - 1
    )
  });
}

function getDateFromQuery({ queryValue, maxDate, defaultDate }) {
  const fromQuery      = DateFns.parseISO(queryValue);
  const queryOrDefault = DateFns.isValid(fromQuery) ?
    DateFns.min([ fromQuery, DateFns.parseISO(maxDate) ]) :
    defaultDate;
  return DateFns.lightFormat(queryOrDefault, 'yyyy-MM-dd');
}

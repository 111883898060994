import XLSX from 'xlsx';


const allColumns = [
  { header: 'Timestamp', field: 'timestamp', width: 15 },
  { header: 'Name', field: 'name', width: 40 },
  { header: 'Contact ID', field: 'contactID', width: 25 },
  { header: 'Location Name', field: 'locationName', width: 40 },
  { header: 'Location ID', field: 'locationID', width: 25 },
  { header: 'Team Member(s)', field: 'teamMembers', width: 20 },
  { header: 'Sentiment', field: 'classifiedAs', width: 15 },
  { header: 'Service', field: 'reviewService', width: 15 },
  { header: 'Rating', field: 'reviewRating', width: 10 },
  { header: 'NPS Rating', field: 'npsScore', width: 10 },
  { header: 'Text', field: 'text', width: 200 }
];


// See https://docs.sheetjs.com/
export default function createFeedbackWorksheet({ allFeedback, locations, isNPS }) {
  const columns = getColumns(isNPS);
  const headers = columns.map(({ header }) => header);

  const rows = allFeedback
    .map(feedback => ({
      ...feedback,
      timestamp: {
        v: feedback.timestamp,
        t: 'd',
        z: 'yyyy-mm-dd'
      },
      name:          getContactName(feedback),
      contactID:     getContactID(feedback),
      locationName:  getLocationName({ feedback, locations }),
      locationID:    feedback.location.id,
      reviewService: feedback.review?.service,
      reviewRating:  feedback.review?.rating,
      text:          getText(feedback),
      teamMembers:   getTeamMembers(feedback)
    }));

  const cells = rows
    .map(row => columns.map(({ field }) => row[field]));

  const worksheet    = XLSX.utils.aoa_to_sheet([ headers, ...cells ]);
  worksheet['!cols'] = columns
    .map(({ width, header }) => ({ wch: width || header.length }));

  return { worksheet, worksheetName: 'Review Metrics' };
}


function getColumns(isNPS) {
  return isNPS ?
    allColumns :
    allColumns.filter(({ field }) => field !== 'npsScore');
}


function getContactName(feedback) {
  const { contact } = feedback;
  if (contact)
    return contact.displayName;
  const { review } = feedback;
  if (review)
    return review.author.name;
  return '';
}


function getContactID(feedback) {
  return feedback.contact?.id || '';
}


function getLocationName({ locations, feedback }) {
  const locationID = feedback.location.id;
  const location   = locations.get(locationID);
  return location?.name || '';
}


function getText(feedback) {
  const { review } = feedback;
  if (review)
    return review.body;
  const { message } = feedback;
  if (message)
    return message.body;
  return '';
}


function getTeamMembers(feedback) {
  return feedback.providers?.map(({ name }) => name).join(', ');
}

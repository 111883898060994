import { capitalCase } from '../util/change_case';
import XLSX            from 'xlsx';


const npsColumns = [
  { header: 'Keyword',  field: 'term',     width: 30 },
  { header: 'Total',    field: 'total',    width: 15 },
  { header: 'Positive', field: 'positive', width: 15 },
  { header: 'Neutral',  field: 'passive',  width: 15 },
  { header: 'Negative', field: 'negative', width: 15 }
];

const nonNpsColumns = [
  { header: 'Keyword',  field: 'term',     width: 30 },
  { header: 'Total',    field: 'total',    width: 15 },
  { header: 'Positive', field: 'positive', width: 15 },
  { header: 'Negative', field: 'negative', width: 15 }
];


// See https://docs.sheetjs.com/
export default function createKeywordWorksheet({ keywords, isNPS }) {
  const columns = isNPS ? npsColumns : nonNpsColumns;
  const headers = columns.map(({ header }) => header);

  const rows = keywords
    .map(keyword => ({
      term: capitalCase(keyword.term),
      ...keyword.sentiment
    }));

  const cells = rows
    .map(row => columns.map(({ field }) => row[field]));

  const worksheet    = XLSX.utils.aoa_to_sheet([ headers, ...cells ]);
  worksheet['!cols'] = columns
    .map(({ width, header }) => ({ wch: width || header.length }));

  return { worksheet, worksheetName: 'Keywords' };
}

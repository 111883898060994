import './footer.less';

import { Link }           from 'react-router-dom';
import { useCurrentUser } from '../util/use_current_user';
import Block              from '../components/block';
import BroadlyIcon        from '../images/broadly-icon.svg';
import FeatureAvailable   from '../components/feature_available';
import React              from 'react';


export default function Footer() {
  const { locations } = useCurrentUser();

  return (
    <Block className="footer-block">
      <footer>
        <nav className="footer-navigation">
          <span className="copyright">© All rights reserved</span>
          {/*
          // not showing this link until we can distinguish between free/premium
          // users
          <a href="https://broadly.com/schedule-demo/" target="_blank" rel="noopener noreferrer">
            Upgrade to Premium
          </a>
          */}
          <FeatureAvailable feature="support" locations={locations}>
            <a href="https://broadlyhelp.force.com/s/contactsupport" target="_blank" rel="noopener noreferrer">
              Need Help? Contact Broadly Support
            </a>
          </FeatureAvailable>
        </nav>
        <div className="logo">
          <Link to="/">
            <BroadlyIcon/>
          </Link>
        </div>
      </footer>
    </Block>
  );
}

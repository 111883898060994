import { CheckSquare, Square } from 'react-feather';
import { useCallback }         from 'react';
import { useMemo }             from 'react';
import { useState }            from 'react';
import classnames              from 'classnames';
import Dropdown                from './dropdown';
import React                   from 'react';


// We need the `search` option to prevent closing
// the dropdown when pressing the space bar.
// https://github.com/Semantic-Org/Semantic-UI-React/pull/3766
export default function MultiselectDropdown({
  options: unfilteredOptions,
  onClick,
  placeholder,
  onClearAll,
  className,
  fluid = false,
  maxSizeOptions = Infinity
}) {
  const [ search, setSearch ] = useState('');
  const filterOptions         = useCallback(({ text }) => text.toLowerCase().includes(search), [ search ]);
  const options               = useMemo(() => unfilteredOptions.filter(filterOptions), [ unfilteredOptions, filterOptions ]);
  const hasMultiple           = options.length > 1;
  const hasSelected           = options.some(option => option.active);
  const trimmedOptions        = options.slice(0, maxSizeOptions);
  const isBiggerThanMaxSize   = options.length > maxSizeOptions;
  const classNames            = classnames('multiselect', className);

  function onSearchChange({ target }) {
    setSearch(target.value.toLowerCase());
  }

  return (
    <Dropdown text={placeholder} className={classNames} fluid={fluid} search>
      <Dropdown.Menu>
        <React.Fragment>
          <div className="search-item">
            <input className="input-search" placeholder='Search...' value={search} onChange={onSearchChange}/>
          </div>
          <Dropdown.Divider/>
        </React.Fragment>
        {hasMultiple && (
          <React.Fragment>
            <Dropdown.Item disabled={!hasSelected} onClick={onClearAll}>
              Clear All
            </Dropdown.Item>
            <Dropdown.Divider/>
          </React.Fragment>
        )}
        {trimmedOptions.map(option => (
          <Dropdown.Item
            key={option.key}
            active={option.active}
            onClick={e => onClick(e, option)}
            text={<TextWithCheckbox {...option}/>}
          />
        ))}
        {isBiggerThanMaxSize && (
          <Dropdown.Item
            disabled
            text={`Showing first ${maxSizeOptions} items`}
          />
        )}
        {trimmedOptions.length === 0 && (
          <Dropdown.Item disabled text={'No results'}/>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function TextWithCheckbox({ active, text }) {
  return (
    <span className="with-checkbox">
      {active && <CheckSquare/>}
      {!active && <Square/>}
      {text}
    </span>
  );
}

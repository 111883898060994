import * as d3                  from 'd3';
import { getTicks }             from '../util/ticks';
import { useData }              from './score/historical';
import { useDataForChart }      from './score/breakdown';
import { VisualizeMetricsLine } from '../components/visualize_metrics';
import MetricsPageSection       from './metrics_page_section';
import MetricsSection           from './metrics_section';
import React                    from 'react';
import scoreReducers            from './score/score_reducers';
import useAggregates            from './use_aggregates';
import useIsNPS                 from '../util/use_is_nps';


const formatWithCommaDelimiters = d3.format(',d');

export default function Satisfaction({ dimension, dateRange }) {
  const isNPS      = useIsNPS();
  const reducers   = scoreReducers(isNPS);
  const aggregates = useAggregates({ dimension, reducers, dateRange });

  return (
    <MetricsPageSection>
      <NpsSection {...{ dimension, dateRange, isNPS, reducers, aggregates }}/>
      <PromoterDetractorsSection {...{ dimension, dateRange, isNPS, reducers, aggregates }}/>
    </MetricsPageSection>
  );
}

function NpsSection({ dimension, dateRange, isNPS, reducers, aggregates }) {
  const sectionTitle = isNPS ? 'Net Promoter Score ®' : 'Customer Satisfaction Score';

  const title          = isNPS ? 'NPS' : undefined;
  const color          = '#3583EA';
  const current        = aggregates.current.classifiedAs.score;
  const previous       = aggregates.previous.classifiedAs.score;
  const delta          = current - previous;
  const points         = useData({ dimension, reducers, dateRange, isNPS });
  const allYValues     = points.map(point => point.y);
  const ticks          = getTicks({ values: allYValues, lowerBound: -100, upperBound: 100, minRange: 20 });
  const nonNpsSubtitle = 'Your Customer Satisfaction Score represents the percentage of satisfied customers who have responded to your review requests.';
  const npsSubtitle    = (
    'Net Promoter Score (NPS) measures both customer satisfaction and loyalty. ' +
    'This is how your NPS Score is calculated: NPS = % Promoters - % Detractors'
  );
  const subtitle       = isNPS ? npsSubtitle : nonNpsSubtitle;

  const metric = {
    title,
    color,
    current,
    previous,
    delta,
    points,
    ticks
  };

  return (
    <MetricsSection title={sectionTitle} subtitle={subtitle}>
      <VisualizeMetricsLine metrics={[ metric ]}/>
    </MetricsSection>
  );
}


function PromoterDetractorsSection({ dimension, dateRange, isNPS, reducers, aggregates }) {
  const sectionTitle    = isNPS ? 'Promoters, Passives, & Detractors' : 'Promoters & Detractors';
  const link            = <a href="https://broadlyhelp.force.com/s/article/Responding-to-unhappy-customers-detractors">See our tips for managing detractors</a>;
  const npsSubtitle     = (
    <>
                            When asked to rate your business, promoters are customers who give a score of 9-10,
                            passives are customers who give a score of 7-8 and detractors are customers who give a score of 0-6. {link}.</>
  );
  const nonNpsSubtitle  = (
    <>Promoters are customers who have indicated that they would recommend your business.
                            Detractors are unhappy customers who often have an issue with their service that requires attention. {link}.</>
  );
  const sectionSubtitle = isNPS ? npsSubtitle : nonNpsSubtitle;

  const allMetrics       = useDataForChart({ dimension, reducers, dateRange });
  const promotersMetric  = getPromotersMetric({ isNPS, aggregates, allMetrics });
  const passivesMetric   = getPassivesMetric({ isNPS, aggregates, allMetrics });
  const detractorsMetric = getDetractorsMetric({ aggregates, allMetrics });

  const metrics = [ promotersMetric, passivesMetric, detractorsMetric ].filter(Boolean);

  return (
    <MetricsSection title={sectionTitle} subtitle={sectionSubtitle}>
      <VisualizeMetricsLine metrics={metrics} formatter={formatWithCommaDelimiters}/>
    </MetricsSection>
  );
}

function getPromotersMetric({ aggregates, allMetrics }) {
  const title    = 'Promoters';
  const color    = '#4AB934';
  const current  = aggregates.current.classifiedAs.promoters;
  const previous = aggregates.previous.classifiedAs.promoters;
  const points   = allMetrics.map(metric => ({ x: metric.date, y: metric.classifiedAs.promoters }));
  const delta    = getDelta({ current, previous, isGood: isZeroOrMore });

  return {
    title,
    color,
    current: formatWithCommaDelimiters(current),
    previous,
    points,
    delta
  };
}


function getPassivesMetric({ isNPS, aggregates, allMetrics }) {
  if (!isNPS)
    return undefined;

  const title    = 'Passives';
  const color    = '#F1C857';
  const current  = aggregates.current.classifiedAs.passives;
  const previous = aggregates.previous.classifiedAs.passives;
  const points   = allMetrics.map(metric => ({ x: metric.date, y: metric.classifiedAs.passives }));
  const delta    = getDelta({ current, previous, isGood: isZeroOrMore });

  return {
    title,
    color,
    current: formatWithCommaDelimiters(current),
    previous,
    points,
    delta
  };
}


function getDetractorsMetric({ aggregates, allMetrics }) {
  const title    = 'Detractors';
  const color    = '#F36668';
  const current  = aggregates.current.classifiedAs.detractors;
  const previous = aggregates.previous.classifiedAs.detractors;
  const points   = allMetrics.map(metric => ({ x: metric.date, y: metric.classifiedAs.detractors }));
  const delta    = getDelta({ current, previous, isGood: isZeroOrLess });

  return {
    title,
    color,
    current: formatWithCommaDelimiters(current),
    previous,
    points,
    delta
  };
}


function getDelta({ current, previous, isGood }) {
  const formatter = d3.format('.0%');
  const change    = previous ? (current / previous - 1) : 0;
  const text      = formatter(change);
  const quality   = isGood(change) ? 'good' : 'bad';

  return {
    text,
    quality
  };
}

function isZeroOrMore(n) {
  return n >= 0;
}

function isZeroOrLess(n) {
  return n <= 0;
}

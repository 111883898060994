import './star_rating.less';

import { Star }     from 'react-feather';
import FacebookLogo from '../images/facebook_logo.svg';
import GoogleLogo   from '../images/google_logo.svg';
import PropTypes    from 'prop-types';
import React        from 'react';
import serviceNames from '../util/service_name';


export default function StarRating({ service, url, score }) {
  return (
    <a href={url} className="star-rating" target="_blank" rel="noopener noreferrer">
      {service && <Service service={service}/>}
      <Stars score={score}/>
    </a>
  );
}

StarRating.propTypes = {
  service: PropTypes.string,
  url:     PropTypes.string,
  score:   PropTypes.oneOf([ 1, 2, 3, 4, 5 ])
};


function Stars({ score }) {
  const ariaLabel = score === 1 ? `${score} star` : '${score} stars';

  return (
    <div aria-label={ariaLabel} className="stars">
      {[ 1, 2, 3, 4, 5 ].map(n => (<StarIcon key={n} n={n} score={score}/>))}
    </div>
  );
}

function StarIcon({ score, n }) {
  const type      = score >= n ? 'solid' : 'outline';
  const className = `star ${type}`;
  return (
    <div className={className}>
      <Star/>
    </div>
  );
}

function Service({ service }) {
  return (
    <div className="service">
      {service === serviceNames.GOOGLE && <GoogleLogo/>}
      {service === serviceNames.FACEBOOK && <FacebookLogo/>}
    </div>
  );
}

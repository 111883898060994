import * as DateFns from 'date-fns';
import { useGroup } from '../../util/crossfilter';


export function useDataForChart({ dimension, reducers, dateRange }) {
  const daysInRange = DateFns.differenceInDays(
    DateFns.parseISO(dateRange.endDate),
    DateFns.parseISO(dateRange.startDate)
  );
  const isMonthly   = daysInRange > 30 * 7;
  const isWeekly    = daysInRange > 30;

  function getKeyDate(date) {
    const realDate = DateFns.parseISO(date);
    if (isMonthly) {
      const month = DateFns.startOfMonth(realDate);
      return DateFns.lightFormat(month, 'yyyy-MM-dd');
    } else if (isWeekly) {
      const weekly = DateFns.startOfWeek(realDate);
      return DateFns.lightFormat(weekly, 'yyyy-MM-dd');
    } else
      return date;
  }

  const [ addReducer, , initialReducer ] = reducers;
  const group                            = useGroup({ dimension, reducers });

  const byDate = group.all()
    .filter(({ key: date }) => date >= dateRange.startDate && date <= dateRange.endDate)
    .reduce(function(map, { key: date, value: entry }) {
      const keyDate = getKeyDate(date);
      const entries = map.get(keyDate) || [];
      entries.push(entry);
      map.set(keyDate, entries);
      return map;
    }, new Map());

  return [ ...byDate.entries() ]
    .map(function([ date, entries ]) {
      const { classifiedAs } = entries.reduce(addReducer, initialReducer());
      return { date, classifiedAs };
    })
    .sort((a, b) => a.date.localeCompare(b.date));
}

import * as d3      from 'd3';
import * as DateFns from 'date-fns';
import { useGroup } from '../../util/crossfilter';


// Chart averages NPS score for last 30 days
const daysForAverage = 30;

export function useData({ dimension, reducers, dateRange, isNPS }) {
  const group      = useGroup({ dimension, reducers });
  const dataPoints = 100;
  const dates      = listDates({ dateRange, dataPoints });
  return dates
    .map(date => getDataPoint({
      group,
      reducers,
      endDate:  date,
      daysBack: daysForAverage,
      isNPS
    }))
    .filter(Boolean);
}


function listDates({ dateRange, dataPoints }) {
  const daysInRange = DateFns.differenceInDays(
    DateFns.parseISO(dateRange.endDate),
    DateFns.parseISO(dateRange.startDate)
  );
  const resolution  = Math.max(Math.floor(daysInRange / dataPoints), 1);
  const dates       = [];
  let date          = dateRange.startDate;
  while (date <= dateRange.endDate) {
    dates.push(date);
    date = DateFns.lightFormat(
      DateFns.addDays(DateFns.parseISO(date), resolution),
      'yyyy-MM-dd'
    );
  }
  return dates;
}


function getDataPoint({ group, reducers, endDate, daysBack, isNPS }) {
  const [ addReducer, , initialReducer ] = reducers;

  const startDate = DateFns.lightFormat(
    DateFns.subDays(DateFns.parseISO(endDate), daysBack),
    'yyyy-MM-dd'
  );

  const { classifiedAs } = group
    .all()
    .filter(({ key: date }) => date >= startDate && date <= endDate)
    .map(({ value }) => value)
    .reduce(addReducer, initialReducer());

  if (classifiedAs.responded >= 0) {
    const tooltip = getTooltip({ classifiedAs, isNPS });
    return { x: endDate, y: classifiedAs.score, tooltip };
  } else
    return null;
}


// Show calculation in tooltip
function getTooltip({ classifiedAs, isNPS }) {
  if (isNPS) {
    const formatPercent                               = d3.format('.1%');
    const { score, promoters, detractors, responded } = classifiedAs;
    return `${score} = ${formatPercent(promoters / responded)} - ${formatPercent(detractors / responded)}`;
  } else {
    const { score, promoters, responded } = classifiedAs;
    return `${score}% = ${promoters} / ${responded}`;
  }
}

import { useNotification } from '../components/notification';
import { useQuery }        from '@apollo/react-hooks';
import LoadingScreen       from '../components/loading_screen';
import React               from 'react';
import rollbar             from '../rollbar';


export default function Query({ query, variables, children }) {
  const { data, loading, error } = useQuery(query, { fetchPolicy: 'cache-first', variables });
  const { showError }            = useNotification();

  if (error) {
    // No need to show an auth error in the UI.
    // We reauthenticate and retry in the apollo client.
    const isAuthError = error.networkError?.statusCode === 401;
    if (isAuthError)
      return null;

    rollbar.error(error);
    showError('Something went wrong. Refresh the page to try again.');
    return null;
  } else if (loading)
    return <LoadingScreen/>;
  else
    return children(data);
}

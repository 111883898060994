// Component to create a new Apollo GraphQL client as context provider.

import { ApolloClient }                 from 'apollo-client';
import { ApolloLink }                   from 'apollo-link';
import { createHttpLink }               from 'apollo-link-http';
import { InMemoryCache }                from 'apollo-cache-inmemory';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { isTest }                       from '../util/env';
import { Observable }                   from 'apollo-link';
import { onError }                      from 'apollo-link-error';
import { RetryLink }                    from 'apollo-link-retry';
import { setContext }                   from 'apollo-link-context';
import ApolloLinkTimeout                from 'apollo-link-timeout';
import apolloLogger                     from 'apollo-link-logger';
import ms                               from 'ms';


const GRAPHQL_ENDPOINT   = process.env.GRAPHQL_ENDPOINT || 'https://graph.broadly.com/';
const REQUEST_TIMEOUT    = ms('30s');
const RETRY_MAX_DELAY    = ms('2s');
const RETRY_MAX_ATTEMPTS = 3;


// See https://www.apollographql.com/docs/link/links/retry#options
const retryPolicy = {
  delay: {
    initial: Math.round(RETRY_MAX_DELAY / 10),
    max:     RETRY_MAX_DELAY,
    jitter:  true
  },
  attempts: {
    max:     RETRY_MAX_ATTEMPTS,
    retryIf: error => {
      // Don't retry on client error (auth token, forbidden, invalid input),
      // just give up and let the user deal with these errors.
      const isServerError  = error?.statusCode >= 500;
      const isTimeout      = error?.statusCode === 408;
      const isNetworkError = error?.message.includes('Failed to fetch');
      return isServerError || isTimeout || isNetworkError;
    }
  }
};


// IntrospectionFragmentMatcher has been removed in v3. It is recommended to
// use InMemoryCache’s possibleTypes option instead (Available only in v3).
// https://www.apollographql.com/docs/react/migrating/apollo-client-3-migration/#breaking-cache-changes
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind:          'UNION',
          name:          'Attachment',
          possibleTypes: [
            {
              name: 'FileAttachment'
            },
            {
              name: 'ImageAttachment'
            }
          ]
        },
        {
          kind:          'UNION',
          name:          'MemberOf',
          possibleTypes: [
            {
              name: 'MemberOfLocation'
            },
            {
              name: 'MemberOfOrganization'
            }
          ]
        }
      ]
    }
  }
});


// Create a new Apollo Client
export default function newApolloClient({ accessToken, reauthenticate, impersonate }) {
  // Apollo links is composition of middleware, the last of which makes the
  // GraphQL request to the server.  Order of composition matters.
  const link = ApolloLink.from([
    // Retry failed requests, prior to logging so we log every attempt.
    new RetryLink(retryPolicy),
    logging(),

    // Give up if the network is slow, or the server never responds.
    new ApolloLinkTimeout(REQUEST_TIMEOUT),

    setContext(addAuthHeader),
    onError(handleAuthError),

    createHttpLink({ uri: GRAPHQL_ENDPOINT })
  ]);


  // GraphQL queries snad objects are cached here, and not in Redux store.
  const cache = new InMemoryCache({
    fragmentMatcher,
    dataIdFromObject: object => {
      return object.id || null;
    }
  });

  // Add authorization header.
  function addAuthHeader() {
    const authorization = `Bearer ${accessToken}`;
    return {
      headers: {
        authorization,
        ...getImpersonateHeaders(impersonate)
      }
    };
  }

  // If the server tells us the token is invalid/expired/etc, we'll ask the user
  // to authenticate.  Right now, authenticate is terminal (browser redirect).
  function handleAuthError({ networkError, operation, forward }) { // eslint-disable-line consistent-return
    const statusCode = networkError?.statusCode;

    if (statusCode === 401)
      return reauthenticateObservable({ reauthenticate, forward, operation });
  }

  return new ApolloClient({ link, cache });
}


// Log each GraphQL request to console in development environment.
function logging() {
  if (process.env.NODE_ENV === 'production' || isTest()) {
    return function noop(operation, forward) {
      return forward(operation);
    };
  } else
    return apolloLogger;
}


// Observable to get a new access token and retry the request.
// Based on https://stackoverflow.com/questions/50965347/-/51321068
function reauthenticateObservable({ reauthenticate, forward, operation }) {
  return new Observable(async observer => {
    try {
      const newAccessToken = await reauthenticate();

      operation.setContext(({ headers = {} }) => ({
        headers: {
          // Re-add old headers
          ...headers,
          // Switch out old access token for new one
          authorization: `Bearer ${newAccessToken}`
        }
      }));

      const subscriber = {
        next:     observer.next.bind(observer),
        error:    observer.error.bind(observer),
        complete: observer.complete.bind(observer)
      };

      // Retry last failed request
      forward(operation).subscribe(subscriber);
    } catch (error) {
      observer.error(error);
    }
  });
}


// Add HTTP headers to indicate impersonation.
function getImpersonateHeaders(impersonate) {
  if (impersonate) {
    const { entity, id } = impersonate;
    const headers        = { [`x-${entity}`]: id };
    return headers;
  } else
    return null;
}

import './visualize_metrics.less';

import * as d3                 from 'd3';
import { format }              from 'date-fns';
import { formatISO }           from 'date-fns';
import { getTicks }            from '../util/ticks';
import { parseISO }            from 'date-fns';
import classnames              from 'classnames';
import React                   from 'react';

import { Area }                from 'recharts';
import { AreaChart }           from 'recharts';
import { Bar }                 from 'recharts';
import { BarChart }            from 'recharts';
import { CartesianGrid }       from 'recharts';
import { ResponsiveContainer } from 'recharts';
import { Tooltip }             from 'recharts';
import { XAxis }               from 'recharts';
import { YAxis }               from 'recharts';


// Metrics has a shape of:
// [{
//   points:     [{ x: Date, y: Number }, ...],
//   title:      String,
//   current:    Number,
//   delta:      Number,
//   color:      String (Hex) (optional),
//   meta:       String (optional),
//   ticks:      [ Number ],
// }, ...];
//
// xAxisOverrides has shape of:
// {
//  [ Any property passed to XAxis ]
// }
//
// yAxisOverrides has shape of:
// {
//  [ Any property passed to YAxis ]
// }
//
export function VisualizeMetricsLine({ metrics, formatter, xAxisOverrides, yAxisOverrides }) {
  const multipleMetrics = metrics.length > 1;

  return (
    <div className="visualize-metrics">
      {multipleMetrics && <MultipleMetrics {...{ metrics }}/>}
      {!multipleMetrics && <SingleMetric metric={metrics[0]}/>}

      <div className="trends">
        <TimeSeries {...{ metrics, formatter, xAxisOverrides, yAxisOverrides }}/>
      </div>
    </div>
  );
}

export function VisualizeMetricsBar({ metrics, formatter, xAxisOverrides, yAxisOverrides }) {
  const multipleMetrics = metrics.length > 1;

  return (
    <div className="visualize-metrics">
      {multipleMetrics && <MultipleMetrics {...{ metrics }}/>}
      {!multipleMetrics && <SingleMetric metric={metrics[0]}/>}

      <div className="trends">
        <TimeSeriesBar {...{ metrics, formatter, xAxisOverrides, yAxisOverrides }}/>
      </div>
    </div>
  );
}

function SingleMetric({ metric }) {
  const { title }   = metric;
  const { current } = metric;
  const { delta }   = metric;
  const { meta }    = metric;
  return (
    <div className="metrics">
      {title && <span className="title">{title}</span>}
      <div className="current-wrap">
        <div className="current">
          {current}
          <Delta delta={delta}/>
        </div>
      </div>
      <span className="meta">{meta || 'Latest'}</span>
    </div>
  );
}

function MultipleMetrics({ metrics }) {
  const [ metricA, metricB, metricC ] = metrics;

  return (
    <div className="compare-metrics">
      {metricA && <Part metric={metricA}/>}
      {metricB && <Part metric={metricB}/>}
      {metricC && <Part metric={metricC}/>}
    </div>
  );
}

function Part({ metric }) {
  const { title }   = metric;
  const { current } = metric;
  const { delta }   = metric;
  const { color }   = metric;
  return (
    <div className="part">
      <span className="title" style={{ borderColor: color }}>{title}</span>
      <div className="current-wrap">
        <div className="current">
          {current}
          <Delta delta={delta}/>
        </div>
      </div>
    </div>
  );
}

function Delta({ delta }) {
  const { text, quality } = getDeltaProps(delta);
  const classNames        = classnames('delta', quality);
  // if delta text is empty, output a non-breaking space to preserve layout
  return (
    <span className={classNames}>{text || <>&nbsp;</>}</span>
  );
}

function getDeltaProps(delta) {
  if (typeof delta === 'number')
    return getNumericDeltaProps(delta);
  else
    return delta;
}

function getNumericDeltaProps(change) {
  const formatter = d3.format('+,d');
  const formatted = formatter(change);
  const text      = change === 0 ? '' : formatted;
  const quality   = change < 0 ? 'bad' : 'good';

  return {
    text,
    quality
  };
}


function TimeSeries({ metrics, formatter = defaultFormatter, xAxisOverrides = {} }) {
  if (!metrics.length || !metrics[0].points.length)
    return null;

  // All line graphs have a shared X axis. Pick out the X axis from the first
  // chart. e.g.: ["2020-01-18", "2020-01-19", ...]
  const dates = metrics[0].points.map(point => formatISO(parseISO(point.x), { representation: 'date' }));
  const yAxes = getYAxes({ metrics });

  // A combination of date & chart metrics
  // [{xAxisDate: "2020-01-19", line0: 10, line1: 0, line2: 1}, ...]
  const dataPoints = combineCharts({ dates, yAxes });

  // Styling the graph
  const responsiveContainerStyles = { width: '100%', height: 300 };
  const xAxisStyles               = { padding: { left: 5, right: 5 }, tickSize: 10 };
  const areaChartStyles           = {
    width:  '100%',
    margin: {
      top:    40,
      right:  yAxes.length === 1 ? 5 : -20,
      left:   -5,
      bottom: 20
    }
  };

  return (
    <ResponsiveContainer {...responsiveContainerStyles}>
      <AreaChart data={dataPoints} {...areaChartStyles}>
        <XAxis
          dataKey="xAxisDate"
          tick={<CustomizedXAxisTick/>}
          {...xAxisStyles}
          {...xAxisOverrides}
        />

        {/* Avoid grid lines overlapping the Y axes lines */}
        <CartesianGrid strokeDashArray="10 0" horizontal={false}/>

        {yAxes.map((yAxis, i) => {
          const orientation   = (i === 0) ? 'left' : 'right';
          const bottomPadding = yAxis.isTruncated ? 30 : 5;

          return (
            <YAxis
              key={i}
              domain={yAxis.domain}
              yAxisId={yAxis.unit}
              ticks={yAxis.ticks}
              tick={(
                <CustomizedYAxisTick orientation={orientation} formatter={yAxis.metrics[0].formatter || formatter}/>
              )}
              padding={{ left: 3, bottom: bottomPadding, top: 3 }}
              minTickGap={1}
              tickSize={10}
              orientation={orientation}/>
          );
        })}
        <Tooltip content={<CustomTooltip formatter={formatter}/>}/>

        {yAxes.map(({ unit, metrics }) => { // eslint-disable-line no-shadow
          return metrics.map((metric, i) => {
            const { color } = metric;
            const { area }  = metric;
            const dataKey   = `line-${metric.index}`;
            const options   = {
              dataKey,
              type:         'linear',
              activeDot:    { r: 5 },
              connectNulls: true
            };

            // Inactive data points are usually not shown. Show them
            // if there is only one point on the graph so it's not blank.
            const dotRadius = dataPoints.length === 1 ? 5 : 0;

            const svgStyles = {
              dot:               { fill: color, r: dotRadius },
              fill:              area ? color : 'rgba(0, 0, 0, 0)',
              stroke:            color,
              activeDot:         { r: 5 },
              fillOpacity:       1,
              strokeWidth:       area ? 0 : 3,
              animationEasing:   'ease-in-out',
              animationDuration: 200
            };
            return <Area key={`${unit}-${i}`} {...options} {...svgStyles} yAxisId={unit}/>;
          });
        })}

        {yAxes.filter(({ isTruncated }) => isTruncated).map((_, i) => (
          <g key={i} className="truncated-y-axis-marker">
            <g className="marker-group">
              <rect x="1" width="4" height="14"/>
              <path d="M0.5 0V14 M5 0V14"/>
            </g>
          </g>
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
}


// Reduces X and Y axes into a single array suitable
// to be used as source data for a Recharts graph.
//
// e.g.: [{ xAxisDate: '2020-03-31', line-0: 29, line-1: 10 ... }]
export function combineCharts({ dates, yAxes }) {
  return dates.map((date, dateIndex) => {
    return yAxes.reduce((accum, { domain, metrics }) => {
      return metrics.reduce((accum, metric) => { // eslint-disable-line no-shadow
        const point                    = metric.points[dateIndex];
        const { scale = defaultScale } = metric;
        const { index }                = metric;
        const { y }                    = point;
        return {
          ...accum,
          [`line-${index}`]:   scale({ y, domain }),
          [`metric-${index}`]: metric,
          [`point-${index}`]:  point
        };
      }, accum);
    }, { xAxisDate: date });
  });
}


function CustomTooltip({ active, payload, label, formatter }) {
  if (!active)
    return null;

  const date = label && format(parseISO(label), 'do LLL, yyyy');

  return (
    <div className="custom-recharts-tooltip">
      <div className="date">{date}</div>

      {payload.map((point, i) => {
        const metric = point.payload[`metric-${i}`];
        const { y }  = point.payload[`point-${i}`];
        return (
          <div key={i} style={{ backgroundColor: point.color }}>
            {(metric.formatter || formatter)(y)}
          </div>
        );
      })}
    </div>
  );
}


function CustomizedYAxisTick({ x, y, payload, formatter, orientation }) {
  const svgStyles = {
    x:          0,
    y:          (orientation === 'left') ? -30 : 20,
    dy:         (orientation === 'left') ? 20 : 0,
    textAnchor: 'middle',
    fontSize:   '13px',
    fill:       '#81A0C9',
    transform:  'rotate(-90)',
    fontFamily: 'Nova Mono, monospace'
  };
  return (
    <g transform={`translate(${x},${y})`}>
      <text {...svgStyles}>{formatter(payload.value)}</text>
    </g>
  );
}


function CustomizedXAxisTick({ x, y, payload }) {
  const date          = parseISO(payload.value);
  const formattedDate = format(date, 'do LLL');

  const svgStyles = {
    x:          0,
    y:          0,
    dy:         20,
    textAnchor: 'middle',
    fill:       '#81A0C9',
    fontSize:   '13px',
    fontFamily: 'Nova Mono, monospace'
  };

  return (
    <g transform={`translate(${x},${y})`}>
      <text {...svgStyles}>{formattedDate}</text>
    </g>
  );
}


function defaultFormatter(value) {
  return value;
}


function defaultScale({ y }) {
  return y;
}


// Returns an array of items grouped by a given identity function.
//
// > groupBy([
//   { id: 1, name: 'foo' },
//   { id: 2, name: 'bar' },
//   { id: 1, name: 'baz' }
// ], item => item.id);
// [
//   [ 1, [ { id: 1, name: 'foo' }, { id: 1, name: 'baz' } ] ],
//   [ 2, [ { id: 2, name: 'foo' } ] ]
// ]
function groupBy(array, identity) {
  const groupedObject = array.reduce((accum, item) => {
    const value = identity(item);
    if (!accum.has(value))
      accum.set(value, []);
    accum.get(value).push(item);
    return accum;
  }, new Map());
  const groupedArray = [ ...groupedObject.keys() ].map(key => [ key, groupedObject.get(key) ]);
  return groupedArray;
}


// Returns an array of Y axes with their units, ticks, domain, etc.
function getYAxes({ metrics, isBar }) {
  const metricsByUnit = groupBy(metrics, metric => metric.unit || 'number');
  const axes          = metricsByUnit.map(([ unit, metricsInUnit ]) => {
    // Y axis plots for all metrics in this unit
    // e.g.: [[2, 3, 5, ...], [1, 1.3, 2.1, ...], ...]
    const yPoints          = metricsInUnit.flatMap(metric => metric.points.map(point => point.y));
    const getTicksOptions  = { values: yPoints, lowerBound: isBar ? 0 : Math.min(...yPoints), includeZero: isBar };
    const ticks            = metricsInUnit[0].ticks || getTicks(getTicksOptions);
    const domainStart      = ticks[0];
    const domainEnd        = ticks[ticks.length - 1];
    const domain           = [ domainStart, domainEnd ];
    const isTruncated      = (domainStart > 0);
    const metricsWithIndex = metricsInUnit.map(metric => ({ ...metric, index: metrics.indexOf(metric) }));
    return { unit, ticks, domain, isTruncated, metrics: metricsWithIndex };
  });
  return axes;
}



function TimeSeriesBar({ metrics, formatter = defaultFormatter, xAxisOverrides = {} }) {
  if (!metrics.length || !metrics[0].points.length)
    return null;

  // All line graphs have a shared X axis. Pick out the X axis from the first
  // chart. e.g.: ["2020-01-18", "2020-01-19", ...]
  const dates = metrics[0].points.map(point => formatISO(parseISO(point.x), { representation: 'date' }));
  const yAxes = getYAxes({ metrics, isBar: true });

  // A combination of date & chart metrics
  // [{xAxisDate: "2020-01-19", line0: 10, line1: 0, line2: 1}, ...]
  const dataPoints = combineCharts({ dates, yAxes });

  // Styling the graph
  const responsiveContainerStyles = { width: '100%', height: 300 };
  const xAxisStyles               = { padding: { left: 5, right: 5 }, tickSize: 10 };
  const areaChartStyles           = {
    width:  '100%',
    margin: {
      top:    40,
      right:  yAxes.length === 1 ? 5 : -20,
      left:   -5,
      bottom: 20
    }
  };

  return (
    <ResponsiveContainer {...responsiveContainerStyles}>
      <BarChart data={dataPoints} {...areaChartStyles}>
        <XAxis
          dataKey="xAxisDate"
          tick={<CustomizedXAxisTick/>}
          {...xAxisStyles}
          {...xAxisOverrides}
        />

        {/* Avoid grid lines overlapping the Y axes lines */}
        <CartesianGrid strokeDashArray="10 0" horizontal={false}/>

        {yAxes.map((yAxis, i) => {
          const orientation   = (i === 0) ? 'left' : 'right';
          const bottomPadding = yAxis.isTruncated ? 30 : 5;
          return (
            <YAxis
              key={i}
              domain={yAxis.domain}
              ticks={yAxis.ticks}
              tick={(
                <CustomizedYAxisTick orientation={orientation} formatter={yAxis.metrics[0].formatter || formatter}/>
              )}
              padding={{ left: 3, bottom: bottomPadding, top: 3 }}
              minTickGap={1}
              tickSize={10}
              orientation={orientation}/>
          );
        })}
        <Tooltip content={<CustomTooltip formatter={formatter}/>} cursor={{ fill: '#f6f6f6', strokeWidth: 0 }}/>

        {yAxes.map(({ unit, metrics }) => { // eslint-disable-line no-shadow
          return metrics.map((metric, i) => {
            const { color } = metric;
            const options   = {
              dataKey:           `line-${metric.index}`,
              fill:              color,
              fillOpacity:       1,
              animationEasing:   'ease-in-out',
              animationDuration: 200,
              minPointSize:      2
            };
            return <Bar key={`${unit}-${i}`} {...options}/>;
          });
        })}
      </BarChart>
    </ResponsiveContainer>
  );
}

import * as ENV             from '../util/env';
import { getApolloContext } from '@apollo/react-hooks';
import { useContext }       from 'react';
import { useEffect }        from 'react';
import { useState }         from 'react';
import Bluebird             from 'bluebird';
import query                from '../graphql/queries/providers.graphql';
import useLocations         from '../util/use_locations';


export default function useProviders() {
  const locations                         = useLocations();
  const { client }                        = useContext(getApolloContext());
  const [ loadingState, setLoadingState ] = useState('pending');
  const [ error, setError ]               = useState(null);
  const [ providers, setProviders ]       = useState([]);

  function onData(newProviders) {
    // providers.current.push(...newProviders);
    setProviders(previous => previous.concat(newProviders));
  }

  function onError(err) {
    setError(err);
    setLoadingState('complete');
  }

  function onComplete() {
    setLoadingState('complete');
  }

  useEffect(() => {
    setLoadingState('pending');
    setError(null);
    setProviders([]);
  }, [ locations ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(function() {
    return loadProviders({ client, locations, onData, onComplete, onError });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ locations ]);

  const isLoading = (loadingState === 'pending');

  return { providers, error, isLoading };
}


// Load providers in small batches, and updates crossfilter as necessary.
//
// Intent to be used from useEffect, and returns a function that will stop
// any loading that's still in progress.
function loadProviders({ client, locations, onData, onComplete, onError }) {
  let isUnmounted      = false;
  const allLocationIDs = [ ...locations.keys() ];

  async function queryProviders(locationIDs, cursor = null) {
    // Apollo supports pagination with fetchMore, but it relies on the cache.
    const { data, error } = await client.query({
      query,
      variables: {
        locationIDs,
        first: 1000,
        after: cursor
      }
    });

    // Component was unmounted, don't try to change the state.
    if (isUnmounted)
      return null;

    if (error)
      throw error;
    else
      return await onNewData(locationIDs, data);
  }

  async function onNewData(locationIDs, data) {
    onData(getProviders(data));

    const { pageInfo } = data.providers;
    if (pageInfo.hasNextPage)
      return await queryProviders(locationIDs, pageInfo.endCursor);
    else
      return null;
  }

  // When user navigates to a different page and component unmounted, call this
  // function to stop loading more metrics, and stop updating component state.
  function stop() {
    isUnmounted = true;
  }

  async function start() {
    // Don't complicate tests with concurrency
    const chunkSize = ENV.isTest() ? 1 : 6;
    const chunks    = splitArray(allLocationIDs, chunkSize);

    try {
      await Bluebird.map(chunks, async function(locationIDs) {
        await queryProviders(locationIDs);
      }, { concurrency: Infinity });

      if (!isUnmounted)
        onComplete();
    } catch (error) {
      onError(error);
    }
  }

  start();

  return stop;
}


function getProviders(data) {
  const { edges } = data.providers;
  return edges.map(({ node }) => node);
}


function splitArray(array, size) {
  const elementsPerArray = Math.ceil(array.length / size);
  return Array(size)
    .fill()
    .map((_, index) => index * elementsPerArray)
    .map(begin => array.slice(begin, begin + elementsPerArray));
}

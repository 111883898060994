import { jws } from 'jsrsasign';

export default function isSalesforceToken(accessToken) {
  try {
    const { payloadObj } = jws.JWS.parse(accessToken);
    const { sub }        = payloadObj;
    return sub.startsWith('salesforce');
  } catch (error) { // invalid token to parse, probably a test
    return false;
  }
}

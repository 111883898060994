import { Redirect }                from 'react-router-dom';
import { useSearchParameterState } from './util/use_search_params';
import Query                       from './components/query';
import query                       from './graphql/queries/locations.graphql';
import React                       from 'react';


export default function RedirectToOrganizationPage() {
  const [ locationID ] = useSearchParameterState('locations');
  const [ path ]       = useSearchParameterState('path');

  return (
    <Query query={query}>
      {({ locations }) => {
        const location = locations.edges[0].node;

        if (location) {
          const pathWithSeparator = path.startsWith('/') ? path : '/'.concat(path);
          const to                = {
            pathname: `/${location?.organization?.id || '_'}${pathWithSeparator}`,
            search:   `?locations=${locationID}`
          };
          return <Redirect to={to} push/>;
        } else
          throw new Error('Location not found');
      }}
    </Query>
  );
}

import { useCurrentUser }  from './use_current_user';
import { useLocation }     from './use_locations';
import { useMemo }         from 'react';
import { useOrganization } from './use_organizations';


// Return all organzations + root locations
export function useBrands() {
  const { organizations, locations } = useCurrentUser();

  return useMemo(
    () => getEntries({ organizations, locations }),
    [ organizations, locations ]
  );
}


function getEntries({ organizations, locations }) {
  const locationsWithNoOrganization = locations.filter(location => !location.organization);
  const organizationsFromLocations  = locations
    .map(location => location.organization)
    .filter(Boolean);

  const allOrganizations    = [ ...organizations, ...organizationsFromLocations ];
  const uniqueOrganizations = uniqueBy(allOrganizations, ({ id }) => id)
    .map(organization => ({ ...organization, isOrganization: true }));

  const brands = [ ...uniqueOrganizations, ...locationsWithNoOrganization ];
  return brands;
}


export function useBrand() {
  const organization = useOrganization();
  const location     = useLocation();

  if (organization)
    return organization;
  else
    return location;
}


function uniqueBy(array, predicate) {
  const added  = new Set();
  const result = array.reduce((accum, item) => {
    const value = predicate(item);
    if (!added.has(value)) {
      added.add(value);
      accum.push(item);
    }
    return accum;
  }, []);
  return result;
}

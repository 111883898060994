import currency from 'currency.js';


export default [
  add,
  remove,
  initial
];


function getInitialReviewInfo() {
  return {
    totalRating:  0,
    ratingCount:  0,
    rating:       0,
    reviewsAdded: 0
  };
}


function getInitialReviews() {
  return {
    byDate:      new Map(),
    google:      getInitialReviewInfo(),
    facebook:    getInitialReviewInfo(),
    yelp:        getInitialReviewInfo(),
    nextdoor:    getInitialReviewInfo(),
    tripAdvisor: getInitialReviewInfo()
  };
}


function add(acc, { classifiedAs, leads, reviews, payments, location = {}, organization = {}, feedbackResponse, date }) {
  const { askForFeedback: askForFeedbackLoc } = location;
  const { askForFeedback: askForFeedbackOrg } = organization;
  const isNPS                                 = (askForFeedbackLoc === 'NPS' || askForFeedbackOrg === 'NPS');

  return  {
    ...addScore({
      promoters:         acc.classifiedAs.promoters  + (classifiedAs.promoters || 0),
      passives:          acc.classifiedAs.passives   + (classifiedAs.passives || 0),
      detractors:        acc.classifiedAs.detractors + (classifiedAs.detractors || 0),
      npsScore:          acc.classifiedAs.npsScore,
      satisfactionScore: acc.classifiedAs.satisfactionScore,
      isNPS
    }),
    ...addLeads({
      currentLeads: acc.leads,
      newLeads:     leads
    }),
    payments: {
      paid:        acc.payments.paid.add(payments.paid),
      outstanding: acc.payments.outstanding.add(payments.outstanding)
    },
    ...reviewsReducer(acc, { date, reviews }, 'add'),
    ...addResponseRate({
      asked:     acc.feedbackResponse.asked          + (feedbackResponse.asked || 0),
      responded: acc.feedbackResponse.responded      + (feedbackResponse.responded || 0)
    }),
    isNPS,
    organization
  };
}


function remove(acc, { classifiedAs, leads, reviews, payments, location = {}, organization = {}, feedbackResponse, date }) {
  const { askForFeedback: askForFeedbackLoc } = location;
  const { askForFeedback: askForFeedbackOrg } = organization;
  const isNPS                                 = (askForFeedbackLoc === 'NPS' || askForFeedbackOrg === 'NPS');

  return ({
    ...addScore({
      promoters:         acc.classifiedAs.promoters  - (classifiedAs.promoters || 0),
      passives:          acc.classifiedAs.passives   - (classifiedAs.passives || 0),
      detractors:        acc.classifiedAs.detractors - (classifiedAs.detractors || 0),
      npsScore:          acc.classifiedAs.npsScore,
      satisfactionScore: acc.classifiedAs.satisfactionScore,
      isNPS
    }),
    ...subLeads({
      currentLeads: acc.leads,
      newLeads:     leads
    }),
    ...reviewsReducer(acc, { date, reviews }, 'remove'),
    payments: {
      paid:        acc.payments.paid.subtract(payments.paid),
      outstanding: acc.payments.outstanding.subtract(payments.outstanding)
    },
    ...addResponseRate({
      asked:     acc.feedbackResponse.asked          - (feedbackResponse.asked || 0),
      responded: acc.feedbackResponse.responded      - (feedbackResponse.responded || 0)
    }),
    isNPS,
    organization
  });
}


function initial() {
  return {
    leads: {
      newLeads:          0,
      respondedTo:       null,
      totalResponseTime: 0,
      responseTime:      0
    },
    classifiedAs: {
      promoters:         0,
      passives:          0,
      detractors:        0,
      responded:         0,
      npsScore:          0,
      satisfactionScore: 0
    },
    reviews:  getInitialReviews(),
    payments: {
      paid:        currency(0),
      outstanding: currency(0)
    },
    feedbackResponse: {
      asked:        0,
      responded:    0,
      responseRate: 0
    },
    isNPS: false
  };
}


function addScore({ promoters, passives, detractors, satisfactionScore, npsScore, isNPS }) {
  if (isNPS) {
    const responded = promoters + passives + detractors;
    const newScore  = responded ? Math.round((promoters - detractors) / responded * 100) : 0;
    return {
      classifiedAs: {
        promoters, passives, detractors, responded, npsScore: newScore, satisfactionScore
      }
    };
  } else {
    const responded = promoters + detractors;
    const newScore  = responded ? Math.round(promoters / responded * 100) : 0;
    return {
      classifiedAs: {
        promoters, passives, detractors, responded, npsScore, satisfactionScore: newScore
      }
    };
  }
}

function addLeads({ currentLeads, newLeads }) {
  if (Object.keys(newLeads).length === 0)
    return { leads: currentLeads };

  const newLeadsSum  = currentLeads?.newLeads ? currentLeads.newLeads + newLeads.newLeads : newLeads.newLeads;
  const respondedTo  = currentLeads?.respondedTo ? currentLeads.respondedTo + newLeads.respondedTo : newLeads.respondedTo;
  const responseTime = currentLeads?.responseTime ? currentLeads.responseTime + newLeads.responseTime : newLeads.responseTime;

  return {
    leads: {
      newLeads:     newLeadsSum || 0,
      respondedTo:  respondedTo || 0,
      responseTime: responseTime || 0
    }
  };
}


function subLeads({ currentLeads, newLeads }) {
  if (Object.keys(newLeads).length === 0)
    return { leads: currentLeads };

  const newLeadsSub  = currentLeads?.newLeads ? currentLeads.newLeads - newLeads.newLeads : newLeads.newLeads;
  const respondedTo  = currentLeads?.respondedTo ? currentLeads.respondedTo - newLeads.respondedTo : newLeads.respondedTo;
  const responseTime = currentLeads?.responseTime ? currentLeads.responseTime - newLeads.responseTime : newLeads.responseTime;

  return {
    leads: {
      newLeads:     newLeadsSub || 0,
      respondedTo:  respondedTo || 0,
      responseTime: responseTime || 0
    }
  };
}


// Rating and review count are aggregates themselves.
// We only need to reduce to the latest observed value.
// The add and remove reducers are called whenever
// items are added or removed from the Crossfilter,
// and also when items get filtered. We need to keep
// a reference of the review data by date so we can
// always reduce to the latest value.
//
// If there is more than one data point per date (such
// as having one for each location on the brands page),
// the scores are averaged over all the points.
function reviewsReducer(acc, { date, reviews }, operation) {
  const { byDate } = acc.reviews;
  const isAdd      = operation === 'add';

  if (isAdd) {
    if (!byDate.has(date))
      byDate.set(date, []);
    byDate.get(date).push(reviews);
  } else
    byDate.delete(date);

  const allDates    = [ ...byDate.keys() ].sort();
  const lastDate    = allDates[allDates.length - 1];
  const lastReviews = byDate.get(lastDate)?.reduce(reduceReviewCountAndRating, getInitialReviews());

  return {
    reviews: {
      byDate,
      google: {
        ...lastReviews?.google,
        reviewsAdded: acc.reviews.google.reviewsAdded + (reviews?.google?.reviewsAdded || 0) * (isAdd ? 1 : -1)
      },
      facebook: {
        ...lastReviews?.facebook,
        reviewsAdded: acc.reviews.facebook.reviewsAdded + (reviews?.facebook?.reviewsAdded || 0) * (isAdd ? 1 : -1)
      },
      yelp: {
        ...lastReviews?.yelp,
        reviewsAdded: acc.reviews.yelp.reviewsAdded + (reviews?.yelp?.reviewsAdded || 0) * (isAdd ? 1 : -1)
      },
      nextdoor: {
        ...lastReviews?.nextdoor,
        reviewsAdded: acc.reviews.nextdoor.reviewsAdded + (reviews?.nextdoor?.reviewsAdded || 0) * (isAdd ? 1 : -1)
      },
      tripAdvisor: {
        ...lastReviews?.tripAdvisor,
        reviewsAdded: acc.reviews.tripAdvisor.reviewsAdded + (reviews?.tripAdvisor?.reviewsAdded || 0) * isAdd ? 1 : -1
      }
    }
  };
}


function addResponseRate(feedbackResponse) {
  const { asked, responded } = feedbackResponse;
  const responseRate         = asked ? Math.min(responded / asked, 1) : 0;

  return {
    feedbackResponse: {
      asked,
      responded,
      responseRate,
      ...feedbackResponse
    }
  };
}


function reduceReviewCountAndRating(acc, reviews) {
  return {
    google:      addRatings(acc.google, reviews.google),
    facebook:    addRatings(acc.facebook, reviews.facebook),
    yelp:        addRatings(acc.yelp, reviews.yelp),
    nextdoor:    addRatings(acc.nextdoor, reviews.nextdoor),
    tripAdvisor: addRatings(acc.tripAdvisor, reviews.tripAdvisor)
  };
}


function addRatings(accumulator, currentItem) {
  const reviewsAdded  = accumulator.reviewsAdded + (currentItem?.reviewsAdded || 0);
  const totalRating   = accumulator.totalRating + (currentItem?.rating || 0);
  const currentRating = currentItem?.rating ? 1 : 0;
  const ratingCount   = accumulator.ratingCount + currentRating;
  const rating        = ratingCount ? (totalRating / ratingCount) : 0;

  return {
    reviewsAdded,
    totalRating,
    ratingCount,
    rating
  };
}

import { useCurrentUser }   from '../util/use_current_user';
import amplitude            from './amplitude';
import isSalesforceToken    from './is_salesforce_token';
import useSelectedLocations from '../util/use_selected_locations';


export function logEvent({
  accessToken,
  userProperties,
  organization,
  locations,
  selectedLocationsWithUserAccess,
  eventType,
  eventProperties,
  callback
}) {
  if (isSalesforceToken(accessToken))
    return;

  amplitude.setUserProperties(userProperties);

  // We want to make sure we log which organization that the user is accessing
  const organizationID   = organization?.id;
  const organizationName = organization?.name;

  // We want to list all locationIDs that are selected, if they are selected
  const locationIDs       = locations?.split(',') || [];
  const allLocations      = (organization?.locations && Array.from(organization.locations.values())) || [];
  const selectedLocations = allLocations
    .filter(location => locationIDs.includes(location.id))
    .map(location => ({
      id:        location.id,
      name:      location.name,
      createdAt: location.createdAt
    }));

  amplitude.logEvent(eventType, {
    ...eventProperties,
    organizationID,
    organizationName,
    selectedLocations
  }, callback);

  selectedLocationsWithUserAccess.forEach(location => (
    logPlanhatEvent({
      action:            eventType,
      userID:            userProperties.id,
      userName:          userProperties.name,
      companyExternalId: location
    }))
  );
}

function logPlanhatEvent({ action, userID, userName, companyExternalId }) {
  const requestOptions = {
    method:  'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body:     JSON.stringify({ action, name: userName, externalId: userID, companyExternalId }),
    redirect: 'follow'
  };

  const planhatUUID = '33728570-6d67-4ce5-8c80-60607422ce4e';

  fetch(`https://analytics.planhat.com/analytics/${planhatUUID}`, requestOptions);
}

export function useSelectedLocationsWithUserAccess() {
  const selectedLocations = useSelectedLocations();
  const { locations }     = useCurrentUser();
  const result            = locations.reduce((acc, location) => {
    if (selectedLocations.has(location.id)) {
      return [
        ...acc,
        location.id
      ];
    } else
      return acc;
  }, []);
  return result;
}

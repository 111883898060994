import * as d3                      from 'd3';
import { getProviderDisplayName }   from './index';
import { getProvidersNameCountMap } from './index';
import get                          from 'lodash.get';
import XLSX                         from 'xlsx';


function getColumns(columnsInUse) {
  const showNpsScore          = columnsInUse.includes('feedback.npsScore');
  const showPassives          = columnsInUse.includes('feedback.passives') || showNpsScore;
  const showSatisfactionScore = columnsInUse.includes('feedback.satisfactionScore');

  const columns = [
    { header: 'ID', field: 'id', width: 25 },
    { header: 'Team Member', field: 'name', width: 25 },
    { header: 'Customers Asked', field: 'asked' },
    { header: 'Response Rate', field: 'responseRate' },
    { header: 'Promoters', field: 'promoters' },
    ...(showPassives ? [{ header: 'Passives', field: 'passives' }] : []),
    { header: 'Detractors', field: 'detractors' },
    ...(showNpsScore ? [{ header: 'NPS Score', field: 'npsScore' }] : []),
    ...(showSatisfactionScore ? [{ header: 'Satisfaction Score', field: 'satisfactionScore' }] : []),
    { header: 'Reviews', field: 'reviews' },
    { header: 'Average Rating', field: 'rating' }
  ];

  return columns;
}


// See https://docs.sheetjs.com/
export default function createWorksheet(providersMetrics) {
  const providerNameCountMap = getProvidersNameCountMap(providersMetrics);
  const optionalColumns      = [ 'feedback.npsScore', 'feedback.satisfactionScore', 'feedback.passives' ];
  const columnsInUse         = getColumnsInUse(providersMetrics, optionalColumns);
  const columns              = getColumns(columnsInUse);
  const headers              = columns.map(({ header }) => header);
  const rows                 = providersMetrics
    .map(row => calculate({ row, columnsInUse, providerNameCountMap }))
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(row => columns.map(({ field }) => row[field]));

  const worksheet    = XLSX.utils.aoa_to_sheet([ headers, ...rows ]);
  worksheet['!cols'] = columns
    .map(({ width, header }) => ({ wch: width || header.length }));

  return { worksheet, worksheetName: 'Team Metrics' };
}

function calculate({ row, columnsInUse, providerNameCountMap }) {
  const { name }              = row;
  const { location }          = row;
  const showNpsScore          = columnsInUse.includes('feedback.npsScore');
  const showPassives          = columnsInUse.includes('feedback.passives') || showNpsScore;
  const showSatisfactionScore = columnsInUse.includes('feedback.satisfactionScore');
  const providerDisplayName   = getProviderDisplayName({ name, location, providerNameCountMap });

  return {
    id:           row.id,
    name:         providerDisplayName,
    asked:        row.feedbackResponse.asked || '',
    responseRate: row.feedbackResponse.responseRate ? d3.format('.0%')(row.feedbackResponse.responseRate) : '',
    promoters:    row.feedback.promoters || '',
    ...(showPassives ? { passives: row.feedback.passives || '' } : {}),
    detractors:   row.feedback.detractors || '',
    ...(showNpsScore ? { npsScore: row.feedback.npsScore || '' } : {}),
    ...(showSatisfactionScore ? { satisfactionScore: row.feedback.satisfactionScore || '' } : {}),
    reviews:      row.feedbackResponse.reviews || '',
    rating:       row.feedbackResponse.rating ? row.feedbackResponse.rating.toFixed(2) : ''
  };
}

function getColumnsInUse(data, optionalColumns) {
  return data.reduce((acc, location) => {
    if (optionalColumns.length === acc.length)
      return acc;

    optionalColumns.forEach(column => {
      const value = get(location, column);

      if (!acc.includes(column) && value)
        acc.push(column);
    });

    return acc;
  }, []);
}

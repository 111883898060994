export default function scoreReducers(isNPS) {
  return [
    ({ classifiedAs: acc }, { classifiedAs: value }) => addScore({
      promoters:  acc.promoters  + (value.promoters || 0),
      passives:   acc.passives   + (value.passives || 0),
      detractors: acc.detractors + (value.detractors || 0),
      isNPS
    }),
    ({ classifiedAs: acc }, { classifiedAs: value }) => addScore({
      promoters:  acc.promoters  - (value.promoters || 0),
      passives:   acc.passives   - (value.passives || 0),
      detractors: acc.detractors - (value.detractors || 0),
      isNPS
    }),
    () => ({
      classifiedAs: {
        promoters:  0,
        passives:   0,
        detractors: 0,
        responded:  0,
        score:      0
      }
    })
  ];
}

function addScore({ promoters, passives, detractors, isNPS }) {
  if (isNPS) {
    const responded = promoters + passives + detractors;
    const score     = responded ? Math.round((promoters - detractors) / responded * 100) : 0;
    return {
      classifiedAs: {
        promoters, passives, detractors, responded, score
      }
    };
  } else {
    const responded = promoters + detractors;
    const score     = responded ? Math.round(promoters / responded * 100) : 0;
    return {
      classifiedAs: {
        promoters, passives, detractors, responded, score
      }
    };
  }
}

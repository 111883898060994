
export default function removeEmptyVariables(variables) {
  return Object.keys(variables).reduce((acc, key) => {
    const value   = variables[key];
    const isArray = Array.isArray(value);

    if (isArray && !value.length)
      return acc;
    else if (value)
      return { ...acc, [key]: value };
    else
      return acc;
  }, {});
}

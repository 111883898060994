import * as DateFns from 'date-fns';

export default function getFilterDate(year = 2018) {
  const now     = new Date();
  const maxDate = DateFns.lightFormat(now, 'yyyy-MM-dd');
  const minDate = DateFns.lightFormat(new Date(`${year}-01-01T12:00:00`), 'yyyy-MM-dd');
  return {
    maxDate,
    minDate
  };
}

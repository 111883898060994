import * as d3                  from 'd3';
import { useGroup }             from '../util/crossfilter';
import { VisualizeMetricsLine } from '../components/visualize_metrics';
import MetricsPageSection       from './metrics_page_section';
import MetricsSection           from './metrics_section';
import React                    from 'react';
import useAggregates            from './use_aggregates';


const reducers = [
  ({ feedbackResponse: acc }, { feedbackResponse: value }) => addResponseRate({
    asked:     acc.asked     + (value.asked || 0),
    responded: acc.responded + (value.responded || 0)
  }),
  ({ feedbackResponse: acc }, { feedbackResponse: value }) => addResponseRate({
    asked:     acc.asked     - (value.asked || 0),
    responded: acc.responded - (value.responded || 0)
  }),
  () => ({ feedbackResponse: { asked: 0, responded: 0, responseRate: 0 } })
];


export default function CustomersSurveyed({ dimension, dateRange }) {
  const { current, previous } = useAggregates({ dimension, reducers, dateRange });

  const formatWithCommaDelimiters = d3.format(',d');
  const formatAsPercentage        = d3.format('.0%');

  const group = useGroup({ dimension, reducers });
  const data  = group.all()
    .filter(({ key: date }) => date >= dateRange.startDate && date <= dateRange.endDate);

  const askedForFeedback = {
    title:   'Asked',
    color:   '#9BD1E5',
    area:    true,
    current: formatWithCommaDelimiters(current.feedbackResponse.asked),
    delta:   current.feedbackResponse.asked - previous.feedbackResponse.asked,
    points:  data.map(({ key, value }) => ({ x: key, y: value.feedbackResponse.asked })),
    unit:    'number'
  };

  const responded = {
    title:   'Responded',
    color:   '#54A6C9',
    area:    true,
    current: formatWithCommaDelimiters(current.feedbackResponse.responded),
    delta:   current.feedbackResponse.responded - previous.feedbackResponse.responded,
    points:  data.map(({ key, value }) => ({ x: key, y: value.feedbackResponse.responded })),
    unit:    'number'
  };

  const responseRate = {
    title:   'Response Rate',
    color:   '#b27eea',
    current: formatAsPercentage(current.feedbackResponse.responseRate),
    delta:   roundToOneDecimal((current.feedbackResponse.responseRate - previous.feedbackResponse.responseRate) * 100),
    points:  data.map(({ key, value }) => ({
      x: key,
      y: value.feedbackResponse.responseRate
    })),
    scale:     ({ y, domain }) => domain[0] + (y * (domain[1] - domain[0])),
    formatter: formatAsPercentage,
    ticks:     [ 0, 0.25, 0.5, 0.75, 1 ],
    unit:      'percentage'
  };

  const metrics  = [ askedForFeedback, responded, responseRate ];
  const link     = (
    <a href="https://broadlyhelp.force.com/s/article/what-are-some-best-practices-to-maximize-my-response-rate">
      View our best practices for maximizing your response rate
    </a>
  );
  const subtitle = (
    <>
      See how many customers you’ve asked for feedback, those who responded, and the resulting response rates. {link}.
    </>
  );

  return (
    <MetricsPageSection>
      <MetricsSection title="Customer Response Rate" subtitle={subtitle}>
        <VisualizeMetricsLine metrics={metrics} formatter={formatWithCommaDelimiters}/>
      </MetricsSection>
    </MetricsPageSection>
  );
}


function roundToOneDecimal(value) {
  // Rounding to nearest 0.1, using EPSILON for precision
  // https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
  const epsilon = value >= 0 ? Number.EPSILON : -Number.EPSILON;
  return Math.round((value + epsilon) * 10) / 10;
}


function addResponseRate(feedbackResponse) {
  const { asked, responded } = feedbackResponse;
  const responseRate         = asked ? Math.min(responded / asked, 1) : 0;

  return {
    feedbackResponse: {
      ...feedbackResponse,
      asked,
      responded,
      responseRate
    }
  };
}

import './button.less';

import { Button as SemanticButton } from 'semantic-ui-react';
import classnames                   from 'classnames';
import React                        from 'react';


// This component imports our button styles and exports the Button component
// from semantic-ui-react. That Button component has a sensible and
// well-documented API https://react.semantic-ui.com/elements/button so we can
// use it to generate button markup, and apply our own appearance to it.

function Button({ rounded, className, ...props }) {
  const classes = classnames(className, { rounded });
  return (
    <SemanticButton {...props} className={classes}/>
  );
}

Button.Group = SemanticButton.Group;

export default Button;

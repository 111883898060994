import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// import our styles after the above styles
import './date_range_selector.less';

import * as DateFns        from 'date-fns';
import { DateRangePicker } from 'react-dates';
import { Responsive }      from 'semantic-ui-react';
import Button              from './button';
import moment              from 'moment';
import React               from 'react';


// constant props for DateRangePicker
const constants = {
  startDateId:   'startDate',
  endDateId:     'endDate',
  minimumNights: 0
};

export default function DateRangeSelector({ dateRange, setDateRange, minDate, maxDate }) {
  // local state used to track open/closed state
  const [ focusedInput, onFocusChange ] = React.useState(null);

  // DateRangePicker requires moment date objects
  const moments = {
    startDate: moment(dateRange.startDate),
    endDate:   moment(dateRange.endDate)
  };

  // setDateRange requires JS dates. These are used as fallbacks in
  // onDatesChange handler (when only one date changes)
  const dates = {
    startDate: DateFns.parseISO(dateRange.startDate),
    endDate:   DateFns.parseISO(dateRange.endDate)
  };

  function onDatesChange({ startDate, endDate }) {
    const changed = {
      startDate: startDate?.toDate(),
      endDate:   endDate?.toDate()
    };
    setDateRange({
      startDate: changed.startDate || dates.startDate,
      endDate:   changed.endDate || dates.endDate
    });
  }

  // determine if a given day should be disabled or not
  // https://momentjs.com/docs/#/query/is-between/
  function isOutsideRange(day) {
    const unit      = 'day';
    const inclusive = '[]';
    return !day.isBetween(minDate, maxDate, unit, inclusive);
  }

  // helper to render content below the calendar grid
  function renderCalendarInfo() {
    return (
      <DateRangeButtons {...{ dateRange, setDateRange, maxDate }}/>
    );
  }

  return (
    <div className="date-range-selector">
      <DateRangePicker {...constants} {...moments} {...{
        focusedInput,
        onFocusChange,
        onDatesChange,
        isOutsideRange,
        renderCalendarInfo
      }}/>
    </div>
  );
}


function DateRangeButtons({ dateRange, setDateRange, maxDate }) {
  function isInRange(days) {
    const endDate   = DateFns.lightFormat(
      DateFns.subDays(DateFns.parseISO(maxDate), 1),
      'yyyy-MM-dd'
    );
    const startDate = DateFns.lightFormat(
      DateFns.subDays(DateFns.parseISO(maxDate), days),
      'yyyy-MM-dd'
    );
    return startDate === dateRange.startDate && endDate === dateRange.endDate;
  }

  return (
    <div className="date-range-buttons">
      <Button.Group>
        {[
          [ '1 Year',   '1y', 365 ],
          [ '3 Months', '3m', 90  ],
          [ '1 Month',  '1m', 30  ],
          [ '1 Week',   '7d', 7   ]
        ].map(([ longLabel, shortLabel, days ], i) => (
          <Button
            key={i}
            active={isInRange(days)}
            onClick={() => setDateRange({ days })}>
            <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>{shortLabel}</Responsive>
            <Responsive minWidth={Responsive.onlyComputer.minWidth}>{longLabel}</Responsive>
          </Button>
        ))}
      </Button.Group>
    </div>
  );
}

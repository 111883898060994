import './confirm.less';

import { Confirm } from 'semantic-ui-react';


// This component imports our confirm styles and exports the Confirm component
// from semantic-ui-react. That Confirm component has a sensible and
// well-documented API https://react.semantic-ui.com/addons/confirm/ so we can
// use it to generate confirm markup, and apply our own appearance to it.

export default Confirm;

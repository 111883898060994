import './nps_badge.less';
import classnames from 'classnames';
import React      from 'react';


export default function NpsBadge({ score }) {
  const classNames = classnames('nps-badge', `score-${score}`);
  return (
    <div className={classNames}>
      <div className="nps-score">
        {score}
      </div>
      <div className="nps-title">NPS</div>
    </div>
  );
}

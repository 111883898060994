import BrandSelector     from '../components/brand_selector';
import createWorksheet   from './worksheet';
import DateRangeSelector from '../components/date_range_selector';
import DownloadExcel     from '../components/download_excel';
import Locations         from '../facets/locations';
import React             from 'react';


export default function Facets({ cf, dateRange, minDate, maxDate, setDateRange, providers, isLoading }) {
  return (
    <div className="facets">
      <div className="brand-location-selectors">
        <BrandSelector/>
        <Locations cf={cf}/>
      </div>
      <div className="date-download">
        <DateRangeSelector {...{ dateRange, minDate, maxDate, setDateRange }}/>
        <Download {...{ providers, isLoading }}/>
      </div>
    </div>
  );
}

function Download({ providers, isLoading }) {
  return (
    <DownloadExcel disabled={isLoading} logName="DashboardTeamExported">
      {() => ({ worksheets: [ createWorksheet(providers) ] })}
    </DownloadExcel>
  );
}

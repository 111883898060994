// Hooks for getting access token for the current user, and logging them out.

import * as QS           from 'querystring';
import { useContext }    from 'react';
import { useEffect }     from 'react';
import { useState }      from 'react';
import LoadingScreen     from '../components/loading_screen';
import React             from 'react';


// @auth/auth0-spa-js aliased in package.json because
// https://github.com/auth0/auth0-spa-js/issues/478
import createAuth0Client from 'auth0-spa-js'; // eslint-disable-line import/no-unresolved

// For testing.
export const AuthenticateContext = React.createContext(null);


const clientOptions = {
  audience:     'https://graph.broadly.com',
  client_id:    'a4e4rcoy3Aah7p7jDeEfWaIRWjd4WQM5',
  domain:       'login.broadly.com',
  redirect_uri: getRedirectURI(),
  scope:        'read:contact read:location read:user'
};


// Use this hook to get current access token and function to retrieve a new
// token.
//
// accessToken – The access token is retrieved asynchronously, so the first call
// to this hook returns null.
//
// reauthenticate - If the server rejects the access token, call this function
// to get a new access token - it may force the user to login again and redirect
// back to the current page.
export function useAccessToken() {
  return useContext(AuthenticateContext);
}


async function authenticateAsync(setAccessToken, connection) {
  const auth0 = await createAuth0Client({ ...clientOptions, connection });

  try {
    // This requires CORS to work, otherwise it will timeout with an error
    const newAccessToken = await auth0.getTokenSilently();
    setAccessToken(newAccessToken);
  } catch (error) {
    // Typically `login_required` error. If the user is not authenticated, then
    // this will redirect them back to Auth0 to get a new access token. If the
    // user has logged out from the app, Auth0 will ask them to login again. It
    // will show them the login prompt, so we don't have to detect this case.
    const prompt = connection === 'salesforce' ? 'login' : 'none'; // when salesforce ask to login
    await auth0.loginWithRedirect({ appState: getReturnTo(), prompt });
  }
}


function getReturnTo() {
  return [ window.location.pathname, window.location.search ].join('');
}


// Use this to sign out of the dashboard. Logging out doesn't impact open tabs
// that have a valid access token, but if the user reloads another open tab, of
// the access token expires, they will be asked to login again.
export async function signOut() {
  sessionStorage.clear();
  localStorage.clear();
  const auth0 = await createAuth0Client(clientOptions);
  auth0.logout({ returnTo: getRedirectURI() });
}


function getRedirectURI() {
  return location ? `${location.protocol}//${location.host}` : 'https://dashboard.broadly.com';
}


function getRedirectTo() {
  if (window.location.search.includes('redirectTo')) {
    const { redirectTo } = QS.parse(window.location.search.slice(1));
    return redirectTo;
  }

  return null;
}


export function Authenticate({ children }) {
  const [ redirected, setRedirected ] = useState(null);

  useEffect(() => {
    handleRedirects().then(setRedirected);
  }, []);

  // Show loading screen while we decide whether
  // we need to redirect the user, and while actually
  // redirecting.
  if (redirected === null || redirected === true)
    return <LoadingScreen/>;

  // Allow impersonating a user, organization, or location.
  //
  // - https://dashboard.broadly.com/impersonate/user/:id
  // - https://dashboard.broadly.com/impersonate/organization/:id
  // - https://dashboard.broadly.com/impersonate/location/:id
  //
  const { entity, id } = getImpersonation();
  if (entity && id) {
    return (
      <AuthenticateWithSalesforce entity={entity} id={id}>
        {children}
      </AuthenticateWithSalesforce>
    );
  }

  return (
    <AuthenticateWithCredentials>
      {children}
    </AuthenticateWithCredentials>
  );
}


function getImpersonation() {
  const { location }          = window;
  const isImpersonatingViaURL = (location.pathname.indexOf('/impersonate/') === 0);
  if (isImpersonatingViaURL) {
    const [ , , entity, id ] = location.pathname.split('/');
    // Store impersonation details in session storage
    // to support refreshing the page while impersonating.
    sessionStorage.impersonate = JSON.stringify({ entity, id });
    return { entity, id };
  }

  if (sessionStorage.impersonate) {
    const { entity, id } = JSON.parse(sessionStorage.impersonate);
    return { entity, id };
  }

  return {};
}


async function handleRedirects() {
  if (window.location.pathname.includes('signout')) {
    await signOut();
    return true;
  }

  // Avoid getting caught in a redirect loop:
  // - If we get any OAuth error, bounce the user back to the login screen and
  //   have them login again
  // - Drop all search query parameters, otherwise, we could end up redirecting
  //   them back to a URL than includes ?error= and they have no way out
  if (window.location.search.includes('error=')) {
    const auth0 = await createAuth0Client(clientOptions);
    await auth0.loginWithRedirect({ appState: getRedirectTo() || '/', prompt: 'login' });
    return true;
  }

  // Because this is PCKE flow, it will redirect back to the dashboard with a
  // query string that contains an authorization code. Auth0 will exchange it
  // for an access token, and we redirect the use back to the original page.
  if (window.location.search.includes('code=')) {
    const auth0                  = await createAuth0Client(clientOptions);
    const { appState: returnTo } = await auth0.handleRedirectCallback();
    if (returnTo.includes('redirectTo')) {
      const { redirectTo } = QS.parse(returnTo.slice(2));
      window.location.replace(redirectTo);
    } else
      window.location.replace(returnTo);
    return true;
  }

  return false;
}


// Require username and password authentication.
// Token stored in local storage.
function AuthenticateWithCredentials({ children }) {
  const [ accessToken, setAccessToken ] = useState(localStorage.accessToken);

  function onToken(token) {
    localStorage.accessToken = token;
    setAccessToken(token);
    return token;
  }

  useEffect(function() {
    if (!accessToken)
      authenticateAsync(onToken);
  }, [ accessToken ]);

  async function reauthenticate() {
    authenticateAsync(onToken);
  }

  return (
    <AuthenticateContext.Provider value={({ accessToken, reauthenticate })}>
      {accessToken ?
        children :
        <LoadingScreen/>
      }
    </AuthenticateContext.Provider>
  );
}


// Require authentication using Salesforce.
// Token is ephemeral.
function AuthenticateWithSalesforce({ entity, id, children }) {
  const isSupportedEntity = (entity === 'user' || entity === 'organization' || entity === 'location');
  if (!isSupportedEntity)
    throw new Error('Impersonation error');

  const [ accessToken, setAccessToken ] = useState();

  useEffect(function() {
    if (!accessToken)
      authenticateAsync(setAccessToken, 'salesforce');
  }, [ accessToken ]);

  async function reauthenticate() {
    authenticateAsync(setAccessToken, 'salesforce');
  }

  return (
    <AuthenticateContext.Provider value={({ accessToken, reauthenticate, impersonate: { entity, id } })}>
      {accessToken ?
        children :
        <LoadingScreen/>
      }
    </AuthenticateContext.Provider>
  );
}

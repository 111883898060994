import './brands.less';

import { useAllLocations }          from '../util/use_locations';
import { useDimension }             from '../util/crossfilter';
import { useGroup }                 from '../util/crossfilter';
import { useLogPageViewEvent }      from '../util/use_log_page_view_event';
import { useNotification }          from '../components/notification';
import { useOnChange }              from '../util/crossfilter';
import { useOrganizations }         from '../util/use_organizations';
import { useSearchParametersState } from '../util/use_search_params';
import { WideBlock }                from '../components/block';
import Block                        from '../components/block';
import Facets                       from './facets';
import getFilterDate                from '../util/filter_date';
import LoadingShimmer               from '../components/loading_shimmer';
import LocationsOrBrandsTable       from '../components/locations_or_brands_table';
import React                        from 'react';
import reducers                     from '../locations/location_reducers';
import useDateDimension             from '../hooks/use_date_dimension';
import useDateRange                 from '../hooks/use_date_range';
import useLocationMetrics           from './use_location_metrics';
import useOrganizationMetrics       from './use_organization_metrics';


export default function BrandsPage() {
  const organizations          = useOrganizations();
  const { showError }          = useNotification();
  const locations              = useAllLocations();
  const isNPS                  = checkIfIsNPS({ organizations, locations });
  const { maxDate, minDate }   = getFilterDate();
  const [ query, updateQuery ] = useSearchParametersState();

  useLogPageViewEvent('DashboardBrandsViewed');

  // get rid of any existing location filters before getting data
  if (query.get('locations')) {
    query.delete('locations');
    updateQuery(query);
  }

  const [ dateRange, setDateRange ]                             = useDateRange({ maxDate });
  const { cf: cfLoc, error: errorLoc, isLoading: isLoadingLoc } = useLocationMetrics({ locations, ...dateRange, minDate, maxDate });
  const { cf: cfOrg, error: errorOrg, isLoading: isLoadingOrg } = useOrganizationMetrics({ organizations, ...dateRange });
  const isLoading                                               = isLoadingLoc || isLoadingOrg;

  useDateDimension(cfLoc, dateRange);
  useDateDimension(cfOrg, dateRange);
  useOnChange(cfOrg);

  if (errorLoc || errorOrg)
    showError('Something went wrong. Refresh the page to try again.');

  const brandsMetrics = useBrandsMetrics({
    organizations,
    locations,
    organizationsCf: cfOrg,
    locationsCf:     cfLoc
  });

  return (
    <div className="brands-page">
      <Block>
        <Facets {...{ dateRange, minDate, maxDate, setDateRange, brandsMetrics, isLoading, isNPS }}/>
        {isLoading && <LoadingShimmer/>}
      </Block>
      <WideBlock>
        {!isLoading && <LocationsOrBrandsTable data={brandsMetrics} type="brands"/>}
      </WideBlock>
    </div>
  );
}


export function useBrandsMetrics({ organizations, locations, organizationsCf, locationsCf }) {
  const brands        = [ ...organizations.values(), ...locations.values() ];
  const orgMetrics    = useGroupByBrand({ cf: organizationsCf, brands });
  const locMetrics    = useGroupByBrand({ cf: locationsCf, brands });
  const metrics       = [ ...orgMetrics.all(), ...locMetrics.all() ];
  const brandsMetrics = mergeBrandsWithMetrics({ metrics, brands });
  return brandsMetrics;
}


function useGroupByBrand({ cf }) {
  const dimension = useDimension(cf, metric => metric.organization?.id ?? metric.location.id);
  const group     = useGroup({ dimension, reducers });
  return group;
}


function mergeBrandsWithMetrics({ brands, metrics }) {
  return brands.map(brand => {
    const metric = metrics.find(({ key }) => key === brand.id)?.value;

    return {
      ...brand,
      ...metric
    };
  });
}


export function checkIfIsNPS({ organizations, locations }) {
  return [ ...locations.values(), ...organizations.values() ].some(({ askForFeedback }) => askForFeedback === 'NPS');
}

import Amplitude from 'amplitude-js';


const amplitude      = Amplitude.getInstance();
const productionKey  = '9f781a0aa363c8d9e1d5e3d98b245d32';
const developmentKey = '833d785c8ab509604a54f05cf20aaba9';
const apiKey         = process.env.NODE_ENV === 'production' ? productionKey : developmentKey;


amplitude.init(apiKey);


const originalLogEvent = amplitude.logEvent;
amplitude.logEvent     = function logEvent(eventType, eventProperties = {}, callback) {
  originalLogEvent.call(this, eventType, {
    url: document.location.href,
    ...eventProperties
  }, callback);
};


document.addEventListener('AmplitudeLogEvent', function(event) {
  const { eventType, eventProperties } = event.detail;
  amplitude.logEvent(eventType, eventProperties);
}, true);


export default amplitude;

// Catch all React rendering errors and show an error message, which is only
// marginally better than a blank page.
//
// Do not rely on this for error handling!

import FullscreenMessage from '../components/fullscreen_message';
import React             from 'react';
import rollbar           from '../rollbar';


export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    const { error } = this.state;
    if (error) {
      rollbar.error(error);
      return (
        <FullscreenMessage>
          <FullscreenMessage.Title>Oops. Something went wrong.</FullscreenMessage.Title>
          <p>Our team has been informed, and we are working to fix this.</p>
        </FullscreenMessage>
      );
    } else
      return this.props.children;
  }
}

import './loading_screen.less';
import { NavigationWithLogoOnly } from '../app/navigation';
import Block                      from '../components/block';
import LoadingShimmer             from '../components/loading_shimmer';
import React                      from 'react';


export default function LoadingScreen() {
  return (
    <div className="loading-screen">
      <NavigationWithLogoOnly/>
      <Block>
        <LoadingShimmer/>
      </Block>
    </div>
  );
}

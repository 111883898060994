import './fullscreen_spinner.less';
import { Loader } from 'semantic-ui-react';
import Block      from './block';
import React      from 'react';


export default function FullscreenSpinner() {
  return (
    <Block className="fullscreen-spinner">
      <Loader active inline="centered" content="Loading…" size="large"/>
    </Block>
  );
}

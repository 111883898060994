import { getApolloContext } from '@apollo/react-hooks';
import { useContext }       from 'react';
import { useEffect }        from 'react';
import { useState }         from 'react';
import query                from '../graphql/queries/feedback_facets.graphql';
import removeEmptyVariables from './remove_empty_variables_from_query';

export function useFeedbackFacets({
  localStartDate,
  localEndDate,
  responseOption,
  keyword,
  locationIDs,
  providerIDs,
  searchText,
  sentiment,
  visibility,
  setError
}) {
  const [ feedbackFacets, setFeedbackFacets ] = useState(null);
  const { client }                            = useContext(getApolloContext());

  const loadFeedbackInput = {
    client,
    localStartDate,
    localEndDate,
    responseOption,
    keyword,
    locationIDs,
    providerIDs,
    searchText,
    sentiment,
    visibility,
    setError,
    setFeedbackFacets
  };

  useEffect(() => {
    setFeedbackFacets(null);

    return loadFeedback({ ...loadFeedbackInput });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ localStartDate, localEndDate, responseOption, keyword, locationIDs, providerIDs, searchText, sentiment, visibility ]);

  return { isLoading: !!feedbackFacets, feedbackFacets };
}

export function loadFeedback({
  client,
  localStartDate,
  localEndDate,
  responseOption,
  keyword,
  locationIDs,
  providerIDs,
  searchText,
  sentiment,
  visibility,
  setError,
  setFeedbackFacets
}) {
  let isUnmounted = false;

  function start() {
    setError(null);
    queryFeedback();
  }

  async function queryFeedback() {
    const variables = {
      locationIDs,
      startDate: localStartDate,
      endDate:   localEndDate,
      responseOption,
      keyword,
      providerIDs,
      searchText,
      sentiment,
      visibility
    };

    const notEmptyVariables = removeEmptyVariables(variables);

    const { data, error } = await client.query({
      query,
      variables: notEmptyVariables
    });

    // Component was unmounted, don't try to change the state.
    if (isUnmounted)
      return;

    if (error)
      onError(error);
    else {
      const keywords          = data.feedbackFacets.keywords;
      const keyWordsWithTotal = keywords.map(({ term, sentiment: keywordSentiment }) => {
        const { positive } = keywordSentiment || 0;
        const { passive }  = keywordSentiment || 0;
        const { negative } = keywordSentiment || 0;
        const total        = positive + passive + negative;

        return {
          term,
          sentiment: {
            ...keywordSentiment,
            total
          }
        };
      });

      onNewData({
        ...data.feedbackFacets,
        keywords: keyWordsWithTotal
      });
    }
  }

  function onError(error) {
    setError(error);
  }

  function onNewData(data) {
    setFeedbackFacets(data);
  }

  // When user navigates to a different page and component unmounted, call this
  // function to stop loading more metrics, and stop updating component state.
  function stop() {
    isUnmounted = true;
  }

  start();

  return stop;
}

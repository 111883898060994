import './block.less';

import classnames from 'classnames';
import React      from 'react';


export default function Block({ className, children }) {
  const classNames = classnames('block', className);
  return (
    <div className={classNames}>
      <div className="block-content">
        {children}
      </div>
    </div>
  );
}

export function WideBlock({ className, children }) {
  const classNames = classnames('block', className);
  return (
    <div className={classNames}>
      <div className="wide-block-content">
        {children}
      </div>
    </div>
  );
}
